/* eslint-disable eqeqeq */

import { message, Upload, Input,Row,Col, Spin } from 'antd';
// import axios from 'axios';
import React, { useState, useEffect, } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import get from 'lodash/get';
import { addCompany } from "../../actions/auth";
import { updateSupplier, fetchSupplier } from '../../actions/suppliers';
import { fetchOrganization } from "../../actions/organization";
import TextEditor from '../../components/TextEditor/TextEditor';
export const genHeaders = () => ({
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
    },
  });

  const { TextArea } = Input;
const OrganisationDetails = () => {
    // const userId = sessionStorage.getItem('user_id');
    const EntityId=sessionStorage.getItem('entity_id');
    const roleId = sessionStorage.getItem('role_id');
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [HeaderData, setHeaderData] = useState({});
    const organisation = useSelector(state => get(state, `entities.organizations.${EntityId}`));
    const supplier = useSelector(state => get(state, `entities.suppliers.${EntityId}`));
    const [companyDesc, setCompanyDesc] = useState("");
    // const[Documentid,setDocumentid]=useState();
    const dispatch = useDispatch();
    const [FooterData, setFooterData] = useState({});
    const AdminType=sessionStorage.getItem('type_admin')

  useEffect(() => {
    if(roleId === '2') {
       dispatch(fetchOrganization(+EntityId));
      }
      else if(roleId === '3') {
      // dispatch(fetchOrganization(+EntityId));
      dispatch(fetchSupplier(+EntityId));
   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  // console.log(organisation,"===oraganization")

  useEffect(() => {
    setImageUrl(organisation?.branding_logo || supplier?.branding_logo);
    setHeaderData(organisation?.header || supplier?.header || "");
    setFooterData(organisation?.footer || supplier?.footer || "");
    setCompanyDesc(supplier?.company_description  || organisation?.org_description|| "")
    if(organisation){
      setValue()
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[organisation, supplier])

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  function getBase64(img, callback) {

    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
      
  // console.log(roleId, AdminType,"=======roleId==2 && AdminType==0")
  const handleChangeupload=(filechange)=>{
    const file = filechange?.file?.originFileObj;
    getBase64(file,imageUrl =>{
      setImageUrl(imageUrl)
      setLoading(false)},
    );
  }
  

  const changeFooter = (data) => {
    setFooterData(data);
  };

  const changeHeader = (data) => {
    setHeaderData(data);
  };

  const submit = async () => {
    setLoading(true);
    window.scroll(0,0);
    const data = {
      branding_logo:imageUrl,
      header: HeaderData,
      footer: FooterData,
      company_description: companyDesc,
    };
    // if(companyDesc && sessionStorage.getItem("role_id") === '3'){
    //   await dispatch(updateSupplier(EntityId, data));
    // }
    const response = await dispatch(sessionStorage.getItem("role_id") === '3' ? updateSupplier(EntityId, data) : addCompany(data, EntityId));
    // const response = await dispatch(addCompany(data, EntityId));
    if (response?.error === true) {
      message.error(response?.payload?.body?.error?.message);
    } else {
        message.success(intl.formatMessage({ id: "success" }));
      // setIsLoading(false);
      // getuserdetails(1, pageSize)
      // form.resetFields()
      // setVisible(false)
    }
    setLoading(false);
    // setHeaderData(data);
  };
    
  // const dataBetweenTag=async (org)=>{ 
  //   if(org=== null || org=== undefined || org=== '' ){
  //     return ''
  //   }
  //   var result = org?.match(/<p>(.*?)<\/p>/g)?.map(function(val){
  //     return val.replace(/<\/?p>/g,'');
  //   });
  //   return result?.length ?  result[0] : '';
  // }

  const setValue =async ()=>{ 
    setCompanyDesc(organisation?.org_description)
    // setCompanyDesc(await dataBetweenTag(supplier?.company_description))
    setHeaderData(organisation?.header);
    setFooterData(organisation?.footer);
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}><FormattedMessage id={'UploadLogo'} /></div>
    </div>
  );
      
	return (
    <>
      <Spin spinning={loading}>
        <div className="profile-wrapper">
          <h1 className="profile">{roleId==='2'?<FormattedMessage id={'org_details'} />:<FormattedMessage id={'companydetails'} />}</h1>
          <h3>{<FormattedMessage id={'UploadLogo'} />}</h3>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={!(roleId==='2' && AdminType==0 ) && beforeUpload}
            onChange={!(roleId==='2' && AdminType==0 ) &&  handleChangeupload}
          >
            {imageUrl ? <div alt="avatar" className="image" style={{backgroundImage: `url(${imageUrl})`}} /> : uploadButton}
          </Upload>

          {
            roleId === '2'  && 
            <div className="profile-wrapper">
              <h3>{<FormattedMessage id={'org_details'} />}</h3>
              <Row justify="center">
                <Col xs={24} md={20} lg={15}>
                  <Row>
                    <Col xs={15}>
                      <p><b><FormattedMessage id="organisation" /></b>:</p>
                    </Col>
                    <Col>
                      <p>{organisation?.organization_name}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={15}>
                      <p><b><FormattedMessage id="business_id" /></b>:</p>
                    </Col>
                    <Col>
                      <p>{organisation?.business_id}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={15}>
                      <p><b><FormattedMessage id="mobile_number" /></b>:</p>
                    </Col>
                    <Col>
                      <p>{organisation?.contact}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={15}>
                      <p><b><FormattedMessage id="email" /></b>: </p>
                    </Col>
                    <Col>
                      <p>{organisation?.email}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={15}>
                      <p><b><FormattedMessage id="address" /></b>: </p>
                    </Col>
                    <Col className="address">
                      <p>{organisation?.address}</p>
                      <p>{organisation?.city_name}</p>
                      <p>{organisation?.pincode}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }

          {
            roleId === '3'  && 
            <div className="profile-wrapper">
              <h3>{<FormattedMessage id={'supplier_details'} />}</h3>
              <Row justify="center">
                <Col xs={24} md={20} lg={15}>
                  <Row>
                    <Col xs={15}>
                      <p><b><FormattedMessage id="supplier" /></b>:</p>
                    </Col>
                    <Col>
                      <p>{supplier?.company_name}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={15}>
                      <p><b><FormattedMessage id="business_id" /></b>:</p>
                    </Col>
                    <Col>
                      <p>{supplier?.business_id}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={15}>
                      <p><b><FormattedMessage id="mobile_number" /></b>:</p>
                    </Col>
                    <Col>
                      <p>{supplier?.contact}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={15}>
                      <p><b><FormattedMessage id="email" /></b>: </p>
                    </Col>
                    <Col>
                      <p>{supplier?.email}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={15}>
                      <p><b><FormattedMessage id="address" /></b>: </p>
                    </Col>
                    <Col className="address">
                      <p>{supplier?.address}</p>
                      <p>{supplier?.city_name}</p>
                      <p>{supplier?.pincode}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }
          <h3>{<FormattedMessage id={'desc'} />}</h3>
      
          <TextArea
            disabled={(roleId=='2' && AdminType==0 )} 
            placeholder=""
            value={companyDesc}
            autoSize={{ minRows: 3, maxRows: 6 }} onChange={(e) => setCompanyDesc(e.target.value)}
          />
          {/* <TextEditor textData={companyDesc} onChangeData={(data) => setCompanyDesc(data)} /> */}
          <h3>{<FormattedMessage id={'header'} />}</h3>
          <TextEditor 
            disabled={(roleId==2 && AdminType==0 )} 
            textData={HeaderData || ""} 
            onChangeData={changeHeader} 
          />
          <h3>{<FormattedMessage id={'footer'} />}</h3>
          <TextEditor 
            disabled={(roleId==2 && AdminType==0 )} 
            textData={FooterData || "" } 
            onChangeData={changeFooter} 
          />
        </div>
        {
          (!(roleId==2 && AdminType==0 ))&&
          <div className="buttonBlock">
            <div onClick={() =>submit()} className="btn">
              <FormattedMessage id="save" />
            </div>
          </div>
        }
      </Spin>
    </>
  );
};
 export default OrganisationDetails;
