import React from 'react';
import PropTypes from "prop-types";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fi';
// import { useIntl } from "react-intl";

const TextEditor = ({textData, lang, onChangeData,disabled}) => {
    // const intl = useIntl();
	return (
		<CKEditor
        disabled={disabled}
        editor={ ClassicEditor }
        config={{
            removePlugins: ['CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'],
            toolbar: [
                'heading', '|',
                'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
                'outdent', 'indent', '|',
                'blockQuote', 'insertTable', '|',
                'undo', 'redo'
            ],
            language: 'fi',
          }}
        data={textData}
        onReady={ editor => {
            // You can store the "editor" and use when it is needed.
        } }
        onChange={ ( event, editor ) => {
            const data = editor.getData();
            onChangeData(data);
        } }
    />
)
	
}

TextEditor.propTypes = {
	textData: PropTypes.string,
	onChangeData: PropTypes.func,
}

export default TextEditor;