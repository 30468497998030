/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { 
  message, 
  // Spin 
} from "antd";
import { 
  domainUrl, 
  // awsDocumentUrl 
} from "../../helper";
import Header from "../Header";
import "../../components/SupplierDashboard/SupplierDashboardnew.css";
import moment from "moment";
import "./control-panel.less";
import { BarChart } from 'react-easy-chart';
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";

const Dashboard = ({ props }) => {
  // const { tenderId } = props.match.params;
  const [dashboards, setDashboards] = useState({});
  const [barData, setBarData] = useState([]);
  const role = sessionStorage.getItem('role_id')
  const history = useHistory();

  const genHeaders = () => ({
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
    },
  });

  const dateFunc = (value) => {
    var date = value;
    // console.log(date,"=====date1")
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();

    date = yyyy + '-' + mm + '-' + dd;
    // console.log(date,"=====date2")
    return date;
  }

  const monthJoinFunc = (data, check, invited) => {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = dateFunc(new Date(y, m, 1));
    var lastDay = dateFunc(new Date(y, m + 1, 0));
    var resultProductData = data?.filter(a => {
      var dateVal = dateFunc(new Date(invited ? a.invite_datetime : a.created_at));
      // if(invited){
      //   console.log(dateVal,"=====dateVal")
      // }
      // return (moment(dateVal).isAfter(firstDay) && moment(dateVal).isBefore(lastDay));
      return (dateVal >= firstDay && dateVal <= lastDay);
      // if(dateVal >= firstDay && dateVal <= lastDay){
      //   return a;
      // }
    });
    return check ?
      resultProductData?.map((val) => {
        return { ...val, published_date: moment(val?.published_date).format("DD-MM-YYYY"), closing_date: moment(val?.closing_date).format("DD-MM-YYYY") }
      })
      :
      resultProductData?.length;
  };

  const thisWeekAmountFunc = (data, check) => {
    var curr = new Date();
    var first = curr.getDate() - curr.getDay();
    var last = first + 6;
    const sunAmt = data?.filter((val) => dateFunc(new Date(new Date(curr.setDate(first)))) === dateFunc(new Date(val.created_at)));
    const monAmt = data?.filter((val) => dateFunc(new Date(new Date(curr.setDate(first + 1)))) === dateFunc(new Date(val.created_at)));
    const tueAmt = data?.filter((val) => dateFunc(new Date(new Date(curr.setDate(first + 2)))) === dateFunc(new Date(val.created_at)));
    const wedAmt = data?.filter((val) => dateFunc(new Date(new Date(curr.setDate(first + 3)))) === dateFunc(new Date(val.created_at)));
    const thuAmt = data?.filter((val) => dateFunc(new Date(new Date(curr.setDate(first + 4)))) === dateFunc(new Date(val.created_at)));
    const friAmt = data?.filter((val) => dateFunc(new Date(new Date(curr.setDate(first + 5)))) === dateFunc(new Date(val.created_at)));
    const satAmt = data?.filter((val) => dateFunc(new Date(new Date(curr.setDate(last)))) === dateFunc(new Date(val.created_at)));

    const weekLength = sunAmt?.length + monAmt?.length + tueAmt?.length + wedAmt?.length + thuAmt?.length + friAmt?.length + satAmt?.length
    // const weekLength = data.map(val => {
    //   var dateVal = dateFunc(new Date(val.created_at));
    //   console.log(first, dateVal, last,"=====first, dateVal, last");
    //   return (dateVal >= first && dateVal <= last);
    // });
    const weekData = [...sunAmt, ...monAmt, ...tueAmt, ...wedAmt, ...thuAmt, ...friAmt, ...satAmt]
    return check ?
      weekData?.map((val) => {
        return { ...val, published_date: moment(val?.published_date).format("DD-MM-YYYY"), closing_date: moment(val?.closing_date).format("DD-MM-YYYY") }
      })
      : weekLength;
  }

  useEffect(() => {
    const getInfo = async () => {
      if(role === '1'){
        let organizations = [{x:'no organizations found', y:''}];
        const publishingOrganization = await axios
          .get(`${domainUrl}/tender/v2/tender/organization`, genHeaders())
          .catch((e) => {
            console.error(e);
            return null;
          });
        if(publishingOrganization?.data?.data?.result){
          organizations = publishingOrganization?.data?.data?.result?.map((org) =>{
            return {x:org.organization_name+'('+org.tenders_count+')', y:org.tenders_count, color: org.tenders_count>= 5 ? '#2B7776' : 'grey'}
          })
        }
        setBarData(organizations);
      }

      const publishedCall = await axios
        .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_published=1&is_awarded=0&fetched_by=month&order_by=published_date&sort_by=DESC&limit=100`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const completedCall = await axios
        .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_completed=1&order_by=completion_date&fetched_by=month&sort_by=DESC&limit=100`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const dueCompletionCall = await axios
        .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_awarded=1&is_completed=0&fetched_by=month&sort_by=DESC&limit=100`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const failedCompletionCall = await axios
        .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_completed=0&fetched_by=month&sort_by=DESC&limit=100`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const suppliersRegisteredCall = await axios
        .get(`${domainUrl}/users?entity_type=supplier&fetched_by=month`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const activeTendersCall = await axios
        .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_published=1&is_awarded=0&sort_by=DESC&limit=100`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

        const invitedSuppliersCall = await axios
        .get(`${domainUrl}/masterservicenew/master/supplier_request/invite?&limit=100&fetched_by=month`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      try {
        let published, completed, dueComplete, failedComplete, supplierRegistered, activeTenders, invitedSuppliers;

        const [
          publishedRes, completedRes, dueCompletionRes, failedCompletionRes, suppliersRegisteredRes, activeTendersRes, invitedSuppliersRes
        ] = await Promise.all([
          publishedCall, completedCall, dueCompletionCall, failedCompletionCall, suppliersRegisteredCall, activeTendersCall, invitedSuppliersCall
        ]);

        if (publishedRes) {
          published = publishedRes?.data?.data?.result;
          published = [...new Map(published?.map(item => [item['tender_id'], item])).values()]
        }
        if (completedCall) {
          completed = completedRes?.data?.data?.result;
          completed = [...new Map(completed?.map(item => [item['tender_id'], item])).values()]

        }

        if (dueCompletionCall) {
          dueComplete = dueCompletionRes?.data?.data?.result;
          dueComplete = [...new Map(dueComplete?.map(item => [item['tender_id'], item])).values()]
        }

        if (failedCompletionCall) {
          failedComplete = failedCompletionRes?.data?.data?.result;
          failedComplete = [...new Map(failedComplete?.map(item => [item['tender_id'], item])).values()]
          failedComplete = failedComplete?.filter((val) => val.completion_date != null && val.completion_date < dateFunc(new Date()))

        }

        if (suppliersRegisteredCall) {
          supplierRegistered = suppliersRegisteredRes?.data?.data?.result;
          supplierRegistered = [...new Map(supplierRegistered?.map(item => [item['id'], item])).values()]
        }

        if (activeTendersCall) {
          activeTenders = activeTendersRes?.data?.data?.result;
          activeTenders = [...new Map(activeTenders?.map(item => [item['tender_id'], item])).values()]
        }

        if (invitedSuppliersRes) {
          invitedSuppliers = invitedSuppliersRes?.data?.data?.result;
          invitedSuppliers = [...new Map(invitedSuppliers?.map(item => [item['id'], item])).values()]
        }

        const dashboardObj = {
          todayTender: published?.filter((val) => dateFunc(new Date(val.created_at)) === dateFunc(new Date()))?.length,
          weekTender: thisWeekAmountFunc(published),
          monthTender: monthJoinFunc(published, false),
          completedTender: monthJoinFunc(completed, false),
          tenderDueComplete: monthJoinFunc(dueComplete, false),
          tenderFailComplete: monthJoinFunc(failedComplete, false),
          supplierRegister: monthJoinFunc(supplierRegistered, false),
          activeTender: activeTenders?.length,
          invitedSupplier: monthJoinFunc(invitedSuppliers, false, true),
          todayTenderData: published?.filter((val) => dateFunc(new Date(val.created_at)) === dateFunc(new Date())),
          weekTenderData: thisWeekAmountFunc(published, true),
          monthTenderData: monthJoinFunc(published, true),
          completedTenderData: monthJoinFunc(completed, true),
          tenderDueCompleteData: monthJoinFunc(dueComplete, true),
          tenderFailCompleteData: monthJoinFunc(failedComplete, true),
          supplierRegisterData: monthJoinFunc(supplierRegistered, true),
          activeTenderData: activeTenders
        };
        setDashboards(dashboardObj);
      } catch (e) {
        message.error(
          e?.message ?? "Unable to retrieve information from server."
        );
      }

    };
    getInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <>
      <Header />
      <div className="container">
        <div className="cardnw mt-2">
        <h1><FormattedMessage id={'dashboard'} /></h1>
          <div className="ant-row">
            <div className="ant-col leftSidePanel ant-col-md-24 ant-col-xl-8" style={{"paddingLeft": "10px", "paddingRight": "10px"}}>
              <div className="card mt-2">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="d-flex w-100 justify-content-between height-100">
                      <div className="d-flex ">
                        <button className="btn btn-sm span-card-btn">&nbsp;</button>
                        <h2 className="vertical-center"> <FormattedMessage id={'tenders_published'} /> </h2>
                      </div>
                      <div>
                        <h1 className="text-gray display-2">{dashboards.todayTender}</h1>
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                      <div><h1 className="h5"><FormattedMessage id={'today'} /></h1></div>
                      <div className="mt-15"><a onClick={() => history.push({ pathname: "/dashboarddetails", state: 1 })}><FormattedMessage id={'view'} /> &#187;</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ant-col leftSidePanel ant-col-md-24 ant-col-xl-8" style={{"paddingLeft": "10px", "paddingRight": "10px"}}>
              <div className="card mt-2">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="d-flex w-100 justify-content-between height-100">
                      <div className="d-flex ">
                        <button className="btn btn-sm span-card-btn">&nbsp;</button>
                        <h2 className="vertical-center"> <FormattedMessage id={'tenders_published'} /> </h2>
                      </div>
                      <div>
                        <h1 className="text-gray display-2">{dashboards.weekTender}</h1>
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                      <div><h1 className="h5"><FormattedMessage id={'this_week'} /></h1></div>
                      <div className="mt-15"><a onClick={() => history.push({ pathname: "/dashboarddetails", state: 2 })}><FormattedMessage id={'view'} /> &#187;</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ant-col leftSidePanel ant-col-md-24 ant-col-xl-8" style={{"paddingLeft": "10px", "paddingRight": "10px"}}>
              <div className="card mt-2">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="d-flex w-100 justify-content-between height-100">
                      <div className="d-flex ">
                        <button className="btn btn-sm span-card-btn">&nbsp;</button>
                        <h2 className="vertical-center"> <FormattedMessage id={'tenders_published'} /> </h2>
                      </div>
                      <div>
                        <h1 className="text-gray display-2">{dashboards.monthTender}</h1>
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                      <div><h1 className="h5"><FormattedMessage id={'this_month'} /></h1></div>
                      <div className="mt-15"><a onClick={() => history.push({ pathname: "/dashboarddetails", state: 3 })}><FormattedMessage id={'view'} /> &#187;</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ant-row">
            <div className="ant-col leftSidePanel ant-col-md-24 ant-col-xl-8" style={{"paddingLeft": "10px", "paddingRight": "10px"}}>
              <div className="card mt-2">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="d-flex w-100 justify-content-between height-100">
                      <div className="d-flex ">
                        <button className="btn btn-sm span-card-btn">&nbsp;</button>
                        <h2 className="vertical-center"> <FormattedMessage id={'tenders_marked_as_complete'} /> </h2>
                      </div>
                      <div>
                        <h1 className="text-gray display-2">{dashboards.completedTender}</h1>
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                      <div><h1 className="h5"><FormattedMessage id={'this_month'} /></h1></div>
                      <div className="mt-15"><a onClick={() => history.push({ pathname: "/dashboarddetails", state: 4 })}><FormattedMessage id={'view'} /> &#187;</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ant-col leftSidePanel ant-col-md-24 ant-col-xl-8" style={{"paddingLeft": "10px", "paddingRight": "10px"}}>
              <div className="card mt-2">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="d-flex w-100 justify-content-between height-100">
                      <div className="d-flex ">
                        <button className="btn btn-sm span-card-btn">&nbsp;</button>
                        <h2 className="vertical-center"> <FormattedMessage id={'tenders_due_for_completion'} /> </h2>
                      </div>
                      <div>
                        <h1 className="text-gray display-2">{dashboards.tenderDueComplete}</h1>
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                      <div><h1 className="h5"><FormattedMessage id={'this_month'} /></h1></div>
                      <div className="mt-15"><a onClick={() => history.push({ pathname: "/dashboarddetails", state: 5 })}><FormattedMessage id={'view'} /> &#187;</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ant-col leftSidePanel ant-col-md-24 ant-col-xl-8" style={{"paddingLeft": "10px", "paddingRight": "10px"}}>
              <div className="card mt-2">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="d-flex w-100 justify-content-between height-100">
                      <div className="d-flex ">
                        <button className="btn btn-sm span-card-btn">&nbsp;</button>
                        <h2 className="vertical-center"> <FormattedMessage id={'tenders_failed_completion'} /></h2>
                      </div>
                      <div>
                        <h1 className="text-gray display-2">{dashboards.tenderFailComplete}</h1>
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                      <div><h1 className="h5"><FormattedMessage id={'this_month'} /></h1></div>
                      <div className="mt-15"><a onClick={() => history.push({ pathname: "/dashboarddetails", state: 6 })}><FormattedMessage id={'view'} /> &#187;</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ant-row">
            {role !== '3' &&
              <div className="ant-col leftSidePanel ant-col-md-24 ant-col-xl-8" style={{"paddingLeft": "10px", "paddingRight": "10px"}}>
                <div className="card mt-2">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="d-flex w-100 justify-content-between height-100">
                        <div className="d-flex ">
                          <button className="btn btn-sm span-card-btn">&nbsp;</button>
                          <h2 className="vertical-center"> <FormattedMessage id={'new_suppliers_registered'} /> </h2>
                        </div>
                        <div>
                          <h1 className="text-gray display-2">{dashboards.supplierRegister}</h1>
                        </div>
                      </div>
                      <div className="d-flex w-100 justify-content-between">
                        <div><h1 className="h5"><FormattedMessage id={'this_month'} /></h1></div>
                        <div className="mt-15"><a onClick={() => history.push({ pathname: "/dashboarddetails", state: 7 })}><FormattedMessage id={'view'} /> &#187;</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {/* <div className="col-md-4 ">
                <div className="card mt-2">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="d-flex w-100 justify-content-between height-100">
                        <div className="d-flex ">
                          <button className="btn btn-sm span-card-btn">&nbsp;</button>
                          <h2 className="vertical-center"> Tenders Reached Bid End date With 2000 Bids </h2>
                        </div>
                        <div>
                          <h1 className="text-gray display-2">10</h1>
                        </div>
                      </div>
                      <div className="d-flex w-100 justify-content-between">
                        <div><h1 className="h5"><FormattedMessage id={'this_month'} /></h1></div>
                        <div className="mt-15"><a href="/"><FormattedMessage id={'view'} /> &#187;</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            <div className="ant-col leftSidePanel ant-col-md-24 ant-col-xl-8" style={{"paddingLeft": "10px", "paddingRight": "10px"}}>
              <div className="card mt-2"  style={{height: "210px"}}>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="d-flex w-100 justify-content-between height-100">
                      <div className="d-flex ">
                        <button className="btn btn-sm span-card-btn">&nbsp;</button>
                        <h2 className="vertical-center"> <FormattedMessage id={'active_tenders'} /> </h2>
                      </div>
                      <div>
                        <h1 className="text-gray display-2">{dashboards.activeTender}</h1>
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                      <div><h1 className="h5"> </h1></div>
                      <div className="mt-15"><a onClick={() => history.push({ pathname: "/dashboarddetails", state: 8 })}><FormattedMessage id={'view'} /> &#187;</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {role !== '3' &&
              <div className="ant-col leftSidePanel ant-col-md-24 ant-col-xl-8" style={{"paddingLeft": "10px", "paddingRight": "10px"}}>
                <div className="card mt-2">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="d-flex w-100 justify-content-between height-100">
                        <div className="d-flex ">
                          <button className="btn btn-sm span-card-btn">&nbsp;</button>
                          <h2 className="vertical-center"> <FormattedMessage id={'invited_suppliers'} /> </h2>
                        </div>
                        <div>
                          <h1 className="text-gray display-2">{dashboards.invitedSupplier}</h1>
                        </div>
                      </div>
                      <div className="d-flex w-100 justify-content-between">
                        <div><h1 className="h5"><FormattedMessage id={'this_month'} /></h1></div>
                        <div className="mt-15"><a onClick={() => history.push({ pathname: "/dashboarddetails", state: 9 })}><FormattedMessage id={'view'} /> &#187;</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      {role === '1' && 
        <div className="container">
          <div className="cardnw mt-2" style={{ paddingBottom: "20px" }}>
            <div className="d-flex">
              <div className="width-40p text-center">
                <h1 className="style-1">Top 5</h1>
                <h1 className="text-gray"><FormattedMessage id={'publishing_organization'} /></h1>
              </div>
              <div className="width-60p">
                <BarChart
                  // data={[
                  //   { x: 'A', y: 20, color: '#2B7776' },
                  //   { x: 'B', y: 30, color: '#2B7776' },
                  //   { x: 'C', y: 40, color: '#2B7776' },
                  //   { x: 'D', y: 20, color: '#2B7776' },
                  //   { x: 'E', y: 40, color: '#2B7776' },
                  //   { x: 'F', y: 25, color: '#2B7776' },
                  //   { x: 'G', y: 5, color: '#2B7776' }
                  // ]}
                  data={barData}
                  height={300}
                  width={700}
                  axes
                  yTickNumber={-1}
                />
              </div>
            </div>
          </div>
        </div>
      }
      {/* <div className="container">
        <div className="cardnw mt-2" style={{ paddingBottom: "20px" }}>
          <div className="ant-row">
            <div className="ant-col leftSidePanel ant-col-md-24 ant-col-xl-8" style={{"paddingLeft": "10px", "paddingRight": "10px"}}>
              <h1>Tenders with Most Bids <FormattedMessage id={'this_month'} /></h1>
            </div>
            <div className="ant-col leftSidePanel ant-col-md-24 ant-col-xl-16" style={{"paddingLeft": "10px", "paddingRight": "10px"}}>
              <BarChart
                data={[
                  { x: 'A', y: 20, color: '#2B7776' },
                  { x: 'B', y: 30, color: '#2B7776' },
                  { x: 'C', y: 40, color: '#2B7776' },
                  { x: 'D', y: 20, color: '#2B7776' },
                  { x: 'E', y: 40, color: '#2B7776' },
                  { x: 'F', y: 25, color: '#2B7776' },
                  { x: 'G', y: 5, color: '#2B7776' }
                ]}
                width={800}
                height={300}
                axes
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

// Dashboard.propTypes = {};

export default Dashboard;
