/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import { ConfigProvider } from 'antd';
import en from 'antd/lib/locale/en_US';
import fi from 'antd/lib/locale/fi_FI';
import messages from '../../Locale/Locale';
export const Context = React.createContext();
const local = navigator.language;

const LanguageWrapper = (props) => {
   const [locale, setLocale] = useState(local.substring(0,2) || 'fi');
   function selectLanguage(value) {
       const newLocale = value;
       setLocale(newLocale);
   }
   return (
       <Context.Provider value = {{locale, selectLanguage}}>
           <IntlProvider messages={messages[locale]} locale={locale}>
                <ConfigProvider locale={locale}>
                    {props.children}
                </ConfigProvider>
           </IntlProvider>
       </Context.Provider>
   );
}
export default LanguageWrapper;