/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import Header from "../Header/Header";
import "../../components/TenderDetail/TenderDetail.css";
// import profilePic from "../../assets/user-img2.png";
// import profilePic2 from "../../assets/user-img3.png";
// import profilePic3 from "../../assets/user-img4.png";
// import { Link } from "react-router-dom";
// import axios from "axios";
import {
  Button,
  Card,
  // Radio,
  Space,
  // Checkbox,
  // Upload,
  // message,
  // Table,
  // Tag,
  Spin,
  Form,
  Input,
} from "antd";

import Summary from "./Summary/Summary";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const TenderDetail = (props) => {
  const [sendQty, setSendQty] = useState(false);
  // const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);

  const { tenderId } = props.match.params;

  // const genHeaders = () => ({
  //   headers: {
  //     client_code: "ht_front",
  //     token: sessionStorage.getItem("token"),
  //   },
  // });

  useEffect(() => {
    // const getInfo = async () => {
      // setLoading(true);
      // const assets = axios.get(
      // `/tender/v1/tender/${tenderId}/asset`,
      // genHeaders()
      // );
      // const generalInfo = axios.get(
      // `/tender/v1/tender/${tenderId}`,
      // genHeaders()
      // );
      // const files = axios
      // .get(
      // `/Stage/v1/document?entity_type=T&entity_id=${tenderId}`,
      // genHeaders()
      // )
      // .catch((e) => {
      // console.error(e);
      // });
      // const suppliers = axios.get(
        // `/tender/v2/tender/${tenderId}/supplier`,
        // genHeaders()
      // );

      // try {
        // const [suppliersRes] = await Promise.all([suppliers]);

        // setInfo({
          // suppliers: suppliersRes.data.data.suppliers,
        // });

        // setLoading(false);
      // } catch (e) {
        // console.error(e);
        // message.error(
          // e?.message ?? "Unable to retrieve information from server."
        // );
      // }
    // };

    // getInfo();
  }, []);

  const onFinish = (values) => {
  };

  const onFinishFailed = (errorInfo) => {
  };

  return (
    <>
      <Header />
      {loading ? (
        <div style={{ height: 500 }} className="d-flex j-center">
          <Spin style={{ margin: "auto" }}></Spin>
        </div>
      ) : (
        <div className="container f-col">
          {sendQty ? (
            <Card>
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Send Questions to Admin"
                  name="username"
                  // rules={[
                  //   { required: true, message: "Please input your username!" },
                  // ]}
                >
                  <Input.TextArea />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Space>
                    <Button
                      style={{ marginTop: 0 }}
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ marginTop: 0 }}
                      htmlType="submit"
                      onClick={() => setSendQty(false)}
                    >
                      Cancel
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Card>
          ) : (
            <>
              <Summary tenderId={tenderId} />
            </>
          )}
        </div>
      )}
    </>
  );
};

TenderDetail.propTypes = {};

export default TenderDetail;
