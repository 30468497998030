import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { 
    Spin, 
    // Modal, 
    // Form, 
    Input, 
    Button, 
    // message, 
    Pagination, 
    Avatar, 
    // Table, 
    // Space 
} from 'antd';
import { 
    // RollbackOutlined, 
    SearchOutlined 
} from '@ant-design/icons';
import { FormattedMessage, useIntl } from "react-intl";
import moment from 'moment'; 

import { fetchMessageCommunicationInbox, updateMessageCommunication } from "../../actions/messages";
import MessageReplyCard from './MessageReplyCard';
// import Highlighter from "react-highlight-words";
import { changeDateFormat } from '../../utils/common';

const MessagesCard = ({tenderId, tenderTitle, creator_id,cardStyle={}}) => {
  const dispatch = useDispatch();

  const intl = useIntl();
  const [totalCount, setTotalCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [messages, setMessages] = useState([]);
  const [currentTenderId, setCurrentTenderId] = useState();
  const [currentTenderTitle, setCurrentTenderTitle] = useState();
  const [currentSenderId, setCurrentSenderId] = useState();
  const [currentMessageId, setCurrentMessageId] = useState();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [tenderName, setTenderName] = useState('');

    useEffect(() => {
       getMsgData(1, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const getMsgData = async(page, pageSize) => {
        setConfirmLoading(true);
        const params = {limit: pageSize, offset: pageSize*(page-1), order_by: 'created_at', sort_by: 'DESC' };
        if(tenderName){
            params.tender_name = tenderName;
        }
        if(tenderId){
            params.tender_id = tenderId;
        }
        const response = await dispatch(fetchMessageCommunicationInbox(params));
        const count = response?.payload?.data?.meta?.count;
        const result = response?.payload?.data?.result;
        setConfirmLoading(false);
        setTotalCount(count);
        setMessages(result);
    }

    const replyToMsg = (msg, index) => {
        setVisible(false);
        setCurrentTenderId(msg?.entity_meta?.tender_id);
        setCurrentTenderTitle(msg?.entity_meta?.title);
        setCurrentSenderId(msg?.sender_id);
        setCurrentMessageId(msg?.message_id)
        setVisible(true);
        
        if(!msg.is_read)
            markAsRead(msg.message_id, index);
    }

    const onPaginationChange = (page, pageSize) => {
        setPageSize(pageSize);
        getMsgData(page, pageSize);
    }
    const markAsRead = async(message_id, index) => {
        await dispatch(updateMessageCommunication(message_id, {is_read: true}));
        setMessages((c) => {
            const newList = [...c];
            newList[index].is_read = true;
            return newList;
        });
    }

    const markAsReplied = async(id=null) => {
        const index = messages.findIndex(msg => {
                return msg.message_id === currentMessageId;
            }
        )
        await dispatch(updateMessageCommunication(currentMessageId, {is_replied: true}));
        setMessages((c) => {
            const newList = [...c];
            newList[index].is_replied = true;
            return newList;
        });
    }

    const handleSearch = () => {
       getMsgData(1, pageSize);
    };

    // const [state, setState] =  useState({
    //     searchText: '',
    //     searchedColumn: '',
    // });

  return (
    <Spin spinning={confirmLoading}>
        <div className="dataBlock card msgCard" style={cardStyle}>
          {!tenderId &&
            <div style={{display: 'flex',  width: "100%",alignItems:"center" , justifyContent: "end", marginBottom: "2%" }}>
                <Input
                placeholder={intl.formatMessage({ id:"search_tender_name"})}
                value={tenderName}
                onChange={e => setTenderName(e.target.value ? e.target.value : '')}
                onPressEnter={() =>  handleSearch()}
                style={{ marginBottom: 0,display:"block", marginRight: "1%", width: "30%" }}
                />
                <Button
                type="primary"
                onClick={() => handleSearch()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, margin: 0 }}
                >
                <FormattedMessage id="search" />
                </Button>
            </div>
          }
        <h2><FormattedMessage id="Messages" /></h2>
            <ul className="dataTitle list2">
              <li className="w-5"><FormattedMessage id="from" /></li>
              <li><FormattedMessage id="date"/></li>
              <li><FormattedMessage id="time"/></li>
              <li><FormattedMessage id="tender"/></li>
              <li ><FormattedMessage id="last_msg"/></li>
            </ul>
            {
                messages?.map((msg,index) => (
                    <div key={index} className="list-items" onClick={() => replyToMsg(msg,index)}>
                        <div className="profilePic">
                            <Avatar className="msgAvatar">{msg?.sender_meta?.name.substring(0,1)}</Avatar>
                        </div>
                        <ul className={ !msg.is_read ? 'dataContent list2 bold' : 'dataContent list2'}>
                            <li className="d-flex w-3">
                                <span>{msg?.sender_meta?.name}{msg.is_read}</span>
                            </li>
                            <li>
                                <span>{changeDateFormat(msg?.created_at,intl?.locale)} </span>
                            </li>
                            <li>
                                <span>{moment(msg.created_at).format('hh:mm A')}</span>
                            </li>
                            <li>
                                <span>{msg?.entity_meta?.title}</span>
                            </li>
                            <li className="d-flex">
                                <span>{msg.is_replied ? msg?.receiver_meta?.name : msg?.sender_meta?.name }</span>
                            </li>
                        </ul>
                    </div>
                ))
            }

            
            <Pagination
                className="msgsPagination"
                // simple
                size="small"
                showSizeChanger
                onChange={onPaginationChange}
                defaultCurrent={1}
                total={totalCount}
                // locale={{ page: intl.formatMessage({ id: 'page' })}}
                locale={{ items_per_page: intl.formatMessage({ id: 'page' })}}
                // showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({ id: 'of' })} ${total} ${intl.formatMessage({ id: 'items' })}  `}
                />
        </div>
        {
            visible && 
            <MessageReplyCard hideModal={() => setVisible(false)} modalOpen={visible} markAsReplied={markAsReplied} tenderId={currentTenderId} tenderTitle={currentTenderTitle} receiverId={currentSenderId} messageId={currentMessageId}/>
        }
    </Spin>
  )
}

export default MessagesCard;