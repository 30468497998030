import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from 'lodash/get';
import {
  // Button, 
  Input, 
  message, 
  // Space, 
  Table 
} from 'antd';
import Header from "../Header";
import "./Company.css";
import axios from "axios";
import AddModal from "./AddModal";
import { domainUrl } from "../../helper";
import ConfirmationBox from '../ConfirmationBox';
import { compNames, getFilterValues } from '../../utils';
import { fetchCompanies } from '../../actions/company';
import { FormattedMessage, useIntl } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
const Company = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const role_id = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.roles.role_id`));
  const login_org = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.entities`));

  // const companies = useSelector(state => Object.values(get(state, `entities.companies`) || {}));

  const [openCompanyFormModel, setOpenCompanyFormModel] = useState({
    isOpen: false,
    isReadOnly: false,
  });
  const [company, setCompany] = useState();
  const [companies, setCompanies] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [deleteComp, setDeleteComp] = useState({});
  const [companyName, setCompanyName] = useState('');
  useEffect(()=>{
    getAllCompany(1, pageSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[companyName]);
  
  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setState({
  //     searchText: selectedKeys[0],
  //     searchedColumn: dataIndex,
  //   });
  // };
  
  // const handleReset = clearFilters => {
  //   clearFilters();
  //   setState({ searchText: '' });
  // };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={intl.formatMessage({ id:"search"})+` `+intl.formatMessage({ id:dataIndex})}
          value={selectedKeys[0]}
          onChange={e => setCompanyName(e.target.value ? e.target.value : '')}
          onPressEnter={() => setCompanyName(selectedKeys.length ? selectedKeys[0] : '')}
          // onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          // onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        {/* <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            <FormattedMessage id="search" />
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            <FormattedMessage id="reset" />
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            <FormattedMessage id="filter" />
          </Button>
        </Space> */}
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  // const [state, setState] =  useState({
  //   searchText: '',
  //   searchedColumn: '',
  // });

  const [state] =  useState({
    searchText: '',
    searchedColumn: '',
  });
  
  const columns = [
    {
      dataIndex: "company_name",
      title:intl.formatMessage({ id:'company'}),
      filters: getFilterValues("company_name", companies),
      ...getColumnSearchProps('company_name'),
      onFilter: (value, record) => record?.company_name?.indexOf(value) === 0,
      sorter: {
        compare: (a, b) => {
          return compNames(a.company_name, b.company_name);
        },
      },
    },
    {
      dataIndex: "organization_name",
      title:intl.formatMessage({ id:'organisation'}),
      filters: getFilterValues('organization_name', companies),
      onFilter: (value, record) => record?.organization_name?.indexOf(value) === 0,
      sorter: {
        compare: (a, b) => {
          return compNames(a.organization_name, b.organization_name);
        },
      },
    },
    {
      dataIndex: "company_id",
      title:intl.formatMessage({ id:'view'}),
      render: (value) => (
        <div className="viewIcon" onClick={() => openCompanyForm(value, { readOnly: true })}><FormattedMessage id={'view'} /></div>
      ),
    },
    {
      dataIndex: "company_id",
      title:intl.formatMessage({ id:'modify'}),
      render: (value, tableMeta, updateValue) => (
        <div
          className="btn-primary"
          onClick={() => openCompanyForm(value, { readOnly: false })}
        >
         <FormattedMessage id={'modify'} />
        </div>
      ),
    },
    {
      dataIndex: "company_id",
      title:intl.formatMessage({ id:'delete'}),
      render: (value, tableMeta, updateValue) => (
        <div className="deleteIcon" onClick={() => deleteCompany(value)}>
         <FormattedMessage id={'delete'} />
        </div>
      ),
    },
  ];

  useEffect(() => {
    getAllCompany(1, pageSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const getAllCompany = async (page, pageSize) => {
    let params = {
      limit: pageSize, 
      offset: pageSize*(page-1),
    }
    if(role_id === 2) {
      params.organization_id = parseInt(login_org[0].entity_id)
    }
    if(companyName){
      params.company_name = companyName;
    }
    
    const response = await dispatch(fetchCompanies(params, true));
    const count = response?.payload?.meta?.count;
    setTotalCount(count);
    const companyData = Object.values(response?.payload?.result);
    setCompanies(companyData);
  };

  const getSelectedCompany = (company_id) => {
    return companies.find((ele) => ele.company_id === company_id);
  };

  const openCompanyForm = (company_id, opts) => {
    let selected = getSelectedCompany(company_id);
    setOpenCompanyFormModel({ isOpen: true, isReadOnly: opts?.readOnly });
    if(role_id === 2) {
      if(!selected)
        selected = {};
      selected.organization_id = parseInt(login_org[0].entity_id)
    }
    setCompany(selected);
  };

  const closeCompanyForm = () => {
    setOpenCompanyFormModel({ isOpen: false });
    setCompany();
  };

  const addCompany = async (data) => {
    const headers = {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    if (data?.company_id) {
      //modify current organization;
      const { company_id, ...rest } = data;
      
      try{

      // const res = 
      await axios.put(
        `${domainUrl}/masterservicenew/master/company/${company_id}`,
        { ...rest },
        { headers }
      );
      message.success(intl.formatMessage({ id:'company_updated_success_msg'}));
    }
catch(err) {
  message.error(err?.response?.data?.message === 'Validation error' ? 'A Company with this business Id is already added' : (err?.response?.data?.message || 'Server Error'));
}
      getAllCompany(1, pageSize);
      closeCompanyForm();
      return;
    }

    //create new organization
    const body = {
      ...data,
      country_id: +data.country_id,
      city_id: +data.city_id,
    };
    axios.post(
          `${domainUrl}/masterservicenew/master/company`,
          body,
          { headers }
        ).then(res => {
          message.success(intl.formatMessage({ id:'company_added_success_msg'}));
          getAllCompany(1, pageSize)
        }).catch(err => {
          message.error(err?.response?.data?.message === 'Validation error' ? 'A Company with this business Id is already added' : (err?.response?.data?.message || 'Server Error'));
        }) 
    
  //   try{

  //   const res = await axios.post(
  //     `${domainUrl}/masterservicenew/master/company`,
  //     body,
  //     { headers }
  //   );
  //   console.log(res);
  // }
  // catch(err) {
  //   console.log('err', err);
  // }

    getAllCompany(1, pageSize);
    closeCompanyForm();
  };

  const deleteCompany = (id) => {
    setDeleteComp(id);
    setShowConfirmationBox(true);
  };

  const declineSubmit = () => {
    setShowConfirmationBox(false);
    setDeleteComp({});
  }

  const confirmDelete = async () => {
    // const res = 
    await axios.delete(
      `${domainUrl}/masterservicenew/master/company/${deleteComp}`,
      {
        headers: {
          client_code: "ht_front",
          token: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    getAllCompany(1, pageSize);
    setShowConfirmationBox(false);
    setDeleteComp({});
  };

  const onPaginationChange = (pageDetails) => {
    setPageSize(pageDetails.pageSize);
    getAllCompany(pageDetails.current, pageDetails.pageSize);
}

  return (
    <>
      <Header />
      <div className="mainContainer">
        <div className="innerContainer">
        <h2 className="title"> <FormattedMessage id={'companies'} /></h2>
          <Table
              className="ant-table-root"
              // scroll={{ x: 0, y: '40vh' }}
              columns={columns}
              dataSource={companies}
              pagination={{ defaultPageSize: pageSize, showSizeChanger: false, total: totalCount }}
              onChange={onPaginationChange}
              bordered
              rowKey='company_id'
              locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
            />

          <div className="button1" onClick={() => openCompanyForm(null, { readOnly: false })}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.701"
              height="29.701"
              viewBox="0 0 29.701 29.701"
            >
              <path
                fill="#fff"
                d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
                transform="translate(-1 -1)"
              />
            </svg>
            <span >
              {" "}
              <FormattedMessage id={'addnew'} />
            </span>
          </div>
        </div>
      </div>

      {openCompanyFormModel?.isOpen && (
        <AddModal
          readOnly={openCompanyFormModel?.isReadOnly}
          data={company}
          role_id={role_id}
          onClose={closeCompanyForm}
          onSubmit={addCompany}
        />
      )}
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={<FormattedMessage id={'sure_to_delete_company'} />} confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />
   
    </>
  );
};

export default Company;