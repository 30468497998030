/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import Moment from 'react-moment';
import { Typography, Tooltip, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from "react-intl";
import { changeDateFormat } from '../../../utils/common';

const { Paragraph } = Typography;

const ReportAsset = ({data, onVaultSelect}) => {
    const history = useHistory();
	const [reportData,setReportData] = useState([]);
	const intl = useIntl();
	useEffect(() => {
		if(data) {
			const sortedData = data.sort(function(a,b){
				return new Date(b.inspection_date) - new Date(a.inspection_date);
			  });
			setReportData(sortedData);
		}
	},[data]);

    const columns = [
        {
			dataIndex: "inspection_date",
			title:<FormattedMessage id= "Report Date"/>,
            key: "inspection_date",
			sorter: (a, b) => moment(a.inspection_date) - moment(b.inspection_date),
            render: (value, tableMeta, updateValue) => changeDateFormat(value,intl?.locale),
		},
        {
			dataIndex: "inspected_by",
			title:<FormattedMessage id= "Reported By"/>,
            key: "inspected_by",
		},
		{
			dataIndex: "inspection_id",
			title:<FormattedMessage id="view"/>,
            key: "asset_id",
			width: 100,
			render: (value,row) => (
				
				< div style={{display:"flex"}}>
					<div
						className="viewIcon"
						onClick={() => history.push(`/inspection-report/${value}`)}
					>
						<FormattedMessage id="view"/>
					</div>
					<Tooltip title="Copy report">
						<div
							className="copyIcon"
							onClick={() => {localStorage.setItem('copied_report_id', value);history.push(`/inspection-report/add/${row?.asset_id}`)}}
						>
								<FormattedMessage id="copy"/>
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	return (
		<>
            <Table
              className="ant-table-root table-inspection"
			//   scroll={{ x: 0, y: '40vh' }}
              columns={columns}
              dataSource={reportData}
              bordered
              rowKey='inspection_id'
			  locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
            />
        </>
    )
};

ReportAsset.propTypes = {
    
  };

export default ReportAsset;