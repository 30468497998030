/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { message, Spin } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import "../../Tender/Summary/Summary.css";
import AwardCompletion from "./AwardCompletion";
import { domainUrl, awsDocumentUrl } from "../../../helper";
import { genHeaders } from "../../Tender/Tender";
import SendQuestion from '../SendQuestion';
import { changeDateFormat } from '../../../utils/common';

const Summary = ({ tenderId, hidebuttons, allowQs }) => {
  const { onStepChange: move } = {
    onStepChange: null,
  };

  const history = useHistory();
  const intl=useIntl();
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const editIconProps = {
    style: {
      borderRadius: "50%",
      color: "white",
      background: "#2B7776",
      padding: "10px",
    },
  };

  const onFileClick = async (file) => {
    try {
      if (file?.document_id) {
        const documentDetails = await axios.get(
          `${awsDocumentUrl}/Prod/v1/document/${file.document_id}`
        );

        let link;
        if ((link = documentDetails?.data?.data?.signed_link)) {
          const win = window.open(link, "_blank");
          win.focus();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const getInfo = async () => {
      const assetsCall = axios
        .get(`${domainUrl}/tender/v1/tender/${tenderId}/asset`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const generalInfoCall = axios
        .get(`${domainUrl}/tender/v1/tender/${tenderId}`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const filesCall = axios
        .get(
          `${awsDocumentUrl}/Prod/v1/document?entity_type=T&entity_id=${tenderId}`,
          { token: sessionStorage.getItem("token") }
        )
        .catch((e) => {
          console.error(e);
          return null;
        });

      const suppliersCall = axios
        .get(`${domainUrl}/tender/v2/tender/${tenderId}/supplier`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });
        const bidDetails = axios
          .get(`/bid/v1/tender/${tenderId}/bid?is_submitted=1`, genHeaders())
          // .then((response) => {
          //   if (response.data.success) {
          //     setLoading(false);
          //     setData(response.data.data.result);
          //     setAwardedSupplier(response?.data?.data?.result[0]?.winner_id);
          //   } else {
          //     message.error(response.data.message);
          //   }
          // })
          .catch((error) => {
            console.error(error);
            message.error("Could not receive bid information");
            return null;
            // history.goBack();
          });
      try {
        // const awardedCall = axios.get(
        //   `${domainUrl}/tender/v2/tender/supplier?status=awarded&tender_id=${tenderId}`,
        //   genHeaders()
        // );

        let title,
          description,
          tender_type,
          additional_info,
          items,
          files,
          suppliers,
          created_by,
          bids,
          comments,
          award_comment,
          completion_comment,
          winner_id;

        const [
          assetsRes,
          generalInfoRes,
          filesRes,
          suppliersRes,
          //awardedCallRes,
          bidsRes,
        ] = await Promise.all([
          assetsCall,
          generalInfoCall,
          filesCall,
          suppliersCall,
         // awardedCall,
         bidDetails,
        ]);

        if (assetsRes) {
          const itemsRes = await Promise.all(
            assetsRes.data.data.assets.map((el) =>
              axios
                .get(
                  `${domainUrl}/tender/v2/tender/asset/${el.tender_line_id}/items`,
                  genHeaders()
                )
                .catch((e) => {
                  console.error(e);
                  return [];
                })
            )
          );

          items = itemsRes.map((el, index) => {
            const key =
              assetsRes?.data?.data?.assets[index]?.line_text ??
              assetsRes?.data?.data?.assets[index]?.asset_name;
            const value = el.data.data.items;
            return {
              key,
              value,
            };
          });
        }

        if (generalInfoRes) {
          ({
            title,
            description,
            additional_info,
            tender_type,
            created_by,
            winner_id,
            comments,
            award_comment,
            completion_comment,
          } = generalInfoRes.data.data);

          tender_type = tender_type === "r" ? <FormattedMessage id="Repair"/> :tender_type === "m" ? "Maintenance" : <FormattedMessage id="Others"/>;
        }

        if (filesRes) {
          files = filesRes?.data?.data?.documents;
        }

        if (suppliersRes) {
          suppliers = suppliersRes?.data?.data?.suppliers;
        }
        if (bidsRes) {
          bids = bidsRes?.data?.data?.result;
        }
        // if (awardedCallRes?.data?.data?.result?.length === 1) {
        //   winner_id = awardedCallRes?.data?.data?.result[0]?.winner_id;
        // } else {
        //   winner_id = undefined;
        // }

        setInfo({
          title,
          description,
          tender_type,
          additional_info,
          comments,
          award_comment,
          completion_comment,
          items,
          files,
          suppliers,
          closing_date: generalInfoRes?.data?.data?.closing_date,
          winner_id,
          created_by,
          bids,
        });
      } catch (e) {
        console.error(e);
        message.error(
          e?.message ?? "Unable to retrieve information from server."
        );
      } finally {
        setLoading(false);
      }
    };

    getInfo();
  }, []);

  if (loading)
    return <Spin style={{ display: "flex", justifyContent: "center" }} />;
  return (
    <div className="summaryOuter">
      
      <div className="hedding">
        <h2><FormattedMessage id={'Tender_Details'}/></h2>
      </div>
      <div className="mainContainer">
        <div className="textBlock">
          <div className="labelBLock"><FormattedMessage id={'name'}/></div>
          <div className="contentBLock">{info?.title}</div>
        </div>
        <div className="textBlock">
          <div className="labelBLock"><FormattedMessage id={'desc'}/></div>
          <div style={{ whiteSpace: "pre-wrap" }} className="contentBLock" dangerouslySetInnerHTML={{ __html: info?.description }}>
          </div>
        </div>
        <div className="textBlock">
          <div className="labelBLock"><FormattedMessage id={'tenderType'}/></div>
          <div className="contentBLock">{info?.tender_type}</div>
        </div>
      </div>

      <div className="mainContainer">
        <div className="titleSummary">
          <div className="titile"><FormattedMessage id={'items'}/></div>
        </div>
        {info?.items?.map((item, index) => (
          <React.Fragment key={index}>
            <div
              style={{ margin: "10px 0", flexDirection: "column" }}
              className="textBlock list01"
            >
              <div className="labelBLock">{item?.key}</div>
              <ul>
                {item?.value?.map((i, itemIndex) => (
                  <li key={itemIndex}>{i?.item_text}</li>
                ))}
              </ul>
            </div>
            <div className="bdrb"></div>
          </React.Fragment>
        ))}
      </div>

      <div className="mainContainer">
        <div className="titleSummary">
        <div className="titile"><FormattedMessage id="files"/></div>
        </div>

        {info?.files?.map((file, fileIndex) => (
          <div
            onClick={() => onFileClick(file)}
            key={fileIndex}
            style={{ cursor: "pointer" }}
            className="filesNames mt-2"
          >
            <div className="filesDes">
              <span><FormattedMessage id={'file_desc'}/></span>
              <span>{file?.file_description}</span>
            </div>
            <div className="fileFormat" style={{ padding: "0 20px" }}>
              <div className="fileType">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="51.467"
                  height="68.622"
                  viewBox="0 0 51.467 68.622"
                >
                  <path
                    id="Icon_awesome-file-image"
                    data-name="Icon awesome-file-image"
                    d="M51.467,16.344v.812H34.311V0h.812A3.217,3.217,0,0,1,37.4.942L50.525,14.069A3.217,3.217,0,0,1,51.467,16.344Zm-18.228,5.1a3.226,3.226,0,0,1-3.217-3.217V0H3.217A3.217,3.217,0,0,0,0,3.217V65.406a3.217,3.217,0,0,0,3.217,3.217H48.25a3.217,3.217,0,0,0,3.217-3.217V21.444ZM15.084,23.589a6.433,6.433,0,1,1-6.433,6.433A6.433,6.433,0,0,1,15.084,23.589ZM42.962,55.756H8.651l.065-6.5,5.3-5.3a1.532,1.532,0,0,1,2.209.065l5.3,5.3L35.391,35.448a1.608,1.608,0,0,1,2.275,0l5.3,5.3Z"
                    fill="#2B7776"
                  />
                </svg>
              </div>
              <div className="fileInfo">
                <div className="text">{file?.file_name}</div>
                {/* <div className="fileSize">200kb</div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mainContainer">
        <div className="titleSummary">
          <div className="titile"><FormattedMessage id={'additional_info'}/></div>
        </div>
        <p style={{ whiteSpace: "pre-wrap" }} className="font-size-18" dangerouslySetInnerHTML={{ __html: info?.additional_info }}>
        </p>
      </div>
      {info?.comments &&
        <div className="mainContainer">
          <div className="titleSummary">
            <div className="titile"><FormattedMessage id={'Recommendation Comment'}/></div>
          </div>
          <p style={{ whiteSpace: "pre-wrap" }} className="font-size-18" dangerouslySetInnerHTML={{ __html: info?.comments }}>
          </p>
        </div>
      }
      {info?.award_comment &&
        <div className="mainContainer">
          <div className="titleSummary">
            <div className="titile"><FormattedMessage id={'Award Comment'}/></div>
          </div>
          <p style={{ whiteSpace: "pre-wrap" }} className="font-size-18" dangerouslySetInnerHTML={{ __html: info?.award_comment }}>
          </p>
        </div>
      }
      {info?.completion_comment &&
        <div className="mainContainer">
          <div className="titleSummary">
            <div className="titile"><FormattedMessage id={'Completion Comment'}/></div>
          </div>
          <p style={{ whiteSpace: "pre-wrap" }} className="font-size-18" dangerouslySetInnerHTML={{ __html: info?.completion_comment }}>
          </p>
        </div>
      }
      {
        info.bids?.length > 0 && (
        <div className="mainContainer">
          <div className="titleSummary">
            <div className="titile"><FormattedMessage id="Bids_Receieved" /></div>
          </div>
          <div className="list01">
            <ul>
              {info?.bids?.map((bid, index) => (
                <li key={index}>{bid?.supplier_name}
                  <ol>
                    <li>Total : €{bid?.sub_total}</li>
                  </ol>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className="mainContainer">
        <div className="textBlock">
          <div className="labelBLock"><FormattedMessage id={'closing_date'}/></div>
          <div className="contentBLock">{changeDateFormat(info?.closing_date,intl?.locale)}</div>
        </div>
      </div>
     
      {info?.winner_id && <AwardCompletion tender={info} winner={info?.winner_id} />}
    </div>
  );
};

export default Summary;
