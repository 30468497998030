/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Card, Spin, Button, DatePicker, Modal, message } from "antd";
import FileUpload from "../../../FileUpload";
import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";
import axios from "axios";
import moment from 'moment';
import { genHeaders } from "../../../Tender/Tender";
import './AwardCompletion.less';
import { sendEmailCommunication } from "../../../../actions/communication";
import { FormattedMessage } from "react-intl";
import FileDisplay from "../../../FileDisplay";
import EmailFormatter from "../../../EmailFormatter";
import { useIntl } from "react-intl";
import { changeDateFormat } from '../../../../utils/common';

const AwardCompletion = ({ winner, tender }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [formattedDate, setFormattedDate] = useState('');
  const [fileList, setFileList] = useState([]);
  const [fileListUpdated, setFileListUpdated] = useState([]);
  const [comment, setComment] = useState();
  const [error, setError] = useState();
  const [info, setInfo] = useState({});
  const { tenderId } = useParams();
  // console.log(tender,"======tender")
  const [sentEmailModel, setSentEmailModel] = useState(false);
  const emailSections = [
    {
      value: "Tender Info",
      selected: false,
    },
  ];
  useEffect(() => {
    const getCompletionInfo = async () => {
      setPageLoading(true);
      setError();
      try {
        const r = await axios
          .get(`/tender/v3/tender/${tenderId}/management?supplier_id=${winner}`, genHeaders())
          .catch((e) => {
            if (e?.response?.status === 404) {
              // console.log("no data found");
              return;
            }
            throw e;
          });

        if (r?.data?.data) {
          setInfo(r.data.data);
            setFormattedDate(moment(r.data.data.completion_date))
        }
      } catch (e) {
        setError("Unable to get progress/completion information");
      } finally {
        setPageLoading(false);
      }
    };

    getCompletionInfo();
  }, []);

  const updateFileList = (files) => {
    setFileListUpdated(files)
  }



  let content;

  if (pageLoading) {
    content = <Spin style={{ display: "flex", justifyContent: "center" }} />;
  } else if (error) {
    content = <h3 style={{ textAlign: "center" }}>{error}</h3>;
  } else {
    content = (
      <>
        <div className="textBlock">
        <div className="labelBLock"><FormattedMessage id='completion_date'/></div>
          <div
            className="contentBLock"
            style={{
              // marginLeft: "auto",
              display: "flex",
              // justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
          
              <p className="completion_date">{formattedDate ? changeDateFormat(formattedDate,intl?.locale) : '-'}</p>
            
          </div>
           
        </div>
        <div className="textBlock">
          <div className="labelBLock"><FormattedMessage id='files_uploaded'/></div>
        </div>
        <FileDisplay entityType={'TM'} updateFileList={updateFileList} fileList={fileList} entityId={tenderId} hideTitle={true} deleteFiles={false}/>
      </>
    );
  }

  return (
    <div className="__AwardCompletion__">
      <div className="hedding">
      <h2> <FormattedMessage id="Date and progress"/></h2>
      </div>
      <div className="mainContainer">{content}</div>
      <div className="container f-col">
        {/* <div className="btn cancelBtn"  onClick={() => setShowPreview(true)}><FormattedMessage id="preview" /></div> */}
        <div className="textBlock" style={{justifyContent: 'center'}}>
          <div onClick={() => setSentEmailModel(true)} className="btn">
            <FormattedMessage id="email_info" />
          </div>
        </div>
      </div>
      {sentEmailModel && (
          <EmailFormatter
            tenderManagementInfo={info}
            tenderInfo={tender}
            isOpen={sentEmailModel}
            onClose={() => setSentEmailModel(false)}
            contentList={emailSections}
            tender_id={tenderId}
          />
      )}
    </div>
  );
};

export default AwardCompletion;
