/* eslint-disable no-unused-vars */
import React from "react";
import { Row, Col } from "antd";
import Header from "../Header";
import "../../components/SupplierDashboard/SupplierDashboard.css";
import TenderCard from "../__TenderCard";
import { useHistory } from "react-router";
import MessagesCard from "../MessagesCard";

const Dashboard = (props) => {

  const history = useHistory();
  return (
    <>
      <Header />
      <div className="container-fluid">
        <Row /*gutter={[20, 3]} style={{width: '100%'}}*/ style={{marginTop: '10px'}} justify="center">
          <Col md={24} xl={14} className="leftSidePanel">
            <TenderCard status="completed_all" />
            <TenderCard status="awarded" />
            <TenderCard status="submitted" />
            <TenderCard status="progress" />
            <TenderCard status="draft" />
            <TenderCard status="pre" />
            
            {/* </TenderCard> */}
          </Col>
          <Col  md={24} xl={10} className="rightSidePanel">
            <MessagesCard />
          </Col>
        </Row>
        {/* <div className="rightSidePanel">
          <MessagesCard />
          <div className="card02 mt-2">
            <div className="searchForm">
              <div class="dashboardTitle">
                <div>Search Tenders</div>
              </div>

              <div className="formGroup">
                <label>Title</label>
                <div className="costumSelect">
                  <select name="country" id="slct" className="inputFiled">
                    <option value="">Choose an option</option>
                    <option value="1">Country 1</option>
                    <option value="2">Country 2</option>
                    <option value="3">Country 3</option>
                  </select>
                </div>
              </div>
              <div className="formGroup">
                <label>Housing Company</label>
                <div className="costumSelect">
                  <select name="country" id="slct" className="inputFiled">
                    <option value="">Choose an option</option>
                    <option value="1">Country 1</option>
                    <option value="2">Country 2</option>
                    <option value="3">Country 3</option>
                  </select>
                </div>
              </div>
              <div className="formGroup">
                <label>Closing date less than</label>
                <div className="days">
                  <input className="inputFiled" />
                  <span>Days</span>
                </div>
              </div>
              <div className="formGroup">
                <label>Country</label>
                <div className="costumSelect">
                  <select name="country" id="slct" className="inputFiled">
                    <option value="">Choose an option</option>
                    <option value="1">Country 1</option>
                    <option value="2">Country 2</option>
                    <option value="3">Country 3</option>
                  </select>
                </div>
              </div>

              <div className="formGroup">
                <label>City</label>
                <div className="costumSelect">
                  <select name="country" id="slct" className="inputFiled">
                    <option value="">Choose an option</option>
                    <option value="1">Country 1</option>
                    <option value="2">Country 2</option>
                    <option value="3">Country 3</option>
                  </select>
                </div>
              </div>
              <div className="formGroup">
                <label>Address</label>
                <textarea className="inputFiled textarea01"></textarea>
              </div>
              <div className="formGroup">
                <div className="searchBtn"><FormattedMessage id="search" /></div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
