import React, { useState, useEffect } from "react";
import { 
	useDispatch, 
	// useSelector 
} from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// import Moment from 'react-moment';
import {Spin, Card, Form, Input, Button, Radio, Row, Col, Divider, DatePicker, Space, Select } from 'antd';

import InspectionCategory from './InspectionCategory';
import FileUpload from '../FileUpload';
import FileDisplay from '../FileDisplay';

import { fetchMasterInspectionData, updateInspectionReports, createInspectionReportsLine, fetchAllInspectionReports, fetchAllInspectionRemarks } from "../../actions/inspection-report";
import { 
	// awsDocumentUrl, 
	domainUrl 
} from "../../helper";
// import { inspectionReportSummary } from "../../utils/constants";
import { FormattedMessage,useIntl } from "react-intl";
// import { groupByKey } from "../../utils";
var htmlPdfClient = require("html-pdf-client")


const tailLayout = {
	wrapperCol: { offset: 10, span: 24 },
};
const FILE_POST_URL = `${domainUrl}/tender/v1/document`;

// const { Option } = Select;
const InspectionReport = ({ copiedFiles, reportDetails,fetchReport, inspectionReportId, finishReportCreation, isReadOnly, ref }) => {
	// console.log(reportDetails,"====reportDetails")
	const history = useHistory();
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [summaryAnswerOneOptions, setSummaryAnswerOneOptions] = useState([]);
	const [summaryAnswerTwoOptions, setSummaryAnswerTwoOptions] = useState([]);
	const [summaryAnswerThreeOptions, setSummaryAnswerThreeOptions] = useState([]);
	const [summaryAnswerFourOptions, setSummaryAnswerFourOptions] = useState([]);
	const [summaryAnswerOne, setSummaryAnswerOne] = useState((reportDetails?.summary_answer_one != null) ? [{label:reportDetails?.summary_answer_one}] : []);
	const [summaryAnswerTwo, setSummaryAnswerTwo] = useState((reportDetails?.summary_answer_two != null) ? [{label:reportDetails?.summary_answer_two}] : []);
	const [summaryAnswerThree, setSummaryAnswerThree] = useState((reportDetails?.summary_answer_three != null) ? [{label:reportDetails?.summary_answer_three}] : []);
	const [summaryAnswerFour, setSummaryAnswerFour] = useState((reportDetails?.summary_answer_four != null) ? [{label:reportDetails?.summary_answer_four}] : []);
	const [inspectionMaster, setInspectionMaster] = useState([]);
	const [remarksOptions, setRemarksOptions] = useState([]);
	const [fileList, setFileList] = useState([]);
	// const [assetName, setAssetName] = useState(reportDetails?.asset_name);
	const [assetName] = useState(reportDetails?.asset_name);
	const [inspectionReports, setInspectionReports] = useState([]);
    const intl=useIntl()  
	const dispatch = useDispatch();
	
	const fetchInspectionReports = async (/*values={}*/) => {
		setIsLoading(true);
		let params = {};
		params.limit = 100;
		params.asset_name = assetName;
		const response = await dispatch(fetchAllInspectionReports(params));
		const reportData = Object.values(response.payload.entities?.inspections || []);
		setIsLoading(false);
		setInspectionReports(segKeyData('inspection_name','inspection_id',reportData));
	};
	useEffect(() => {
		const getMasterReport = async() => {
		  const inspection = await dispatch(fetchMasterInspectionData({limit: 1000}));
		  setInspectionMaster( Object.values(inspection?.payload?.entities?.masterinspections || []));
		  
		}
		if(!isReadOnly){
			getMasterReport();
			fetchInspectionReports();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	// console.log(inspectionMaster,"====inspectionMaster")
	
	useEffect(()=>{
		const fields = form.getFieldsValue();
		if(inspectionMaster?.length && fields?.lineItems){
			// if(inspection?.payload?.entities?.masterinspections && Object.values(inspection?.payload?.entities?.masterinspections || [])?.length){
				let inspectionLines = inspectionMaster;
				// eslint-disable-next-line array-callback-return
				inspectionLines?.length && inspectionLines?.map(inspectionLine => {
					// eslint-disable-next-line array-callback-return
					inspectionLine?.inspection_items?.length && inspectionLine?.inspection_items?.map(inspectionItem =>{
						if(inspectionItem?.inspection_charges?.length){
							// eslint-disable-next-line array-callback-return
							inspectionItem?.inspection_charges?.map(inspectionCharge =>{
								if(inspectionCharge && inspectionCharge?.display_name === 'Ok' && inspectionCharge?.inspection_item_id){
									let inspectionAMCcost = [inspectionCharge?.display_name,0, inspectionCharge?.inspection_amc_id];
									let inspectionItemValue = 0;
									// let remarks = [inspectionCharge?.inspection_remarks];
									fields.lineItems[inspectionCharge?.inspection_item_id].inspection_amc_cost = inspectionAMCcost;
									fields.lineItems[inspectionCharge?.inspection_item_id].inspection_item_value = inspectionItemValue;
									// fields.lineItems[inspectionCharge?.inspection_item_id].inspection_remarks = remarks;
								}
							})
						}
					})
				})
				form.setFieldsValue(fields);
			// }
		}
	},[inspectionMaster, form])
	
	const addFileList = (files) => {
		setFileList({
			...fileList,
			...files
		})
	}

	const onFinish = async (values) => {
		setIsLoading(true);
		let reportData= {
			inspection_name: values.inspection_name,
			admin_description: values?.admin_description,
			inspected_by: values?.inspected_by,
			buyer_name: values?.buyer_name,
			buyer_address: values?.buyer_address,
			inspection_date: moment(values?.inspection_date).format('YYYY-MM-DD'),
			answer_one: values?.answer_one,
			answer_two: values?.answer_two,
			total_year_one: values?.total_year_one?.toString(),
			total_year_two: values?.total_year_two?.toString(),
			total_year_three: values?.total_year_three?.toString(),
			total_year_four: values?.total_year_four?.toString(),
			total_year_five: values?.total_year_five?.toString(),
			total_year_six: values?.total_year_six?.toString(),
			// total_year_one: parseInt(values?.total_year_one),
			// total_year_two: parseInt(values?.total_year_two),
			// total_year_three: parseInt(values?.total_year_three),
			// total_year_four: parseInt(values?.total_year_four),
			summary_answer_one: values?.summary_answer_one?.toString(),
			summary_answer_two: values?.summary_answer_two?.toString(),
			summary_answer_three: values?.summary_answer_three?.toString(),
			summary_answer_four: values?.summary_answer_four?.toString(),
		}
		
		const reportResponse = await dispatch(updateInspectionReports(reportData, inspectionReportId));
		let reportLines = [];
		// eslint-disable-next-line array-callback-return
		values.lineItems.map((item,index) => {
			let obj = {};
			if(item) {
				obj.inspection_item_id = parseInt(Object.keys(values.lineItems)[index]);
				if(item.inspection_amc_cost) {
					if(typeof item.inspection_amc_cost != 'object'){
						item.inspection_amc_cost = item.inspection_amc_cost.split(',');
					}
					obj.inspection_amc_id = parseInt(item.inspection_amc_cost[2]);
					obj.inspection_item_value = parseInt(item.inspection_item_value) || parseInt(item.inspection_amc_cost[1]);
					obj.inspection_remarks = item?.inspection_remarks?.length ? item?.inspection_remarks[0] : '';
					reportLines.push(obj);
				}
			}
		});
		for(let i=0; i<reportLines.length; i++) {
			// const reportLinesResponse = 
			await dispatch(createInspectionReportsLine(reportLines[i], inspectionReportId));
		}

		setIsLoading(false);
		finishReportCreation(reportResponse.payload?.result[0] === inspectionReportId || false);

	};

	const onFinishFailed = (errorInfo) => {
	};

	const onChange = (date, dateString) => {
	}

	const total_cost = [
		{ 'total_year_one': 0 },
		{ 'total_year_two': 0 },
		{ 'total_year_three': 0 },
		{ 'total_year_four': 0 },
		{ 'total_year_five': 0 },
		{ 'total_year_six': 0 },
	]

	const calculateCost = (arrayValue, lineItemId, updateItemValue) => {
		// let index = parseInt(arrayValue[0])-1;
		if(updateItemValue) {
			const fields = form.getFieldsValue();
			fields.lineItems[lineItemId].inspection_amc_cost = fields.lineItems[lineItemId]?.inspection_amc_cost?.split(',');
			fields.lineItems[lineItemId].inspection_item_value = parseInt(arrayValue[1]);
			// console.log(fields,"===fields")
			form.setFieldsValue(fields);
		}
		// if(index < 5) {
		// 	let key = Object.keys(total_cost[index]);
		// 	let cost_key = form.getFieldValue(['lineItems',lineItemId,'inspection_item_value']);
		// 	let value = parseInt(form.getFieldValue(key))+parseInt(cost_key);
		// 	form.setFieldsValue({ [key]: value });
		// }
		// console.log(form.isFieldTouched(['lineItems',lineItemId,'inspection_amc_cost']),"======form")
	}

	const setInitValues = () => {
		// eslint-disable-next-line array-callback-return
		total_cost.map(cost => {
			let key = Object.keys(cost)[0];
			form.setFieldsValue({ [key]: 0 });	
		})
	}

	const onFieldsChange = (changedFields, allFields) => {
		// console.log(changedFields,"======changedFields");
		// console.log(allFields,"====== allFields");
		let fieldEdited = changedFields[0];
		if(changedFields[0].name?.length>1 && ((changedFields[0].name[2] === 'inspection_amc_cost'  && changedFields[0]?.value?.split(',')?.length === 3) || changedFields[0].name[2] === 'inspection_item_value' )) {
			setInitValues();
			// eslint-disable-next-line array-callback-return
			allFields.map(changedFields => {
				if(typeof changedFields?.value == 'string'){
					changedFields.value = changedFields?.value?.split(',');
				}
				if(changedFields.name?.length>1 && changedFields.name[2] === 'inspection_amc_cost' && changedFields?.value?.length === 3) {
					calculateCost(changedFields?.value, changedFields.name[1], changedFields.name[1] === fieldEdited.name[1] && fieldEdited.name[2] === 'inspection_amc_cost' ? true : false);
				}
			})
		}
	}
	const segKeyData = (key,valueKey, data) => {
		let keyArray = data.map(obj => { return obj[key] ? {label: obj[key], value: String(obj[valueKey])}:{label: '', value: ''}});
		
		return keyArray.filter((value, index, self) => self.map(x => x?.value).indexOf(value?.value) === index);
	}
	const handleSearch = async (value, key) => {
		if(value) {
			let params = {};
			params.limit = 100;
			params[key]=value;
    		const response = await dispatch(fetchAllInspectionReports(params));
			const options = Object.values(response.payload.entities?.inspections || []);
			
			let options1 = [...new Map(options.map(item => [item[key], item])).values()];
			if(key === 'summary_answer_one'){
				setSummaryAnswerOneOptions(segKeyData(key,'inspection_id',options1));
			}
			if(key === 'summary_answer_two'){
				setSummaryAnswerTwoOptions(segKeyData(key,'inspection_id',options1));
			}
			if(key === 'summary_answer_three'){
				setSummaryAnswerThreeOptions(segKeyData(key,'inspection_id',options1));
			}
			if(key === 'summary_answer_four'){
				setSummaryAnswerFourOptions(segKeyData(key,'inspection_id',options1));
			}
		}
		else {
			if(key === 'summary_answer_one'){
				setSummaryAnswerOneOptions([]);
			}
			if(key === 'summary_answer_two'){
				setSummaryAnswerTwoOptions([]);
			}
			if(key === 'summary_answer_three'){
				setSummaryAnswerThreeOptions([]);
			}
			if(key === 'summary_answer_four'){
				setSummaryAnswerFourOptions([]);
			}
		}
	}

	const handleRemarksSearch = async (value, key) => {
		if(value) {
			let params = {};
			params.limit = 100;
			params[key]=value;
    		const response = await dispatch(fetchAllInspectionRemarks(params));
			const options = Object.values(response.payload.entities?.inspections || []);
			let options1 = [...new Map(options.map(item => [item[key], item])).values()];
			setRemarksOptions(segKeyData(key,'inspection_remarks',options1))
		}
	}

	const Print = async() =>{
		setIsLoading(true)
		var element = document.getElementById('printablediv');
        var opt = {
            margin:        0.1,
            filename:     'TenderIn '+ reportDetails?.inspection_name.trim() + '.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2,},
            jsPDF:        { unit: 'in', format: 'A4', orientation: 'portrait',compressPDF: true },
            pagebreak:    { after: '.page-break' }
        };
        await htmlPdfClient().set(opt).from(element).toPdf().save();
		setIsLoading(false)
	}
	useEffect(()=>{
		if(reportDetails?.inspection_name){
			setIsLoading(true);
			// console.log(reportDetails,"======reportDetails in useEffect")
			// eslint-disable-next-line array-callback-return
			Object.keys(reportDetails)?.map((key)=>{
				if(key === 'inspection_date'){
					delete reportDetails[key];
				}
				const fields = form.getFieldsValue();
				// console.log(fields,"===fields")

				// eslint-disable-next-line array-callback-return
				fields?.lineItems && reportDetails?.inspection_lines?.length && reportDetails?.inspection_lines?.map((lines,index) =>{
					// eslint-disable-next-line array-callback-return
					lines?.inspection_lines?.length && lines?.inspection_lines?.map((line,index)=>{
						// console.log(line,"======line new");
						// console.log([line?.display_name,line?.inspection_item_value, line?.inspection_amc_id],"======[line?.display_name,line?.inspection_item_value, line?.inspection_amc_id]");
						if(line && line?.inspection_item_id){
							let inspectionAMCcost = [line?.display_name,line?.inspection_item_value  || 0, line?.inspection_amc_id];
							let inspectionItemValue = line?.inspection_item_value || 0;
							let remarks = line?.inspection_remarks ? [line?.inspection_remarks] : null;
							fields.lineItems[line?.inspection_item_id].inspection_amc_cost = inspectionAMCcost;
							fields.lineItems[line?.inspection_item_id].inspection_item_value = inspectionItemValue;
							if(remarks)
								fields.lineItems[line?.inspection_item_id].inspection_remarks = remarks;
							// console.log(fields,"===fields")
						}
					})
					form.setFieldsValue(fields);
				})
				form.setFieldsValue({[key]: reportDetails[key]});
			})
		}
		setIsLoading(false)
	},[reportDetails, form])
	// console.log(reportDetails,"======reportDetails after form set")

	// console.log(form.getFieldsValue(),"====form")
	return (
		<>
		<Spin spinning={isLoading}>
			<Card title="" id='printablediv' ref={ref} className="card-layout inpection-report-card mb-0">
				<Form
					form={form}
					name="basic"
					labelAlign='left'
					initialValues={{...reportDetails,...{address:reportDetails?.company_name+'\n'+reportDetails?.address}}}
					// initialValuesEqual={() => true}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					onFieldsChange={onFieldsChange}
				>
					<div className="page-break">
						<Row gutter={30}>
							<Col md={24} lg={6} xl={11}  span={8}>
								<Form.Item
									label={intl.formatMessage({id:"Inspection Report Name"})}
									name="inspection_name"
									rules={[
										{
											required: true,
											message:<FormattedMessage id='Please enter inspection report name.'/>,
										},
									]}
								>
									<Input placeholder={intl.formatMessage({id:"Inspection Report Name"})} disabled={isReadOnly} />
								</Form.Item>
							</Col>
							{!isReadOnly && inspectionReports &&
								<Col md={24} lg={6} xl={11} span={8}>
									{/* <Form.Item
										label={<FormattedMessage id="Available Inspection Reports"/>}
									>
										<Select
										showSearch
										allowClear
										style={{ width: '100%' }}
										placeholder={<FormattedMessage id="Select Inspection Report to Copy"/>}
										onChange={(value) => fetchReport(value)}
										optionLabelProp="label"
										optionFilterProp="label"
										options={inspectionReports}
										>
										</Select>
									</Form.Item> */}
								</Col>
							}
						</Row>
						<Row gutter={30}>
							<Col md={24} lg={6} xl={11}>
								<h4><FormattedMessage id="Housing Company"/></h4>
								<Form.Item
									label={intl.formatMessage({id:"Housing Company To Be Inspected"})}
									name="address"
									labelCol={{lg:{span: 12, offset: 0}, md:{span: 8, offset: 0}}}
								>
									<Input.TextArea disabled/>
								</Form.Item>
							</Col>
							<Col md={24} lg={12} xl={12}>
								<h4><FormattedMessage id="property_manager"/></h4>
								<Form.Item
									label={intl.formatMessage({id:"label_name"})}
									name="buyer_name"
									labelCol={{lg:{span: 8, offset: 0}, md:{span: 8, offset: 0}}}
								>
									<Input />
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({id:"address"})}
									name="buyer_address"
									labelCol={{lg:{span: 8, offset: 0}, md:{span: 8, offset: 0}}}
								>
									<Input.TextArea />
								</Form.Item>
							</Col>
							<Col md={24} lg={12} xl={12}>
								<h4><FormattedMessage id="Evaluator"/>:</h4>
								<Form.Item
									label={intl.formatMessage({id:"Creator"})}
									name="inspected_by"
									labelCol={{lg:{span: 8, offset: 0}, md:{span: 8, offset: 0}}}
								>
									<Input disabled={isReadOnly} />
								</Form.Item>
								{
									!isReadOnly ? (
										<Form.Item
									label={intl.formatMessage({id:"inspection_date"})}
									name= "inspection_date"
									labelCol={{lg:{span: 8, offset: 0}, md:{span: 8, offset: 0}}}
								>
										<DatePicker defaultValue={moment()} onChange={onChange} placeholder={intl.formatMessage({id:"Select date"})} locale={intl.messages?.datepickerLocale} format={intl.messages?.datepickerLocale?.dateFormat}/>
									
									</Form.Item>
									) : (
										<Form.Item
									label={intl.formatMessage({id:"inspection_date"})}
									labelCol={{lg:{span: 8, offset: 0}, md:{span: 8, offset: 0}}}
								>
									<Input disabled={isReadOnly} value={reportDetails.inspection_date && moment(reportDetails.inspection_date).format("DD-MM-YYYY")}/>
									
								</Form.Item>
									)
								}
							</Col>
						</Row>
						<Divider />
						<Row gutter={30}>
							<Col span={24}>
								<h3>{reportDetails?.asset_name}</h3>
							</Col>
							{
								reportDetails?.asset_properties?.map((prop, index) => (
									<Col md={24} lg={12} xl={12} key={index}>
										<Form.Item
											label={intl.formatMessage({id:prop.property_name})}
											labelCol={{span: 24, offset: 0}}
										>
											<Input value={prop.property_value} disabled/>
										</Form.Item>
									</Col>
								))
							}

						</Row>
						<Divider />
						<div className="report_corrections">

							<h2><FormattedMessage id="FUTURE CORRECTIONS"/></h2>
							<p><FormattedMessage id="Fitness rating"/>:</p>
							<Row>
								<Col md={12} lg={12} xl={12}>
									1. <FormattedMessage id="To be repaired immediately"/>
								</Col>
							</Row>
							<Row>
								<Col md={12} lg={12} xl={12}>
									2. <FormattedMessage id="To be repaired within 1-2 years"/>
								</Col>
							</Row>
							<Row>
								<Col md={12} lg={12} xl={12}>
									3. <FormattedMessage id="To be repaired within 2-3 years"/>
								</Col>
							</Row>
							<Row>
								<Col md={12} lg={12} xl={12}>
									4. <FormattedMessage id="To be repaired within 3-4 years"/>
								</Col>
							</Row>
							<Row>
								<Col md={12} lg={12} xl={12}>
									5. <FormattedMessage id="To be repaired within 4-5 years"/>
								</Col>
							</Row>
							<Row>
								<Col md={12} lg={12} xl={12}>
									OK. <FormattedMessage id="All OK, No cost within 5 years"/>
								</Col>
							</Row>
						</div>
					</div>
					
					{
						!isReadOnly && inspectionMaster.map((category,index) => (
							<>
								<InspectionCategory inspectionLines={reportDetails?.inspection_lines?.length && reportDetails?.inspection_lines?.filter((cat)=>cat?.inspection_category_name === category?.inspection_category_name) } remarksOptions={remarksOptions} handleRemarksSearch={handleRemarksSearch} form={form} categoryData={category} isReadOnly={isReadOnly} indexKey={index}/>
								<Divider />
							</>
						))
					}

					{
						isReadOnly && reportDetails?.inspection_lines.map((category,index) => (
							<>
								<div className="page-break">
									<InspectionCategory categoryData={category} isReadOnly={isReadOnly} indexKey={index}/>
									<Divider />
								</div>
							</>
						))
					}
					<div className="page-break">
						<Row>
							<Col span={24}>
							<h3><FormattedMessage id="FAULT FREQUENCY ACCORDING TO SERVICE JOURNAL"/></h3>
							</Col>
							<Form.Item label=""
									name="answer_one">
								<Radio.Group name='ansGroup1' disabled={isReadOnly} >
								<Space direction="vertical">
									<Radio value="1"><FormattedMessage id="Commendable, 0-1 bug last year"/></Radio>
									<Radio value="2"><FormattedMessage id="Good, 2-3 failures in the last year"/></Radio>
									<Radio value="3"><FormattedMessage id="Satisfactory, 4-5 failures in the last year"/></Radio>
									<Radio value="4"><FormattedMessage id="Bad, more than 5 failures in the last year"/></Radio>
									</Space>
							</Radio.Group>
							</Form.Item>
						</Row>

						<Row>
							<Col span={24}>
								<h3><FormattedMessage id="SPARE PARTS AVAILABILITY"/></h3>
							</Col>
							<Form.Item label="" name="answer_two">
								<Radio.Group name='ansGroup2' disabled={isReadOnly} >
									<Space direction="vertical">
										<Radio value="1"><FormattedMessage id="Spare parts are well available."/></Radio>
										<Radio value="2"><FormattedMessage id="The availability of spare parts may decline in 5 years."/>	</Radio>
										<Radio value="3"><FormattedMessage id="All parts are no longer available as new, some possible repairs are based on demolition parts."/></Radio>
										<Radio value="4"><FormattedMessage id="There are hardly any new spare parts available."/></Radio>
										</Space>
								</Radio.Group>
							</Form.Item>
						</Row>
						<Divider />
							<div>
								<h2><FormattedMessage id="FUTURE COSTS"/></h2>
								<p><FormattedMessage id="Condition rating"/>:</p>
								{/* <Row>			
									<Col  md={12} lg={12} xl={12}>
										<p>1. <FormattedMessage id="To be repaired immediately"/></p>
									</Col>
									<Col  md={12} lg={12} xl={12}>
										<p>{reportDetails?.total_year_one}</p>
									</Col>
								</Row>
								<Row>			
									<Col  md={12} lg={12} xl={12}>
										<p>2. <FormattedMessage id="To be repaired within 1-2 years"/></p>
									</Col>
									<Col  md={12} lg={12} xl={12}>
										<p>{reportDetails?.total_year_two}</p>
									</Col>
								</Row>
								<Row>
									<Col  md={12} lg={12} xl={12}>
										<p>3. <FormattedMessage id="To be repaired within 2-3 years"/></p>
									</Col>
									<Col  md={12} lg={12} xl={12}>
										<p>{reportDetails?.total_year_three}</p>
									</Col>
								</Row>
								<Row>
									<Col  md={12} lg={12} xl={12}>
										<p>4. <FormattedMessage id="To be repaired within 3-4 years"/></p>
									</Col>
									<Col  md={12} lg={12} xl={12}>
										<p>{reportDetails?.total_year_four}</p>
									</Col>
								</Row>
								<Row>
									<Col  md={12} lg={12} xl={12}>
										<p>5. <FormattedMessage id="To be repaired within 4-5 years"/></p>
									</Col>
									<Col  md={12} lg={12} xl={12}>
										<p>{reportDetails?.total_year_five}</p>
									</Col>
								</Row>
								<Row>
									<Col  md={12} lg={12} xl={12}>
										<p>OK. <FormattedMessage id="All OK, No cost within 5 years"/></p>
									</Col>
									<Col  md={12} lg={12} xl={12}>
										<p>{reportDetails?.total_year_five}</p>
									</Col>
								</Row> */}
								<Row>
									<Form.Item label={`1. ${intl.formatMessage({id:"To be repaired immediately"})}`} name="total_year_one">
										<Input disabled={isReadOnly} />
									</Form.Item>
								</Row>
								<Row>				
									<Form.Item label={`2. ${intl.formatMessage({id:"To be repaired within 1-2 years"})}`} name="total_year_two">
										<Input disabled={isReadOnly} />
									</Form.Item>
								</Row>
								<Row>
									<Form.Item label={`3. ${intl.formatMessage({id:"To be repaired within 2-3 years"})}`} name="total_year_three">
										<Input disabled={isReadOnly} />
									</Form.Item>
								</Row>
								<Row>
									<Form.Item label={`4. ${intl.formatMessage({id:"To be repaired within 3-4 years"})}`} name="total_year_four">
										<Input disabled={isReadOnly} />
										</Form.Item>
								</Row>
								<Row>
									<Form.Item label={`5. ${intl.formatMessage({id:"To be repaired within 4-5 years"})}`} name="total_year_five">
										<Input disabled={isReadOnly} />
										</Form.Item>
								</Row>
								<Row>
									<Form.Item label={`Ok. ${intl.formatMessage({id:"All OK, No cost within 5 years"})}`} name="total_year_six">
										<Input disabled={isReadOnly} />
										</Form.Item>
								</Row>
							</div>
						<Divider />
					</div>
					<div className="page-break">
						<div>
							<h2><FormattedMessage id="summary"/></h2>
							<Row>
								{/* {
									inspectionReportSummary.map((summary,index) => (
										<Col span={24} key={index}>
										<Form.Item
											label={intl.formatMessage({id:summary.question})}
											name={summary.key}
											labelCol={{lg:{span: 9, offset: 0}, md:{span: 24, offset: 0}}}
											wrapperCol={{span: 15, offset: 0}}
										>
											<Select
											placeholder="-- Select --"
											optionLabelProp="label"
											disabled={isReadOnly} 
											>
												{
													summary.answers.map((ans,index) => (
														<Option key={index} value={intl.formatMessage({id:ans})}><FormattedMessage id={ans}/></Option>
													))
												}
											</Select>
										</Form.Item>
										</Col>
									))
								} */}
								<Col span={24}>
									<Form.Item
										label={intl.formatMessage({id:'Compliance with regulations and using the elevator'})}
										name={'summary_answer_one'}
										labelCol={{lg:{span: 9, offset: 0}, md:{span: 24, offset: 0}}}
										wrapperCol={{span: 20, offset: 0}}
									>
										<Select
											showSearch
											mode="tags"
											size="large"
											className="preTender_asset"
											showArrow={false}
											onSearch={(e)=>handleSearch(e,'summary_answer_one')}
											notFoundContent={null}
											optionFilterProp="label"
											options={summaryAnswerOneOptions}
											value={summaryAnswerOne}
											onChange={(value,option) => {
												if (value?.length > 1) {
													// if you want only one element :).
													value.pop();
												}
												else {
													form.setFieldsValue({ 'summary_answer_one': option[0]?.label ? option[0]?.label : value[0] })
													setSummaryAnswerOne(option[0]?.label ? option[0] : value[0])
												}
												
											}}
											placeholder=""
											optionLabelProp="label"
											disabled={isReadOnly} 
										>
										</Select>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										label={intl.formatMessage({id:'Defects and costs according to the maintenance book'})}
										name={'summary_answer_two'}
										labelCol={{lg:{span: 9, offset: 0}, md:{span: 24, offset: 0}}}
										wrapperCol={{span: 15, offset: 0}}
									>
										<Select
											placeholder=""
											optionLabelProp="label"
											disabled={isReadOnly} 
											showSearch
											mode="tags"
											size="large"
											className="preTender_asset"
											showArrow={false}
											onSearch={(e)=>handleSearch(e,'summary_answer_two')}
											notFoundContent={null}
											optionFilterProp="label"
											options={summaryAnswerTwoOptions}
											value={summaryAnswerTwo}
											onChange={(value,option) => {
												if (value?.length > 1) {
												// if you want only one element :).
												value.pop();
												}
												else {
													form.setFieldsValue({ 'summary_answer_two': option[0]?.label ? option[0]?.label : value[0] })
													setSummaryAnswerTwo(option[0]?.label ? option[0] : value[0])
												}
												
											}}
										>
										</Select>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										label={intl.formatMessage({id:'Availability of spare parts'})}
										name={'summary_answer_three'}
										labelCol={{lg:{span: 9, offset: 0}, md:{span: 24, offset: 0}}}
										wrapperCol={{span: 15, offset: 0}}
									>
										<Select
											placeholder=""
											optionLabelProp="label"
											disabled={isReadOnly} 
											showSearch
											mode="tags"
											size="large"
											className="preTender_asset"
											showArrow={false}
											onSearch={(e)=>handleSearch(e,'summary_answer_three')}
											notFoundContent={null}
											optionFilterProp="label"
											options={summaryAnswerThreeOptions}
											value={summaryAnswerThree}
											onChange={(value,option) => {
												if (value?.length > 1) {
												// if you want only one element :).
												value.pop();
												}
												else {
													form.setFieldsValue({ 'summary_answer_three': option[0]?.label ? option[0]?.label : value[0] })
													setSummaryAnswerThree(option[0]?.label ? option[0] : value[0])
												}
												
											}}
										>
										</Select>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										label={intl.formatMessage({id:'Actions to be taken'})}
										name={'summary_answer_four'}
										labelCol={{lg:{span: 9, offset: 0}, md:{span: 24, offset: 0}}}
										wrapperCol={{span: 15, offset: 0}}
									>
										<Select
											placeholder=""
											optionLabelProp="label"
											disabled={isReadOnly} 
											showSearch
											mode="tags"
											size="large"
											className="preTender_asset"
											showArrow={false}
											onSearch={(e)=>handleSearch(e,'summary_answer_four')}
											notFoundContent={null}
											optionFilterProp="label"
											options={summaryAnswerFourOptions}
											value={summaryAnswerFour}
											onChange={(value,option) => {
												if (value?.length > 1) {
												// if you want only one element :).
												value.pop();
												}
												else {
													form.setFieldsValue({ 'summary_answer_four': option[0]?.label ? option[0]?.label : value[0] })
													setSummaryAnswerFour(option[0]?.label ? option[0] : value[0])
												}
												
											}}
										>
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
								{!isReadOnly ?
									<Form.Item
										label="Hissitaito Oy"
										name={'admin_description'}
									>
										<Input.TextArea disabled={isReadOnly} autoSize={{ minRows: 3, maxRows: 8 }} />
									</Form.Item>
									:
									<span>
										{reportDetails?.admin_description}
									</span>
								}	
								</Col>
							</Row>
						</div>
					</div>
					<div className="page-breaks">
						<Divider />
						{
							!isReadOnly ? ( 
							<>
								<FileUpload
									fileList={fileList}
									filePostURL={FILE_POST_URL}
									showTitle={false}
									entityType={"IR"}
									addFileList={addFileList}
									entityID={inspectionReportId}
									onFilesStatusChange={addFileList}
									copiedFiles={copiedFiles}
								/>
								<FileDisplay copiedFiles={copiedFiles} entityType={'IR'} fileList={fileList} entityId={inspectionReportId} hideTitle={false} deleteFiles={true}  isReadOnly={isReadOnly}/>
								<Form.Item {...tailLayout}>
									<Button className="ant-custom-btn" type="primary" htmlType="submit">
										<FormattedMessage id="submit"/>
									</Button>
									<Button className="ant-custom-btn" htmlType="button" onClick={() => history.goBack()}>
										<FormattedMessage id="cancel"/>
									</Button>
								</Form.Item>
							</>) : (
								<>
									<FileDisplay entityType={'IR'} entityId={inspectionReportId} isReadOnly={isReadOnly}/>
									<Row style={{marginTop:'20px'}}>
										<Col span={24}>
											<strong><FormattedMessage id="Respectfully"/></strong><br/>
											<strong><FormattedMessage id="Janne Oja"/></strong><br/>
											<strong><FormattedMessage id="Hissitaito Oy"/></strong><br/>
										</Col>
									</Row>
									{/* <Button className="ant-custom-btn" id="pdf-button" htmlType="button" onClick={Print}>
										<FormattedMessage id="PDF"/>
									</Button> */}
								</>
							)
						}
					</div>
					
				</Form>

			</Card>
			{isReadOnly &&
				<Button className="ant-custom-btn" id="pdf-button" htmlType="button" onClick={Print}>
					<FormattedMessage id="GENERATE PDF"/>
				</Button>
			}
			</Spin>
		</>
	);
};

InspectionReport.propTypes = {
	reportDetails: PropTypes.shape({}),
};

export default React.forwardRef(InspectionReport);