import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { 
  // Form, 
  // Input, 
  // Button, 
  // Checkbox, 
  Spin 
} from 'antd';
import logo from "../../assets/TILogo.png";
import "../../components/Login/Login.css";
// import { Link, useHistory } from "react-router-dom";
// import axios from "axios";
// import { domainUrl } from "../../helper";
import { 
  closingDateReminder, 
  // getUser, 
  // login, 
  // resetPasword 
} from '../../actions/auth';
import { 
  useDispatch, 
  // useSelector 
} from "react-redux";

// import ForgotPassword from './ForgotPassword';
// import { FormattedMessage } from "react-intl";

// const layout = {
//   labelCol: {
//     span: 0,
//   },
//   wrapperCol: {
//     span: 24,
//   },
// };
// const tailLayout = {
//   wrapperCol: {
//     offset: 5,
//     span: 16,
//   },
// };

const Reminder = (props) => {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
 

  useEffect(() => {
    sendReminder();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const sendReminder = async () => {
    setIsUploading(true);
    setMessage("Please Wait...");
    let params = {
        //company_type:1
        is_published:1,
        //tender_id:1,
        //tender_number:T605d0fd3a2sauk,
        //tender_type:r,
        //created_by:2,
        //is_visible_to_all:1,
        //days_reminder:30,
        is_awarded:0,
        //plan_id:25,
        event:'REMINDER_CLOSING_TENDER',
    };
    const response = await dispatch(closingDateReminder(params));
    if(response?.payload?.success){
        setMessage("Reminder Sent...");
    }else{
        setMessage(response?.payload?.message);
    }
    // console.log(response,"=====responseresponse");
    setIsUploading(false);
  };

  return (
    <Spin spinning={isUploading} >
      <div className="login__container">
        <div className="loginOuter">
          <div className="ht-logo2">
            <img src={logo} alt="logo" height="100px"/>
          </div>
            {message && <span className={message === "Please Wait..." ? "messageLogin" : "errorMessageLogin"}>{message}</span>}
        </div>
      </div>
    </Spin>
  );
};

Reminder.propTypes = {};

export default Reminder;
