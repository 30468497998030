/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { message } from "antd";

import "./ChooseHousingCompany.css";
import { useTender } from "../PreTenderSteps";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanies } from "../../../actions/company";
import { fetchOrganizations } from "../../../actions/organization";
import { createCompanyPreTender, updatePreTender, deleteCompanyPreTender, fetchCompanyPreTender } from "../../../actions/pre-tender";
import ToolTip from "../../ToolTip";
import { FormattedMessage, useIntl } from "react-intl";
import { get } from "lodash";

const ChooseHousingCompany = () => {
  const dispatch = useDispatch();
  const { preTenderDetails, onStepChange: move, modifyData } = useTender();
  const intl=useIntl()
  // const companies = useSelector(state => get(state, `entities.companies`));
  // const organizations = useSelector(state => get(state, `entities.organizations`));
  const [companies,setCompanies] = useState([])
  const [organizations,setOrganizations] = useState([])
  const [filteredCompanies, setFilteredCompanies] = useState(Object.values(companies || {}));

  const [allSelected, setAllSelected] = useState(false);

  const companyRef = useRef();
  const addressRef = useRef();
  const bidRef = useRef();
  const orgRef = useRef();

  const [selectedCompanies, setSelectedCompanies] = useState('');
  const [filteredOrganization2, setFilteredOrganization2] = useState('');
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const getInfo = async () => {
      const companyResponse = await dispatch(fetchCompanies({ limit: 1000 }));
      
      const companyData = Object.values(companyResponse.payload?.entities?.companies || []);
      setCompanies(companyData);
      const orgResponse = await dispatch(fetchOrganizations({ limit: 1000 }));
      // console.log(orgResponse.payload?.entities?.organizations,'orgE');
      setOrganizations(Object.values(orgResponse.payload?.entities?.organizations || orgResponse.payload?.result || []));
      let orgIdSelected;
      if(!preTenderDetails?.selectedCompanies || preTenderDetails?.selectedCompanies.length === 0) {
        orgIdSelected = Object.values(orgResponse.payload?.entitie?.organizations || orgResponse.payload?.result || [])[0].organization_id;
      }
      else {
        orgIdSelected = preTenderDetails?.selectedCompanies[0]?.organization_id;
      }

      const filtered = companyData?.filter(
        ({ organization_id }) => {
          const includesOrg = organization_id === orgIdSelected;
          // return true;
          return includesOrg;
        }
      );
      setFilteredCompanies(filtered || companyData);
      //setSelectedCompanies(!Array.isArray(preTenderDetails.selectedCompanies) ? preTenderDetails.selectedCompanies : preTenderDetails?.selectedCompanies[0].company_id || '');
      //console.log(preTenderDetails?.selectedCompanies);
      const companiesPrevSelected = preTenderDetails?.selectedCompanies?.map(comp => {
        return comp.company_id;
      })
      setSelectedCompanies(companiesPrevSelected ?? []);
      setAllSelected(companyData.length === preTenderDetails?.selectedCompanies?.length ? true : false);
    };

    getInfo();
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setFilteredCompanies(Object.values(companies || {}));  
    }, 1500);
  },[companies]);

  const onFilterChange = () => {
    const company = companyRef.current.value?.toLowerCase() ?? "";
    const addr = addressRef.current.value?.toLowerCase() ?? "";
    const bid = bidRef.current.value?.toLowerCase() ?? "";
    const org = orgRef?.current?.value ?? "";
    if(selectedCompanies.length > 0) {
      if(selectedCompanies[0].organization_id !== +org) {
        setSelectedCompanies([]);
      }
    }

    const filtered = companies?.filter(
      ({ company_name, address, business_id, organization_id }) => {
        const includesCompany = company_name.toLowerCase().includes(company);
        const includesAddress = address.toLowerCase().includes(addr);
        const includesBid = business_id.toLowerCase().includes(bid);
        const includesOrg = organization_id === +org;
        // return true;
        return includesCompany && includesAddress && includesBid && includesOrg;
      }
    );

    setFilteredCompanies(filtered);
    setFilteredOrganization2(org);
  };

  const onCompanySelect = ({ target }) => {
    const id = +target.value;
    //setSelectedCompanies(id);
    setSelectedCompanies((c) => {
      // console.log(c);
      const isPresent = c.indexOf(id) > -1;
      if (!isPresent) {
        const newCompanies = selectedCompanies.slice();
        newCompanies.push(id);
        return newCompanies;
      } else if (isPresent) {
        return c.filter((el) => el !== id);
      }
    });
  };

  const onSelectAll = () => {
    const companyIds = !allSelected ? filteredCompanies?.map((el) => +el.company_id) ?? [] : [];
    setSelectedCompanies(companyIds);
    setAllSelected(!allSelected);
  };

  const getNameById = (id) => {
    return companies.find(el => el.company_id === id);
  }

  const onBack = () => move(-1, { selectedCompanies });

  const onNext = async () => {
    const companyNames = [];

     if(!selectedCompanies || selectedCompanies.length === 0) {
      setErrorMessage(
        intl.formatMessage({id: "Please select atleast one company."})
      );
      return;
    }

    const companies_data = selectedCompanies?.map(el => {
      companyNames.push(getNameById(el));
      return { company_id: el }
    })

    //const companies_data = [{ company_id: selectedCompanies }];

    if(preTenderDetails.is_submitted !== 1) {
    if(preTenderDetails?.selectedCompanies) {
      
        if(!Array.isArray(preTenderDetails.selectedCompanies))
            await dispatch(deleteCompanyPreTender(preTenderDetails.pretender_id, preTenderDetails?.selectedCompanies));
        else{
          await Promise.all(
        preTenderDetails?.selectedCompanies?.map(async company => {
          await dispatch(deleteCompanyPreTender(preTenderDetails.pretender_id, company.company_id));
        }));
        }
      
    }
    const companyResp = await dispatch(createCompanyPreTender(companies_data, preTenderDetails.pretender_id));
    await dispatch(updatePreTender({
      organization_id: companies.find(comp => comp.company_id === companies_data[0].company_id).organization_id,
      state: parseInt(preTenderDetails.state) > 2 ? preTenderDetails.state : '2',
    },preTenderDetails.pretender_id));
    if (companyResp?.payload?.entities?.preTenderCompany) {
      move(1, { selectedCompanies, companyNames });
    }
    else {
      message.error("Unable to reach server.");
    }
  }
  else {
    move(1, {
      selectedCompanies,
      companyNames
    },true);
  }
  await dispatch(fetchCompanyPreTender(preTenderDetails.pretender_id));
  };

  const publishData = () => {
    const companyNames = []
    selectedCompanies?.map(el => {
      companyNames.push(getNameById(el));
    })
    move(0, {
      selectedCompanies,
      companyNames
    },true);
    modifyData();
  }
  // console.log(filteredCompanies,"=======filteredCompanies")
  return (
    <>
      <div  className="companyWrapper">
        <div className="hedding">
          <h2><FormattedMessage id="choose_company"/> <ToolTip text_id="tender.choose_company.info" placement="top" /></h2>
        </div>
        <div className="flexContainer">
          <div>
            <div className="formGroup">
              <h3 className="subheading"><FormattedMessage id="filters"/></h3>
            </div>
            <div className="formGroup">
              <label className="leftCol"><FormattedMessage id="company"/></label>
              <div className="selectDate">
                <input
                  ref={companyRef}
                  onChange={onFilterChange}
                  type="text"
                  id="company_name"
                  className="formFiled"
                />
                <div className="searchIcon"></div>
              </div>
            </div>
            <div className="formGroup">
              <label className="leftCol"><FormattedMessage id="address"/></label>
              <div className="selectDate">
                <input
                  ref={addressRef}
                  onChange={onFilterChange}
                  type="text"
                  className="formFiled"
                  id="address"
                />
                <div className="searchIcon"></div>
              </div>
            </div>
            <div className="formGroup">
              <label className="leftCol"><FormattedMessage id="business_id"/></label>
              <div className="selectDate">
                <input
                  ref={bidRef}
                  onChange={onFilterChange}
                  type="text"
                  id="business_id"
                  className="formFiled"
                />
                <div className="searchIcon"></div>
              </div>
            </div>
            <div className="formGroup">
              <label className="leftCol"><FormattedMessage id="choose_org"/></label>
              <div className="selectDate">
                <div className="costumSelect">
                  <select
                    ref={orgRef}
                    name="country"
                    value={filteredOrganization2 || selectedCompanies && companies.find(comp => comp.company_id === +selectedCompanies[0])?.organization_id}
                    onChange={onFilterChange}
                    id="organization_name"
                    className="inputFiled"
                  >
                    {/* <option value="">All</option> */}
                    {organizations?.map((el, index) => (
                      <option key={`${el}${index}`} value={el.organization_id}>
                        {el.organization_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="formGroup">
              <h3 className="subheading"><FormattedMessage id="choose_company"/></h3>
            </div>
            <div className="cardBlock">
              <table className="tableGrid fixed_height" width="100%" border="0">
                <thead>
                  <tr>
                    <th align="left" valign="top">
                    <FormattedMessage id="choose_company"/>
                    </th>
                    <th align="left" valign="top">
                    <FormattedMessage id="select"/>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {filteredCompanies?.map((el, index) => (
                    <tr key={`${el.created_at}${el.business_id}${index}`}>
                      <td align="left" valign="top">
                        {el.company_name}
                      </td>
                      <td align="left" valign="top">
                        <div className="radioBtn">
                          <input
                            type="checkbox"
                            //name="radio-group"
                            id={`${el.business_id}${el.company_name}${index}`}
                            value={el.company_id}
                            //checked={+el.company_id === selectedCompanies}
                            checked={
                              selectedCompanies?.includes(+el.company_id)
                                ? "checked"
                                : null
                            }
                            onChange={onCompanySelect}
                          />
                          <label
                            htmlFor={`${el.business_id}${el.company_name}${index}`}
                          ></label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="btn housingbtn" onClick={onSelectAll}>
                {!allSelected ? <FormattedMessage id="select_all"/> : <FormattedMessage id="unselect_all"/>}
              </div>
            </div>
          </div>
        </div>
        {errorMessage && (
            <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>{errorMessage}</p>
        )}
        <div className="buttonBlock">
          <div onClick={onBack} className="btn cancelBtn">
            <FormattedMessage id="bakc"/>
          </div>
          {
              preTenderDetails?.is_submitted === 1 && 
              <div className="btn" onClick={publishData}>
                 <FormattedMessage id="publish"/>
              </div>
            }
          <div onClick={onNext} className="btn">
          <FormattedMessage id="next"/>
          </div>
        </div>
      </div>
    </>
  );
};

// Tender.propTypes = {};

export default ChooseHousingCompany;
