/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col } from 'antd';

import PropTypes from "prop-types";

import Vault from "./Vault";

const ReportVault = ({reportData, onVaultSelect}) => {

	return (
		<>
            <Row>
                {
                    reportData.map(data => (
                        <Col span={6}>
                            <Vault data={data} onVaultSelect={onVaultSelect}/>
                        </Col>
                    ))
                }
            </Row>
        </>
    )
};

ReportVault.propTypes = {
    reportData: PropTypes.shape([]).isRequired,
    onVaultSelect: PropTypes.func,
};

export default ReportVault;