import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { hasRole } from '../../utils';
import { useSelector } from 'react-redux';

const Protected = ({ roles, fallback, children }) => {
	const user = useSelector(state => get(state, `entities.users`));
	let role_id = user ? user[sessionStorage.getItem('user_id')]?.roles?.role_id : 0;
	// console.log(user, role_id);
	return hasRole(roles, role_id) ? (
		<>{children}</>
	) : (
		<>{typeof fallback === 'function' ? fallback() : fallback}</>
	);
};

Protected.propTypes = {
	roles: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.arrayOf(PropTypes.string.isRequired),
	]),

	fallback: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	children: PropTypes.node.isRequired
};

export default Protected;