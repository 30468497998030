import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { message, Table } from 'antd';

import ConfirmationBox from '../ConfirmationBox';
import AssetDetails from "./AssetDetails";
import AssetCompanySelect from "./AssetCompanySelect";
import { FormattedMessage, useIntl } from "react-intl";
import { compNames, getFilterValues } from '../../utils';
import {
	fetchAssets,
	createAsset,
	updateAsset,
	deleteAsset,
} from "../../actions/assets";
import { fetchCompanies } from "../../actions/company";
// import { SearchOutlined } from "@ant-design/icons";

const AssetList = () => {
	const intl = useIntl();
	const [openAssetFormModel, setOpenAssetFormModel] = useState({
		isOpen: false,
		isReadOnly: false,
	});
	const [openCompanySelectModel, setOpenCompanySelectModel] = useState({
		isOpen: false,
	});
	const [assets, setAssets] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [asset, setAsset] = useState({});
	// const [pagination, setPagination] = useState({ pageSize: 10 });
	const [pagination] = useState({ pageSize: 10 });
	const [showConfirmationBox, setShowConfirmationBox] = useState(false);
	const [delAsset, setDeleteAsset] = useState({});
	const dispatch = useDispatch();

	useEffect(() => {
		async function getAssets() {
			const response = await dispatch(fetchAssets({limit: 1000}));
			const assetData = Object.values(response?.payload?.entities?.assets || {});
			const companyRes = await dispatch(fetchCompanies({limit: 1000}));
			const companyData = Object.values(
				companyRes?.payload?.entities?.companies || {}
			);
			setCompanies(companyData);
			mapCompanyIdNames(assetData, companyData);
		}
		getAssets();
	}, [dispatch]);

	const mapCompanyIdNames = (assetData, companyData) => {
		// eslint-disable-next-line array-callback-return
		assetData?.map((asset) => {
			const assetCompany = companyData.find(
				(company) => company.company_id === asset.company_id
			);
			asset.company_name = assetCompany?.company_name;
			asset.organization_name = assetCompany?.organization_name;
		});
		setAssets(assetData);
	};

	const columns = [
		{
			dataIndex: "asset_name",
			title:<><FormattedMessage id="asset"/> <FormattedMessage id="Name"/></>,
			filters: getFilterValues("asset_name", assets),
			// filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
			onFilter: (value, record) => record?.asset_name?.indexOf(value) === 0,
			sorter: {
				compare: (a, b) => {
				return compNames(a.asset_name, b.asset_name);
				},
			},
		},
		{
			dataIndex: "company_name",
			title:intl.formatMessage({ id:'company'}),
			filters: getFilterValues("company_name", assets),
			// filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
			onFilter: (value, record) => record?.company_name?.indexOf(value) === 0,
			sorter: {
				compare: (a, b) => {
				return compNames(a.company_name, b.company_name);
				},
			},
		},
		{
			dataIndex: "organization_name",
			title:intl.formatMessage({ id:'organisation'}),
			filters: getFilterValues("organization_name", assets),
			// filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
			onFilter: (value, record) => record?.organization_name?.indexOf(value) === 0,
			sorter: {
				compare: (a, b) => {
				return compNames(a.organization_name, b.organization_name);
				},
			},
		},
		{
			dataIndex: "asset_id",
			title:intl.formatMessage({ id:'view'}),
			width:75,
			render: (value, tableMeta, updateValue) => (
				<div
					className="viewIcon"
					onClick={() => openAssetForm(value, { readOnly: true })}
				>
				<FormattedMessage id={'view'} />
				</div>
			),
		},
		{
			dataIndex: "asset_id",
			title:intl.formatMessage({ id:'modify'}),
			width: 115,
			render: (value, tableMeta, updateValue) => (
				<div
					className="btn-primary"
					onClick={() =>
						openAssetForm(value, { readOnly: false })
					}
				>
				<FormattedMessage id={'modify'} />
				</div>
			),
		},
		{
			dataIndex: "asset_id",
			title:intl.formatMessage({ id:'delete'}),
			width:80,
			render: (value, tableMeta, updateValue) => (
				<div
					className="deleteIcon"
					onClick={() => deleteAssetData(value)}
				>
					<FormattedMessage id={'delete'} />
				</div>
			),
		},
	];

	const getSelectedAsset = (asset_id) => {
		return assets.find((ele) => ele.asset_id === asset_id);
	};

	const openAssetForm = (asset_id, opts) => {
		let selected = getSelectedAsset(asset_id);
		setOpenAssetFormModel({ isOpen: true, isReadOnly: opts?.readOnly });
		setAsset(selected);
	};

	const closeAssetForm = () => {
		setOpenAssetFormModel({ isOpen: false });
		setAsset({});
	};

	const closeCompanySelectModel = () => {
		setOpenCompanySelectModel({ isOpen: false });
	};

	const selectCompany = (companyData) => {
		companyData.properties = [];
		closeCompanySelectModel();
		setOpenAssetFormModel({ isOpen: true, isReadOnly: false });
		setAsset({
			...companyData,
		});
	};

	const addAsset = async (assetDataAdd, assetProperties) => {
		let props = [];
		// eslint-disable-next-line array-callback-return
		Object.keys(assetProperties).map((key) => {
			let obj = {};
			obj.property_id = parseInt(key);
			obj.property_value = assetProperties[key];
			if(obj.property_value !== '')
				props.push(obj);
		});
		assetDataAdd.properties = [];
		assetDataAdd.properties = props;
		assetDataAdd.created_by = parseInt(sessionStorage.getItem("user_id"));
		if (assetDataAdd.asset_id)
			await dispatch(updateAsset(assetDataAdd.asset_id, assetDataAdd));
		else await dispatch(createAsset(assetDataAdd));

		const response = await dispatch(fetchAssets({limit: 1000}));
		const assetData = Object.values(response?.payload?.entities?.assets);
		message.success(intl.formatMessage({id:"Asset Added Successfully"}))
		mapCompanyIdNames(assetData, companies);
		closeAssetForm();
	};

	const deleteAssetData = (id) => {
		setDeleteAsset(id);
		setShowConfirmationBox(true);
	};
	
	const declineSubmit = () => {
		setShowConfirmationBox(false);
		setDeleteAsset({});
	}

	const confirmDelete = async () => {
		await dispatch(deleteAsset(delAsset));
		const response = await dispatch(fetchAssets({limit: 1000}));
		const assetData = response?.payload?.entities?.assets ? Object.values(response?.payload?.entities?.assets) : null;
		if(assetData){
			mapCompanyIdNames(assetData, companies);
		}else{
			setAssets([]);
		}
		setShowConfirmationBox(false);
		setDeleteAsset({});
		// location.reload();
		// window.location = '/asset';
	};

	return (
		<>
			<h2 className="title">	<FormattedMessage id={'assets'} /></h2>
			<Table
              className="ant-table-root"
			//   scroll={{ x: 0, y: '40vh' }}
              columns={columns}
              dataSource={assets}
              pagination={pagination}
              bordered
              rowKey='asset_id'
			  locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
            />
			<div className="button1">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="29.701"
					height="29.701"
					viewBox="0 0 29.701 29.701"
				>
					<path
						fill="#fff"
						d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
						transform="translate(-1 -1)"
					/>
				</svg>
				<span
					onClick={() => {
						setOpenCompanySelectModel({ isOpen: true });
					}}
				>
					{" "}
					<FormattedMessage id={'addnew'} />
				</span>
			</div>
			{openCompanySelectModel?.isOpen && (
				<AssetCompanySelect
					isOpen={openCompanySelectModel?.isOpen}
					companyList={companies}
					onClose={closeCompanySelectModel}
					onCompanySelect={selectCompany}
				/>
			)}
			{openAssetFormModel?.isOpen && (
				<AssetDetails
					readOnly={openAssetFormModel?.isReadOnly}
					isOpen={openAssetFormModel?.isOpen}
					data={asset}
					onClose={closeAssetForm}
					onSubmit={addAsset}
				/>
			)}

			<ConfirmationBox showConfirmationBox={showConfirmationBox} message={intl.formatMessage({ id:'delete_asset'}) } confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />
		</>
	);
};

export default AssetList;