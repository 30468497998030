/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// import Moment from 'react-moment';
import { makeStyles } from "@material-ui/core/styles";
import { 
    Table, 
    // Button 
} from 'antd';
// import Paper from '@material-ui/core/Paper';
import axios from "axios";
import AddModal from "./AddModal";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationBox from '../ConfirmationBox';
import { domainUrl }  from "../../helper";
import { compNames, getFilterValues } from '../../utils';
import { changeDateFormat } from '../../utils/common';

import {
    // fetchNewOrganizationRequest,
    organizationRequestDecision,
} from "../../actions/organization";

const useStyles = makeStyles((theme) => ({
    
    favIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    fav: {
        marginRight: 5,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
      height: 45,
    border: 'solid 0.5px #d5d7e3',
    backgroundColor: '#e6e9f4',
    padding: '5px 15px',
    fontFamily: 'Poppins',
    fontSize: 16,
    color: '#3f3f41',
    },
    button: {
      margin: theme.spacing(1),
      borderRadius: 8,
      backgroundColor: '#2B7776',
      color: '#fff',
      fontFamily: 'Poppins',
      fontSize: 16,
    fontWeight: 500,
    },
    inviteWrapper: {
        padding: 20,
        marginTop: 30,

    },
    form: {
        margin: '0 auto',
        width: '95%',
    }
}));

const OrganizationNewRequestList = ({ value }) => {
	const intl = useIntl();
    // const classes = useStyles();
    
    const [organizationsRequest, setOrganizationsRequest] = useState([]);
    const [organization, setOrganization] = useState();
    const [openOrganizationRequestFormModel, setOpenOrganizationRequestFormModel] = useState({
        isOpen: false,
        isReadOnly: false,
      });

    // const [formEmailInput, setFormEmailInput] = useState(
    //     {
    //       organization_email: ""
    //     }
    // );
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(1);
    const [message, setMessage] = useState('');
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [decision, setDecision] = useState('');
    const [organizationId, setOrganizationId] = useState();

    // console.log(message);
    const genHeaders = () => ({
        headers: {
          client_code: "ht_front",
          token: sessionStorage.getItem("token"),
        },
      });
    const dispatch = useDispatch();

    async function getOrganizations(page, pageSize) {
        // const response = await dispatch(fetchNewOrganizationRequest({limit: 1000, status: 'pending',order_by: 'organization_request_id', sort_by: 'DESC'}));
        const res = await axios.get(
            domainUrl + `/masterservicenew/master/organization-request?status=pending&limit=${pageSize}&offset=${pageSize*(page-1)}&order_by=organization_request_id&sort_by=DESC`,
            genHeaders()
          );
        // const organizationRequestData = Object.values(response?.payload?.entities?.organizationsRequest || []);
        setOrganizationsRequest(res?.data?.data?.result);
    }

    useEffect(() => {
        if(value === 0)
            getOrganizations(1, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    // const mapCompanyIdNames = (assetData, companyData) => {
    // 	assetData.map((asset) => {
    // 		const assetCompany = companyData.find(
    // 			(company) => company.company_id === asset.company_id
    // 		);
    // 		asset.company_name = assetCompany.company_name;
    // 		asset.organization_name = assetCompany.organization_name;
    // 	});
    // 	setAssets(assetData);
    // };

    const columns = [{
            dataIndex: "organization_name",
            title:intl.formatMessage({ id:'Organisation_Name'}),
            filters: getFilterValues("organization_name", organizationsRequest),
			onFilter: (value, record) => record?.organization_name?.indexOf(value) === 0,
			sorter: {
				compare: (a, b) => {
				    return compNames(a.organization_name, b.organization_name);
				},
			},
        },
        {
            dataIndex: "created_at",
            title:intl.formatMessage({ id:'Date_recieved'}),
            render: (value, tableMeta, updateValue) => (changeDateFormat(value,intl?.locale)),
        },
        {
            dataIndex: "organization_request_id",
            title:intl.formatMessage({ id:'view'}),
            width:75,
            render: (value, tableMeta, updateValue) => (
                <div
                    className="viewIcon"
                    onClick={() => openOrganizationForm(value, { readOnly: true })}
                >
                  <FormattedMessage id={'view'} />
                </div>
            ),
        },
        {
            dataIndex: "organization_request_id",
            title:intl.formatMessage({ id:'accept'}),
            width:150,
            render: (value, tableMeta, updateValue) => (
                <div
                    className="btn-primary"
                    onClick={() =>{
                        makeDecision(value, 'approved')
                      }
                    }
                >
                     <FormattedMessage id={'accept'} />
                </div>
            ),
        },
        {
            dataIndex: "organization_request_id",
            title:intl.formatMessage({ id:'reject'}),
            width: 150,
            render: (value, tableMeta, updateValue) => (
                <div
                    className="btn-secondary"
                    onClick={() =>
                        makeDecision(value, 'rejected')
                    }
                >
                 <FormattedMessage id={'reject'} />
                </div>
            ),
        },
    ];

    const declineSubmit = () => {
		setShowConfirmationBox(false);
		setMessage('' );
        setOrganizationId('');
	}

    const makeDecision = (organizationId, decision) => {
        setMessage('Are you sure you want to '+ decision + ' the organization?' );
        setOrganizationId(organizationId);
        setDecision(decision);
        setShowConfirmationBox(true);
    }

    const acceptRejectRequest = async () => {
        setMessage('' );
        setShowConfirmationBox(false);
        const response = await dispatch(organizationRequestDecision(organizationId, { status: decision }));
        setOrganizationId('');
        const responseStatus = response.payload.body;
        if (responseStatus && !responseStatus?.success) {

        } else {
            getOrganizations(1, pageSize);
            
        }
        
    }

    const getSelectedOrganization = (organization_request_id) => {
    	return organizationsRequest.find(organization => organization.organization_request_id === organization_request_id);
    };

    const closeOrganizationForm = () => {
        setOpenOrganizationRequestFormModel({ isOpen: false });
        setOrganization();
    };

    const openOrganizationForm = (organization_request_id, opts) => {
        let selected = getSelectedOrganization(organization_request_id);
        setOrganization(selected);
        setOpenOrganizationRequestFormModel({ isOpen: true, isReadOnly: opts?.readOnly });
    };

    // const handleInput = evt => {
    //     const name = evt.target.name;
    //     const newValue = evt.target.value;
    //     setFormEmailInput({ [name]: newValue });
    // };

    // const handleSubmit = evt => {
    //     evt.preventDefault();

    //     axios
    //     .post(
    //       `${domainUrl}/masterservicenew/master/organization_request/invite`,
    //       formEmailInput,
    //       {
    //         headers: {
    //             token: sessionStorage.getItem("token"),
    //             client_code: "ht_front",
    //         },
    //       }
    //     )
    //     .then(() => {
            
    //     })
    //     .catch((e) => {
    //         console.log(e.error)
          
    //     })
    //     .finally(() => {
    //       setFormEmailInput({
    //           organization_email: ""
    //       })
    //     });
    // };

    const onPaginationChange = (pageDetails) => {
        setPageSize(pageDetails.pageSize);
        getOrganizations(pageDetails.current, pageDetails.pageSize);
    }

   return (
		<>
            <Table
                className="ant-table-root"
                // scroll={{ x: 0, y: '40vh' }}
                style={{height: '80vh'}}
                columns={columns}
                dataSource={organizationsRequest}
                pagination={{ defaultPageSize: pageSize, showSizeChanger: false, total: totalCount }}
                onChange={onPaginationChange}
                locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
                bordered
                rowKey='organization_request_id'
            />
			{/* <div className="invite">
				<Paper elevation={3} className={classes.inviteWrapper}>
                    <form onSubmit={handleSubmit} className={classes.form}>
                    <label> <FormattedMessage id={'invite_organizations'} /></label>
                    <input
                      className={classes.textField}
                            placeholder={intl.formatMessage({ id:'Enter_emailid'})}
                            onChange={handleInput}
                      type="text"
                      name="organization_email"
                      value={formEmailInput.organization_email}
                    />
                       
                        <Button
                            htmlType="submit"
                            type="primary"
                            className='btn'
                          >
                            <FormattedMessage id={'sendinvitation'} />
                          </Button>
                    </form>
                </Paper>
			</div> */}
            <ConfirmationBox showConfirmationBox={showConfirmationBox} message={<FormattedMessage id={message} />} confirmSubmit={acceptRejectRequest} declineSubmit={declineSubmit} />
            {openOrganizationRequestFormModel?.isOpen && (
                <AddModal
                readOnly={openOrganizationRequestFormModel?.isReadOnly}
                data={organization}
                onClose={closeOrganizationForm}
                />
            )}
		</>
	);
};

export default OrganizationNewRequestList;