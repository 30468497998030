// import React from 'react';
import { FormattedMessage } from "react-intl";

export const inspectionReportSummary = [{
    question: "Compliance with regulations and using the elevator",
    answers: ["The elevator complies with regulations and is safe to use."],
    key: "summary_answer_one",
},{
    question: "Defects and costs according to the maintenance book",
    answers: ["The elevator has had quite a few defects and repairs throughout its life cycle. The automatic doors of the smart lift require a lot of maintenance and replacement of parts in order to work. Door straps and mechanisms usually need to be replaced annually to keep the doors in working order."],
    key: "summary_answer_two",
},{
    question: "Availability of spare parts",
    answers: ["The availability of spare parts is moderate with this elevator model. The smart elevator has not been manufactured since 2006, so the prices of spare parts increase considerably every year."],
    key: "summary_answer_three",
},{
    question: "Actions to be taken",
    answers: ["The elevator has some repair debt and, for example, the technical service life of the frequency converter has been considerably exceeded. Replacing the drive costs approx. € 4,500 - € 11,000 VAT 24% depending on the person making the correction. The door area should be renovated in order to increase operational reliability and reduce unnecessary fault costs. Renovation of an automatic door costs approx. € 6,000 VAT 24%. Repairs should be done as planned, in larger units and on a competitive basis. Another option would be to overhaul the elevator more extensively, i.e., new full controls, pushbuttons, door operators and worn out parts. The price estimate for a larger renovation is approx. € 30,000 VAT 24%."],
    key: "summary_answer_four",
}];

export const AdminMenu = [
    {label: <FormattedMessage id="dashboard"/>, link: '/dashboard'},
    {label: <FormattedMessage id="home"/> , link: '/'},
    {label: <FormattedMessage id="profile"/> , link: '/profile'},
    {label:<FormattedMessage id="organisation"/>, link: '/organization' },
    {label: <FormattedMessage id="company"/>, link: '/company'},
    {label: <FormattedMessage id="asset"/>, link: '/asset'},
    {label: <FormattedMessage id="supplier"/>, link: '/supplier'},
    {label: <FormattedMessage id="settings"/>, link: '/service-type'},
    {label: <FormattedMessage id="inspection_report"/>, link: '/inspection-report'},
    {label: <FormattedMessage id="users"/> , link: '/userdetails'},
];
//PropertyManagerAdmin

export const PropertyManagerMenu = [
    {label: <FormattedMessage id="dashboard"/>, link: '/dashboard'},
    {label: <FormattedMessage id="home"/> , link: '/'},
    {label: <FormattedMessage id="profile"/> , link: '/profile'},
    {label: <FormattedMessage id="company"/>, link: '/company'},
    {label: <FormattedMessage id="asset"/>, link: '/asset'},
    {label: <FormattedMessage id="supplier_rating"/> , link: '/ratings'},
    {label: <FormattedMessage id="property_manager"/> , link: '/user-details'},
];


export const SupplierMenu = [
    {label: <FormattedMessage id="dashboard"/>, link: '/dashboard'},
    {label: <FormattedMessage id="home"/> , link: '/'},
    {label: <FormattedMessage id="profile"/> , link: '/profile'},
    {label: <FormattedMessage id="rating"/> , link: '/ratings'},
    {label: <FormattedMessage id="inspection_report"/>, link: '/inspection-report'},
];

export const Languages = [
    {key: 'en', label: 'ENG'},
    {key: 'fi', label: 'FIN'}
]