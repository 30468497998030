import "./FileUpload.less";
import { DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { message, Form, Input, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { genHeaders } from "../Tender/Tender";
import { awsDocumentUrl } from "../../helper";
import ToolTip from '../ToolTip';
import { FormattedMessage, useIntl } from "react-intl";

const DEFAULT_DOCUMENTID = null;

const FileUpload = (
  {
    filePostURL,
    entityID,
    entityType,
    fileList,
    showTitle = true,
    showUploadButton = true,
    onUploadStatusChange,
    onFilesStatusChange,
    onFilesUploadStatusChange,
    addFileList,
    emptyFilelist,
    copiedFiles,
  },
  ref
) => {
  const [description, setDescription] = useState("");
  // const [files, setFiles] = useState(fileList ?? []);
  const [filesToUpload, setFilesToUpload] = useState(fileList ?? []);
  const [isUploading, setIsUploading] = useState(false);
  const intl = useIntl();
  useEffect(()=>{
    if(copiedFiles){
      setFilesToUpload(copiedFiles);
    }
  },[copiedFiles])
  const onFile = (e) => {

    if (e.target.files && e.target.files.length) {
      setFilesToUpload((c) => {
        const newFiles = [
          ...c,
          ...[...e.target.files].map((file) => ({
            documentId: DEFAULT_DOCUMENTID,
            desc: description,
            contentType: file.type,
            file,
          })),
        ];

        if (onFilesStatusChange) onFilesStatusChange(newFiles);
        return newFiles;
      });
      setDescription('');
    }
  };

  const updateDesc = (e, index) => {
    setFilesToUpload((c) => {
      const newList = [...c];
      newList[index].desc = e.target.value;
      return newList;
    });
  }

  const onFileDeleteBefore = async (index) => {
    try {
      if (filesToUpload[index] && filesToUpload[index].documentId) {
        setIsUploading(true);
        if (onUploadStatusChange) onUploadStatusChange(true);
        await axios.delete(
          `${awsDocumentUrl}/Prod/v1/document/${filesToUpload[index].documentId}`,
          { token: sessionStorage.getItem("token") }
        );
      }
      setFilesToUpload((c) => {
        const newList = [...c];
        newList.splice(index, 1);
        if (onFilesStatusChange) onFilesStatusChange(newList);
        return newList;
      });
    } catch (e) {
      message.error("Unable to delete file");
    } finally {
      setIsUploading(false);
      if (onUploadStatusChange) onUploadStatusChange(false);
    }
  };

  const onFileUploadClick = async () => {
    if (filesToUpload && filesToUpload.length) {
      setIsUploading(true);
      if (onUploadStatusChange) onUploadStatusChange(true);

      for (let index in filesToUpload) {
        if (filesToUpload[index].documentId === DEFAULT_DOCUMENTID) {
          const fd = new FormData();
          const file = filesToUpload[index].file;
          const contentType = filesToUpload[index].contentType;
          fd.append("entity_type", entityType);
          fd.append("entity_id", entityID);
          fd.append("file_description",  filesToUpload[index].desc || file.name);
          fd.append("file", file);
          fd.append("contentType", contentType);
          try {
            const res = await axios.post(filePostURL, fd, genHeaders());
            filesToUpload[index].documentId = res?.data?.data?.document_id;
            // setFiles(filesToUpload);
            addFileList(filesToUpload);
            if(emptyFilelist){
              setFilesToUpload([]);
            };
            message.success(`${file.name} ${intl.formatMessage({ id:'files_uploaded_success'})}`);
          } catch (e) {
            console.error(e);
            message.error(`Unable to upload ${file.name}`);
          }
        }
      }
      if (onFilesStatusChange) {
        setFilesToUpload([]);
      }
      setIsUploading(false);
      if (onUploadStatusChange) onUploadStatusChange(false);
    } else {
      message.error(`${intl.formatMessage({ id:'no_files_attached_either_add_a_file'})}`);
    }
  };

  return (
    <Spin spinning={isUploading} >
      <div className="__FileUpload__">
        {showTitle && (
          <div className="hedding">
            <h2><FormattedMessage id="input_file" /><ToolTip text_id="tender.file.info" placement="top" /></h2>
          </div>
        )}
        {/* <div className="formGroup">
          <label className="leftCol" style={{width:'20%'}}><FormattedMessage id="file_desc" /></label>
          <input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            className="formFiled"
            style={{minWidth:'80%'}}
          />
        </div> */}
        <div className="formGroup" style={{ flexDirection: "column" }}>
          <div className="file-drop-area">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="68.289"
              height="47.803"
              viewBox="0 0 68.289 47.803"
            >
              <path
                id="Icon_awesome-cloud-upload-alt"
                data-name="Icon awesome-cloud-upload-alt"
                d="M57.363,23.014A10.256,10.256,0,0,0,47.8,9.079a10.191,10.191,0,0,0-5.687,1.729,17.074,17.074,0,0,0-31.872,8.515c0,.288.011.576.021.864a15.369,15.369,0,0,0,5.1,29.866H54.632a13.657,13.657,0,0,0,2.732-27.038ZM41.977,29.566H35V41.516a1.712,1.712,0,0,1-1.707,1.707H28.169a1.712,1.712,0,0,1-1.707-1.707V29.566H19.484a1.7,1.7,0,0,1-1.206-2.913L29.525,15.406a1.713,1.713,0,0,1,2.411,0L43.182,26.653a1.706,1.706,0,0,1-1.206,2.913Z"
                transform="translate(0 -2.25)"
                fill="#2B7776"
              />
            </svg>

            <span className="file-msg"><FormattedMessage id="drop_files" /></span>
            <input
              onChange={onFile}
              className="file-input"
              type="file"
              multiple
            />
          </div>
        </div>

        {filesToUpload?.map((el, index) => (
          <div key={index} style={{ position: "relative" }}>
            <div className="filecard">
              <Form.Item label={<FormattedMessage id={'desc'}/>}>
                <Input value={el.desc} onChange={(e) => updateDesc(e, index)}/>
              </Form.Item>
              
              <h3><FormattedMessage id="name" /> : {el.file.name}</h3>
              <h3><FormattedMessage id="size" /> : {Math.round(el.file.size / 1024)} KB</h3>
            </div>
            <DeleteOutlined
              onClick={() => (isUploading ? null : onFileDeleteBefore(index))}
              style={{
                position: "absolute",
                right: "10px",
                top: "30px",
                color: "red",
              }}
            />
            {el?.documentId && (
              <CheckCircleOutlined
                style={{
                  position: "absolute",
                  right: "40px",
                  top: "30px",
                  color: "green",
                }}
              />
            )}
          </div>
        ))}
        {showUploadButton && (
          <>
          <p className="infoText"><FormattedMessage id="upload_msg" /></p>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              ref={ref}
              onClick={isUploading ? null : onFileUploadClick}
              className="btn"
            >
              <FormattedMessage id="upload_files" />
            </div>
          </div>
          </>
        )}
      </div>
    </Spin>
  );
};

export default React.forwardRef(FileUpload);
