import React from 'react';
// import get from 'lodash/get';
// import { useSelector } from 'react-redux';

import ControlPanelDetails from "../../components/ControlPanel/ControlPanelDetails";

const DashboardDetail = (props) => {
	// let role_id = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.roles.role_id`));
	return (
		<>
			<ControlPanelDetails props={props} />
		</>
	)
};


export default DashboardDetail;