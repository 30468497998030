import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import ControlPanel from "../../components/ControlPanel/ControlPanel";

const Dashboard = (props) => {
	// let role_id = 
	useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.roles.role_id`));

	return (
		<>
			<ControlPanel props={props} />
		</>
	)
};


export default Dashboard;