/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { FormattedMessage, useIntl } from "react-intl";
import Header from "../../components/Header";
import { fetchTenderByParams } from '../../actions/tender';
import { getUser } from '../../actions/auth';
import { useHistory } from 'react-router';
import { getFilterValues } from '../../utils';
import { domainUrl } from "../../helper";
import axios from "axios";
// import MessageReplyCard from '../../components/MessagesCard/MessageReplyCard';
// import { updateMessageCommunication } from '../../actions/messages';
import { changeDateFormat } from '../../utils/common';

export const genHeaders = () => ({
  headers: {
    client_code: "ht_front",
    token: sessionStorage.getItem("token"),
  },
});

const AllTenders = (props) => {
    const companyId = props?.location?.state;
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    // let role_id = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.roles.role_id`));
    // const tenders = useSelector(state => get(state, `entities.tenders`));
    const stateUser = useSelector(state => get(state, `entities.users`));
    const [tendersFetched, setTendersFetched] = useState(false);
    const [tenders, setTenders] = useState([]);
    const [tenderInfo, setTenderInfo] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [title, setTitle] = useState('');
    // const [visible, setVisible] = useState(false);
    // const [currentTenderId, setCurrentTenderId] = useState();
    // const [currentTenderTitle, setCurrentTenderTitle] = useState();
    // const [currentSenderId, setCurrentSenderId] = useState();
    // const [currentMessageId, setCurrentMessageId] = useState();
    // const [suppliers, setSuppliers] = useState([]);
    const role = sessionStorage.getItem('role_id')
    const [pagination, setPagination] = useState({
		    pageSize: 10,
        total:0,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({ id:'of'})} ${total} ${intl.formatMessage({ id:'items'})}`,
        locale:{ items_per_page: intl.formatMessage({ id: 'page' })},
        onShowSizeChange: (page, pageSize) => {
            setPagination({
                ...pagination,
                pageSize: pageSize
            })
        },
	  });

    useEffect(() => {
      setPagination({
        ...pagination,
        showTotal: (total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({ id:'of'})} ${total} ${intl.formatMessage({ id:'items'})}`,
        locale:{ items_per_page: intl.formatMessage({ id: 'page' })},
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl?.locale])

    const searchInput = useRef();

    const getAllTenders = async() => {
      setTendersFetched(false);
      let param1 = {limit:1000, is_published: 1, is_awarded:0, order_by: 'updated_at', sort_by: 'DESC'};
      let param2 = {limit:1000, is_published: 0, order_by: 'updated_at', sort_by: 'DESC'};
      let param3 = {limit:1000, is_completed: 1,is_awarded:1, order_by: 'completion_date', sort_by: 'DESC'};
      let param4 = {limit:1000, is_published:1, is_awarded:1, order_by: 'award_datetime', sort_by: 'DESC'};
      if(role === '3'){
        param1.created_by = sessionStorage.getItem('user_id'); 
        param2.created_by = sessionStorage.getItem('user_id'); 
        param3.created_by = sessionStorage.getItem('user_id'); 
        param4.created_by = sessionStorage.getItem('user_id'); 
      }else if(role === '2'){
        param3.created_by = sessionStorage.getItem('user_id'); 
        param2.created_by = sessionStorage.getItem('user_id'); 
      }

      if(title){
        param1.title = title;
        param2.title = title;
      }
      // let publishedTenders = await dispatch(fetchTenderByParams(param1)).then((res)=>setTenders(res?.payload?.entities?.tenders));
      // let draftTenders =  await dispatch(fetchTenderByParams(param2)).then((res)=>setTenders(publishedTenders?.length ? [...publishedTenders,...res?.payload?.entities?.tenders]: res?.payload?.entities?.tenders));
      let publishedTenders = await dispatch(fetchTenderByParams(param1));
      let draftTenders =  await dispatch(fetchTenderByParams(param2));
      let completedTenders =  await dispatch(fetchTenderByParams(param3));
      let awardedTenders =  await dispatch(fetchTenderByParams(param4));
      let published = publishedTenders?.payload?.entities?.tenders;
      let draft = draftTenders?.payload?.entities?.tenders;
      let completed = completedTenders?.payload?.entities?.tenders;
      let awarded = awardedTenders?.payload?.entities?.tenders;
      // console.log(published,"=====publishedTenderspublishedTenders")
      // console.log(draft,"=====draftTendersdraftTenders")
      // console.log(completed,"=====completedTenderscompletedTenders")
      // console.log(awarded,"=====awardedTendersawardedTenders")
      // console.log({...published,...draft,...completed,...awarded},"=====AllTenders")
      setTenders({...published,...draft,...completed,...awarded})
      setTendersFetched(true);
    }

    const getCompanyTenders = async() => {
      let tenders = await axios.get(
        domainUrl + `/tender/v1/tender?limit=20&sort_by=DESC&company_id=${companyId}`,
        genHeaders()
      );
      tenders = tenders.data?.data?.result
      setTenderInfo(tenders)
    }

    useEffect(() => {
      if(companyId){
        setTendersFetched(true);
        getCompanyTenders();
      }else{
        getAllTenders();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    // console.log(tenders,"======tenders sorted")
    
    const fetchUsers = async() => {
        const creators = getFilterValues('created_by', Object.values(tenders || {}));
            await Promise.all(creators?.map(async user => {
                if(!stateUser[user.value]) {
                    await dispatch(getUser(user.value));
                }
            }));
            const sortedTenders = Object.values(tenders || {}).sort(function(a,b){
              return new Date(b.updated_at) - new Date(a.updated_at);
            });
            const tenderInfos = sortedTenders?.map((tender, index) => {
                return {
                    ...tender,
                  created_by: stateUser[tender?.created_by]?.name,
                 
                  status: (tender?.is_awarded === 1 && tender?.is_published === 1 && tender?.is_completed != 1) ? 'Awarded' : ((tender?.is_published === 1 && tender?.is_awarded === 0) ? 'Published' : (tender?.is_published === 0 ? 'Draft' :(tender?.is_completed === 1 && 'Completed')))
                 // winner: suppliers[index]?.supplier_name,
                };
              });
            
              setTenderInfo(tenderInfos)
    }

    useEffect(() => {
        if(tendersFetched) {
            fetchUsers();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tendersFetched]);

    const navigateToTender = async(id, tableMeta) => {
        // if(tableMeta.status == 'Draft') {
        //     history.push(`/tender/${id}`);
        // }
        // else {
        //     history.push(`/tender/detail/${id}`);
        // }
        if (tableMeta.status === "Completed") {
          history.push(`/tender/awarded/${id}`);
        }
        if (tableMeta.status === "Published") {
          history.push(`/tender/detail/${id}`);
        }
        if (tableMeta.status === "Draft") {
          history.push(`/tender/${id}`);
        }
        if (tableMeta.status === "Awarded") {
          history.push(`/tender/awarded/${id}`);
        }
    }

    const handleSearch = () => {
      // confirm();
      // setSearchText(selectedKeys[0]);
      // setSearchedColumn(dataIndex)
      getAllTenders();
    };

    // const handleReset = clearFilters => {
    //   clearFilters();
    //   setSearchText('');
    // };

    
    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={intl.formatMessage({ id:"search"})+` ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setTitle(e.target.value ? e.target.value : '')}
            onPressEnter={() => handleSearch()}
            // onChange={e => setSelectedKeys(e.target.value ? e.target.value : [])}
            // onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 3, display: 'block' }}
          />
          {/* <Space> */}
            <Button
              type="primary"
              // onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              onClick={() => handleSearch()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginTop: 0, }}
            >
              <FormattedMessage id={'search'} />
            </Button>
            {/* <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            <FormattedMessage id={'reset'} />
            </Button>
          </Space> */}
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',

      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });

  // const replyToMsg = async(tender, index) => {
  //   const suppliersCall = await axios
  //   .get(`${domainUrl}/tender/v2/tender/${tender?.tender_id}/supplier`, genHeaders())
  //   .then(res => setSuppliers(res?.data?.data?.suppliers))
  //   .catch((e) => {
  //     console.error(e);
  //     return null;
  //   });
  //   setVisible(false);
  //   setCurrentTenderId(tender?.tender_id);
  //   setCurrentTenderTitle(tender?.title);
  //   setCurrentSenderId(tender?.sender_id);
  //   // setCurrentMessageId(msg?.message_id)
  //   setVisible(true);
  //   // if(!msg.is_read)
  //   //     markAsRead(msg.message_id, index);
  // }
  const onTenderClick = (tender) => {
      history.push(`/tender/${tender.tender_id}/messages`)
  };

  const columns = [
    {
      dataIndex: "title",
      align: 'center',
      title: intl.formatMessage({ id:'tender_title'}),
      ...getColumnSearchProps('title'),
      render: (value,row,index) => (
        <div style={{cursor:"pointer"}} onClick={(e) => onTenderClick(row)}>{value}</div>
      ),
    },
    {
      dataIndex: "tender_id",
      align: 'center',
      title: intl.formatMessage({ id:'message'}),
      render: (value, tableMeta, updateValue) => (
        <div className="chatIcon" style={{marginLeft:'10px',marginRight:'10px'}} onClick={() =>  onTenderClick(tableMeta)}>View</div>
      ),
    },
    {
      dataIndex: "organization_name",
      title: intl.formatMessage({ id:'organisation'}),
      filters: getFilterValues("organization_name", tenderInfo),
      onFilter: (value, record) => record?.organization_name && record?.organization_name?.indexOf(value) === 0,
    },
    {
      dataIndex: "company_name",
      title: intl.formatMessage({ id:'company'}),
      filters: getFilterValues("company_name", tenderInfo),
      onFilter: (value, record) => record?.company_name && record?.company_name?.indexOf(value) === 0,
      render: (value, tableMeta, updateValue) => (  
        <div style={{wordBreak:'break-all'}}>{value?.replace(",", ", ")}</div>
      ),
    },
    {
      dataIndex: "created_by",
      title:intl.formatMessage({ id:'created_by'}),
      filters: getFilterValues("created_by", tenderInfo),
      onFilter: (value, record) => record?.created_by && record?.created_by?.indexOf(value) === 0,
    },
    {
      dataIndex: "bid_count",
      align: 'center',
      title: intl.formatMessage({ id:'bids_submitted'}),
      sorter: (a, b) => a.bid_count - b.bid_count,
      render: (value, tableMeta, updateValue) => (  
        <div>{value}{tableMeta.no_of_suppliers ? `/${tableMeta.no_of_suppliers}` : ``}</div>
      ),
    },
    {
      dataIndex:"status",
      title: intl.formatMessage({ id:'status'}),
      filters: getFilterValues("status", tenderInfo).map(el=>{  
        return {...el,
          text : intl.formatMessage({ id:el.text?.toLowerCase()})
        }
      }),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (value) => intl.formatMessage({ id:value?.toLowerCase()}),
    },
    {
      dataIndex: "created_at",
      title: intl.formatMessage({ id:'created_at'}),
      className:"text-nowrap",
      sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
      render: (value, tableMeta, updateValue) => (
        <div className="text-nowrap">{changeDateFormat(value,intl?.locale)}</div>
      ),
    },
    {
      dataIndex: "updated_at",
      align: 'center',
      title:  intl.formatMessage({ id:'Updated_date'}),
      sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
      render: (value, tableMeta, updateValue) => (
        <div>{changeDateFormat(value,intl?.locale)}</div>
      ),
    },
    {
      dataIndex: "tender_id",
      align: 'center',
      title: intl.formatMessage({ id:'Action'}),
      render: (value, tableMeta, updateValue) => (
        <div className="viewIcon" style={{marginLeft:'10px',marginRight:'10px'}} onClick={() => navigateToTender(value, tableMeta)}>View</div>
      ),
    },
  ];

  // const markAsReplied = async() => {
  //   // await dispatch(updateMessageCommunication(msgId, {is_replied: true}));
  // }  

	return (
    <>
    <div className="__Tender__">

      <Header />
      <div className="mainContainer" style={{padding: 30}}>
        <h2 className="title"> <FormattedMessage id={'tenders'} /></h2>
        <Table
          className="ant-table-root"
          // scroll={{ x: 0, y: '40vh' }}
          loading={!tendersFetched}
          columns={columns}
          dataSource={tenderInfo}
          pagination={pagination}
          bordered
          rowKey='tender_id'
          locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
          scroll={{
            x: "fit-content",
          }}
          size="small"
        />
      </div>
    </div>
    {
      // visi`ble && 
      //   <Me`ssageReplyCard hideModal={() => setVisible(false)} modalOpen={visible} markAsReplied={markAsReplied} tenderId={currentTenderId} tenderTitle={currentTenderTitle} receiverId={currentSenderId} /*messageId={currentMessageId}*/ suppliers={suppliers}/>
    }
    </>
  );
};
 export default AllTenders;
