import React from 'react';
// import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const MessageToast = ({ success, error, info, message, onClose, open }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = React.useState(open);

    const handleClose = (event, reason) => {
        setIsOpen(false);
        onClose();
    };

    return (
        <div className={classes.root}>
      <Snackbar open={isOpen} anchorOrigin={{vertical:'top', horizontal:'center' }} autoHideDuration={6000} onClose={handleClose}>
       
            <Alert onClose={handleClose} severity={ success ? "success" : (error ? "error" : "info") }>
              {message}
            </Alert>
        
      </Snackbar>

    </div>
    );
}

MessageToast.propTypes = {
    success: PropTypes.bool,
    error: PropTypes.bool,
    info: PropTypes.bool,
    message: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};

MessageToast.defaultProps = {
    success: false,
    error: false,
    info: false,
    message: ''
}

export default MessageToast;