/* eslint-disable react-hooks/exhaustive-deps */
import "./Tender.less";

import React, { useCallback, useContext, useState, useEffect } from "react";
import axios from "axios";
import { Spin, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
// import Header from "../Header";
import AdditionalInfo from "./AdditionalInfo/AdditionalInfo";
import EnterTenderDetails from "./EnterTenderDetails/EnterTenderDetails";
import EnterAssetDetails from "./EnterAssetDetails/EnterAssetDetails";
import AttachFile from "./AttachFile/AttachFile";
import ChooseClosingDate from "./ChooseClosingDate/ChooseClosingDate";
import ChooseTenderType from "./ChooseTenderType/ChooseTenderType";
import ChooseHousingCompany from "./ChooseHousingCompany/ChooseHousingCompany";
import Summary from "./Summary/Summary";
import ChooseSupplier from "./ChooseSupplier_NEW/ChooseSupplier";
import ConfirmTender from "./ConfirmTender";
import { domainUrl, awsDocumentUrl } from "../../helper";

export const genHeaders = () => ({
  headers: {
    client_code: "ht_front",
    token: sessionStorage.getItem("token"),
  },
});

const TenderContext = React.createContext({
  onStepChange: null,
  tenderDetails: null,
});

export const useTender = () => {
  const ctx = useContext(TenderContext);
  return ctx;
};

const steps = [
  "Choose Tender Type",
  "Choose Company",
  "Tender Details",
  "Choose Suppliers",
  "Asset Details",
  "Attach File",
  "Closing Date",
  "Additional Info",
  "Summary",
];

const Tender = () => {
  const [step, setStep] = useState(0);
  const [tenderDetails, setTenderDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { tenderId } = useParams();

  const onStepChange = useCallback((moveBy, tenderDetails) => {
    if (tenderDetails) {
      setTenderDetails((current) => {
        return { ...current, ...tenderDetails };
      });
    }
    setStep((currStep) => {
      const finalStep = currStep + moveBy;
      if (finalStep < 1 || finalStep > 10) {
        history.goBack();
        return;
      }
      return finalStep;
    });
  }, []);

  const onNavClick = (finalStep) => {
    if (finalStep >= step) return;
    const diff = finalStep - step;
    onStepChange(diff);
  };

  useEffect(() => {
    const getDetails = async () => {
      setLoading(true);
      const assetsCall = axios
        .get(`${domainUrl}/tender/v1/tender/${tenderId}/asset`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const generalInfoCall = axios
        .get(`${domainUrl}/tender/v1/tender/${tenderId}`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const filesCall = axios
        .get(
          `${awsDocumentUrl}/Prod/v1/document?entity_type=T&entity_id=${tenderId}`,
          { token: sessionStorage.getItem("token") }
        )
        .catch((e) => {
          console.error(e);
          return null;
        });

      const suppliersCall = axios
        .get(`${domainUrl}/tender/v2/tender/${tenderId}/supplier`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const companiesCall = axios
        .get(`${domainUrl}/tender/v2/tender/${tenderId}/company`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      try {
        let title,
          description,
          tender_type,
          additional_info,
          files,
          suppliers,
          companies,
          company_type,
          tender_id,
          is_visible_to_all,
          closing_date,
          days_reminder,
          assetsFormTemplate,
          assetsFormValues;

        const [
          assetsRes,
          generalInfoRes,
          filesRes,
          suppliersRes,
          companiesRes,
        ] = await Promise.all([
          assetsCall,
          generalInfoCall,
          filesCall,
          suppliersCall,
          companiesCall,
        ]);

        if (assetsRes?.data?.data?.assets?.length) {
          assetsFormTemplate = [];
          assetsFormValues = {};
          assetsRes?.data?.data?.assets?.forEach((asset, assetIndex) => {
            const key = `asset${assetIndex + 1}`;
            const assetValue = { [key]: asset?.line_text };
            const itemKeys = asset?.items?.map(
              (_, itemIndex) => `${key}_item${itemIndex + 1}`
            );
            const itemValue = asset?.items?.reduce((acc, item, itemIndex) => {
              acc[itemKeys[itemIndex]] = item?.item_text;
              return acc;
            }, {});


            assetsFormTemplate.push({ key, items: itemKeys });
            assetsFormValues = {
              ...assetsFormValues,
              ...assetValue,
              ...itemValue,
            };
          });
        }

        if (generalInfoRes?.data?.data) {
          ({
            title,
            description,
            additional_info,
            tender_type,
            company_type,
            tender_id,
            is_visible_to_all,
            closing_date,
            days_reminder,
          } = generalInfoRes.data.data);
        }

        if (filesRes?.data?.data?.documents?.length) {
          files = filesRes?.data?.data?.documents?.map((el) => ({
            documentId: el.document_id,
            file: {
              name: el.file_name,
              description: el.file_description,
              size: 30485,
            },
          }));
        }

        if (suppliersRes?.data?.data?.suppliers?.length) {
          suppliers = suppliersRes?.data?.data?.suppliers?.map(
            (el) => el.supplier_id
          );
        }

        if (companiesRes?.data?.data?.companies?.length) {
          companies = companiesRes?.data?.data?.companies?.map(
            (el) => el.company_id
          );
        }

        // { "tender_type": "r", "company_type": 1, "tender_id": 107, "selectedCompanies": [ 1 ], "title": "t1", "description": "2", "visibility": true, "suppliers": [ 1 ], "invites": [], "assetsFormTemplate": [ { "key": "asset1", "items": [ "asset1_item1" ] }, { "key": "asset2", "items": [] } ], "assetsFormValues": { "asset1": "a1", "asset1_item1": "i1", "asset2": "a2" }, "files": [], "closingDate": [ "2021-04-30T04:57:15.820Z", "2021-04-30" ], "reminder": 1 }

        onStepChange(1, {
          tender_type,
          company_type,
          tender_id,
          selectedCompanies: companies,
          title,
          files,
          description,
          visibility: !is_visible_to_all,
          suppliers,
          closingDate: closing_date ? [undefined, closing_date] : undefined,
          reminder: days_reminder,
          additional_info,
          assetsFormTemplate,
          assetsFormValues,
        });
        setLoading(false);
      } catch (e) {
        console.error(e);
        message.error(
          e?.message ?? "Unable to retrieve information from server."
        );
        history.goBack();
      }
    };

    if (tenderId) getDetails();
    else onStepChange(1);
  }, []);

  let content = (
    <TenderContext.Provider value={{ onStepChange, tenderDetails }}>
      {step === 1 && <ChooseTenderType />}
      {step === 2 && <ChooseHousingCompany />}
      {step === 3 && <EnterTenderDetails />}
      {step === 4 && <ChooseSupplier />}
      {step === 5 && <EnterAssetDetails />}
      {step === 6 && <AttachFile />}
      {step === 7 && <ChooseClosingDate />}
      {step === 8 && <AdditionalInfo />}
      {step === 9 && <Summary />}
      {step === 10 && <ConfirmTender />}
    </TenderContext.Provider>
  );

  if (loading) {
    content = (
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
        <ul className="tenderHeader">
          {steps?.map((s, i) => (
            <li
              key={i}
              className={step >= i + 1 ? "active" : null}
              onClick={() => onNavClick(i + 1)}
            >
              {s}
            </li>
          ))}
        </ul>
        <div className="innerContainer">{content}</div>
      </>
  );
};

Tender.propTypes = {};

export default Tender;
