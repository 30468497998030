import "./Tender.less";
import React, { 
  // useCallback, 
  // useContext, 
  useState, 
  useEffect 
} from "react";
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
// import { Spin, Modal, message } from "antd";
import { 
  // useHistory, 
  useParams 
} from "react-router-dom";
import TenderCreationSteps from '../TenderCreationSteps';
import { fetchTenderById, createTender, updateTender } from "../../actions/tender";
import { fetchTenderAsset, createTenderAsset, updateTenderAsset, createTenderAssetItem, deleteTenderAssetItem } from "../../actions/tenderAssets";
import { fetchTenderCompany, deleteTenderCompany, createTenderCompany } from "../../actions/tenderCompanies";
import { fetchTenderSupplier, createTenderSupplier, deleteTenderSupplier } from "../../actions/tenderSuppliers";
import { useDispatch } from "react-redux";
import { fetchOrganizations } from "../../actions/organization";
import { sendEmailCommunication } from "../../actions/communication";
import { Spin } from "antd";

const steps = [
  {label: 'tender_type', key: 1},
  {label: 'choose_company', key: 2},
  {label: 'tender_details', key: 3},
  {label: 'choose_suppliers', key: 4},
  {label: 'asset_details', key: 5},
  {label: 'attach_file', key: 6},
  {label: 'closing_date', key: 7},
  {label: 'add_info', key: 8},
  {label: 'summary', key: 9},
];

const AdminTender = (props) => {
  const dispatch = useDispatch();

  // const [step, setStep] = useState(0);
  // const [tenderModifiedDetails, setTenderModifiedDetails] = useState({});
  const [loading, setLoading] = useState(false);
  // const [showModificationModal, setShowModificationModal] = useState(false);
  // const [reason, setReason] = useState('');
  const [reason] = useState('');

  // const history = useHistory();
  const { tenderId } = useParams();

  const userId = sessionStorage.getItem('user_id');
  const user = useSelector(state => get(state, `entities.users.${userId}`));

  const tenderDetails = useSelector(state => get(state, `entities.tenders.${tenderId}`));
  const tenderCompanies = useSelector(state => Object.keys(get(state, `entities.tenderCompanies`) || {}));
  const tenderSuppliers = useSelector(state => Object.keys(get(state, `entities.tenderSuppliers`) || {}));

  const modifyAssets = async(assetData) => {
    // const createAssetCalls = 
      await Promise.all(
      assetData?.map(async (asset, index) => {
        let data = {};
          if(Array.isArray(asset.asset_name))
             data = isNaN(parseInt(asset.asset_name[0])) ? {line_text: asset.asset_name[0]} : {asset_id: parseInt(asset.asset_name[0])};
          else
            data = asset;
        let assetCall;
        if(!asset.tender_line_id){
          assetCall = await dispatch(createTenderAsset(data, tenderDetails.tender_id));
          let asset_line_id = assetCall.payload?.result[0];
          if(asset.items !== null)
            await dispatch(createTenderAssetItem(asset.items, parseInt(asset_line_id)));
        }
        else {
         assetCall = await dispatch(updateTenderAsset(data, asset.tender_line_id));
          if(tenderDetails?.assetsFormValues[index]?.items != null ) {
          await Promise.all(
            tenderDetails?.assetsFormValues[index]?.items?.map(async item => {
              await dispatch(deleteTenderAssetItem(item.tender_line_item_id)); //update instead of delete
            })
          )
          }
          await dispatch(createTenderAssetItem(asset.items, asset.tender_line_id));
        }
      }));

      await getTenderAssets();
  }

  const modifyCompanies = async(selectedCompanies) => {
    if(tenderCompanies) {
      await Promise.all(
        Object.values(tenderCompanies)?.map(async company => {
          await dispatch(deleteTenderCompany(tenderDetails.tender_id, company));
        }));
    }
    const companies_data = selectedCompanies?.map(el => {
      return { company_id: el }
    })

    // const companyResp = 
    await dispatch(createTenderCompany(companies_data, tenderDetails.tender_id));
    await getTenderCompanies();
  }

  const updateTenderData = async(data,reason) => {
    if(parseInt(tenderDetails?.state) >= parseInt(data?.state)){
      delete data.state;
    }
    let status;
    if(!isEmpty(data)){
      let dataToUpdate = data;
      if(reason)
        dataToUpdate.comments = reason;
        // console.log(dataToUpdate,"=====dataToUpdate");

      const response = await dispatch(updateTender(dataToUpdate, tenderDetails.tender_id));
      status = response.payload?.entities?.tenders[tenderDetails.tender_id] ? true : false;
      if(status && reason && tenderDetails.is_published === 1){
         await dispatch(sendEmailCommunication('MODIFY_TENDER', {tender_id: tenderDetails.tender_id, company_name: user.roles.role_id === 1 ? 'Hissitaito OY' : ''}));
      }
      await getTenderDetails();
    }
    else {
      status = true;
    }
   return status;
  }

  const createTenderData = async(data) => {
    const response = await dispatch(createTender({...data, comments: reason}));
   // setTenderDetails(Object.values(response.payload?.entities?.tenders)[0]);
    const tender_id = Object.values(response.payload?.entities?.tenders)[0]?.tender_id;
    await getTenderDetails(tender_id);
   return tender_id;
  }

  const modifySuppliers = async(selectedSuppliers) => {
    // console.log('2222');
    if(tenderSuppliers) {
      // console.log('3333');
      // await Promise.all(
        tenderSuppliers?.map(async supplier => {
          await dispatch(deleteTenderSupplier(tenderDetails.tender_id, supplier));
        })
      // );
    }
    const supplier_data = selectedSuppliers?.map(el => {
      return { supplier_id: el }
    })
    // console.log('4444');
    // const supplierResp = 
    await dispatch(createTenderSupplier(supplier_data, tenderDetails.tender_id));
    await getTenderSuppliers();
  }

  const getTenderDetails = async (tender_id) => {
    await dispatch(fetchTenderById(tender_id || tenderId));
  }

  const getTenderCompanies = async() => {
    await dispatch(fetchTenderCompany(tenderId));
  }

  const getTenderSuppliers = async() => {
    await dispatch(fetchTenderSupplier(tenderId));
  }

  const getTenderAssets = async() => {
    await dispatch(fetchTenderAsset(tenderId));
  }

  // const getDetails = async () => {
  //   setLoading(true);
  //   // await 
  //   dispatch(fetchOrganizations({ limit: 1000 }));   
  //   // await 
  //   getTenderDetails();

  //   // await 
  //   getTenderCompanies();

  //   // await 
  //   getTenderAssets();

  //   // await 
  //   getTenderSuppliers();

  //   setLoading(false);
  // } 

  const getDetails = async () => {
    setLoading(true);
    try {
        await dispatch(fetchOrganizations({ limit: 1000 }));
        await Promise.all([
            getTenderDetails(),
            getTenderCompanies(),
            getTenderAssets(),
            getTenderSuppliers()
        ]);
    } catch (error) {
        console.error("Error fetching details:", error);
    } finally {
        setLoading(false);
    }
  }

  useEffect(() => {
    if (tenderId){ getDetails(); }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenderId]);
    
  return (
      <Spin spinning={loading}>
        {
          ((tenderId && tenderDetails?.state) || !tenderId) && 
            <TenderCreationSteps steps = {steps} getTenderAssets={getTenderAssets} createTenderData={createTenderData} tenderId = {tenderId} getDetails={getDetails} modifyAssets={modifyAssets} modifyCompanies={modifyCompanies} modifySuppliers={modifySuppliers} updateTenderData={updateTenderData}/>
        }
      </Spin>
  );
};

AdminTender.propTypes = {};

export default AdminTender;
