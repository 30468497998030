/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
// import Avatar from 'antd/lib/avatar/avatar'
import React, { useEffect, useState,useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {getallUser,addUser, updateUser} from '../../actions/auth';
import { useDispatch } from "react-redux";
import './userdetails.css';
// import moment from 'moment';
import {SearchOutlined } from '@ant-design/icons';

import { Button, Input, message,Form,Modal,Table, Space, Spin } from 'antd';
import Header from '../../components/Header';
import { domainUrl } from '../../helper';
import axios from 'axios';
import Highlighter from "react-highlight-words";
export const genHeaders = () => ({
  headers: {
    client_code: "ht_front",
    token: sessionStorage.getItem("token"),
  },
});

const UserDetails=()=>{
  const[Users,setUsers]=useState([]);
  const dispatch=useDispatch()
  const [totalCount, setTotalCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [companies, setCompanies] = useState([]);
  const userId = sessionStorage.getItem('user_id');
  const roleId = sessionStorage.getItem('role_id');
  const entity_id = sessionStorage.getItem('entity_id');
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const intl=useIntl()
  // const companyRef = useRef();
  // const addressRef = useRef();
  // const bidRef = useRef();
  const orgRef = useRef();
  
  const getSelectedUser = (id) => {
    return Users.find((ele) => ele.id === id);
  };
  useEffect(() => {
    const getInfo = async () => {
      try {
        let companies = await axios.get(
          domainUrl + "/masterservicenew/master/company",
          genHeaders()
        );
        companies = companies.data.data.result;

        // const orgInfo = 

        [...new Set(companies?.map(el=>el?.organization_id))]?.map((organization_id) =>
          axios.get(
            `/masterservicenew/master/organization/${organization_id}`,
            genHeaders()
          )
        );

        // let company_types = await Promise.all(orgInfo);

        // company_types =
        //   company_types?.map((el) => el.data.data.company_type_id) ?? [];

        // companies =
        //   companies?.filter(
        //     (_, index) => tenderDetails?.company_type === company_types[index]
        //   ) ?? [];

        if (companies?.length) {
          setCompanies(companies);
          setFilteredCompanies(companies);
          // setSelectedCompanies(tenderDetails?.selectedCompanies ?? []);
          // setAllSelected(companies.length === tenderDetails?.selectedCompanies.length ? true : false);

          const orgs = companies?.map((el) => el?.organization_name) ?? [];
          setOrganizations(Array?.from(new Set(orgs)));
        }
      } catch (e) {
        console.error(e);
        message.error(e?.data?.message ?? "Unable to fetch data from server");
      }
    };

    getInfo();
  }, []);


  const openUserForm = (user_id) => {
    let selected = getSelectedUser(user_id);
    setUser(selected);
    selected?.entities?.map((entity)=>{
      if(entity?.entity_type == 'company'){
        setSelectedCompanies((c) => {
          const isPresent = c.indexOf(entity?.entity_id) > -1;
          if (!isPresent) {
            const newCompanies = selectedCompanies.slice();
            newCompanies.push(entity?.entity_id);
            return newCompanies;
          } else if (isPresent) {
            return c.filter((el) => el !== entity?.entity_id);
          }
        });
      }else{
        setSelectedCompanies([])
      }
    })
    setVisible(true);
  };
  const closeUserForm = async() => {
      setUser({});
      setVisible(false);
      setSelectedCompanies([])
      form.resetFields();
  };
  const onAddUser = async (values) => {
    setIsLoading(true);
    delete values.confirm;
    values["type"] = "O";
    values["created_by"]=parseInt(userId)
    values["is_verified"]=1
    values["role_id"] =parseInt(roleId)
    values["entities"] = [
    
    {
      entity_id:parseInt(entity_id),
      entity_type:"organization",
    },
  ];
  selectedCompanies.map(
    comp =>{
      values["entities"].push(
        {
          entity_id:parseInt(comp),
          entity_type:"company",
        }
      )
    }
  )
    const response = user?.id ? await dispatch(updateUser(user?.id,values)) : await dispatch(addUser(values));
    // console.log("response",response);
    if(response?.error===true){
      message.error(response?.payload?.body?.error?.message)
    }
    else{
    message.success(intl.formatMessage({ id: "User_success" }));
    setIsLoading(false);
    setUser({});
    getuserdetails(1, pageSize)
    form.resetFields()
    setVisible(false)
    }
  };
  const onCompanySelect = ({ target }) => {
    const id = +target.value;
    setSelectedCompanies((c) => {
      const isPresent = c.indexOf(id) > -1;
      if (!isPresent) {
        const newCompanies = selectedCompanies.slice();
        newCompanies.push(id);
        return newCompanies;
      } else if (isPresent) {
        return c.filter((el) => el !== id);
      }
    });
  };

  const onFilterChange = () => {
    // const company = companyRef.current.value?.toLowerCase() ?? "";
    // const addr = addressRef.current.value?.toLowerCase() ?? "";
    // const bid = bidRef.current.value?.toLowerCase() ?? "";
    const org = orgRef.current.value?.toLowerCase() ?? "";

    const filtered = companies?.filter(
      ({ company_name, address, business_id, organization_name }) => {
        // const includesCompany = company_name.toLowerCase().includes(company);
        // const includesAddress = address.toLowerCase().includes(addr);
        // const includesBid = business_id.toLowerCase().includes(bid);
        const includesOrg = organization_name.toLowerCase().includes(org);
        // return true;
        return includesOrg;
      }
    );

    setFilteredCompanies(filtered);
  };
    useEffect(()=>{
        getuserdetails(1,pageSize)
    },[]);
    
    const getuserdetails = async(page, pageSize) => {
        // setConfirmLoading(true);
        // const response = await dispatch(getallUser({limit: pageSize, offset: pageSize*(page-1), order_by: 'created_at', sort_by: 'DESC' }));
        // const countresponse = await dispatch(getallUser({limit: 100000, offset: 0, order_by: 'created_at', sort_by: 'DESC',entity_id:entity_id }));
        const response = await dispatch(getallUser({limit: pageSize, offset: pageSize*(page-1), order_by: 'created_at', sort_by: 'DESC',entity_id:entity_id }));
        
        // const count = response?.payload?.data?.meta?.count;
        const count = response?.payload?.data?.meta?.size;
        const result = response?.payload?.data?.result;
        const filterUserResponse=await result.filter((user)=> /*user?.entities?.[0]?.entity_id==entity_id &&*/ user?.roles?.role_id==1)
       
        filterUserResponse.map(obj =>{
          obj['company'] = Array.prototype.map.call(obj['entities'], function (item) {
            if (item.Company != null) {
              return item.Company.company_name;
            }
          }).join(',').substring(1);
        })
        // setConfirmLoading(false);
        setTotalCount(count);
        setUsers(filterUserResponse);
    }
    // const onPaginationChange = (page, pageSize) => {
    //     setPageSize(pageSize);
    //     getuserdetails(page, pageSize);
    // }
    const onPaginationChange = (pageDetails) => {
      setPageSize(pageDetails.pageSize);
      getuserdetails(pageDetails.current, pageDetails.pageSize);
  }
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
     setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    };
    
    const handleReset = clearFilters => {
      clearFilters();
      setState({ searchText: '' });
    };
    
    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            // ref={node => {
            //   searchInput = node;
            // }}
            placeholder={intl.formatMessage({ id:"search"})+` ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              <FormattedMessage id="search" />
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              <FormattedMessage id="reset" />
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setState({
                  searchText: selectedKeys[0],
                  searchedColumn: dataIndex,
                });
              }}
            >
              <FormattedMessage id="filter" />
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          // setTimeout(() => searchInput.select(), 100);
        }
      },
      render: text =>
        state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
    const [state, setState] =  useState({
      searchText: '',
      searchedColumn: '',
    });
    const columns = [
      {
       title: intl.formatMessage({ id:'label_name'}),
       dataIndex: 'name',
       key: 'name', //api response
       sorter: (a, b) => a.name?.length - b.name?.length,
       defaultSortOrder: 'descend',
       ...getColumnSearchProps('name'),
       width: 100,
      },
      {
       title: intl.formatMessage({ id:'email'}),
      dataIndex: 'email',
       key: 'email',
       width: 45,
      },
      {
       title:intl.formatMessage({ id:'contact'}),
        dataIndex: 'contact',
       key: 'button',
      
       ellipsis: true,
       width: 45,
      },
      {
        title: intl.formatMessage({ id:'company'}),
        
        dataIndex: 'company',
        key: 'company',
        width: 45,
      },
      {
        dataIndex: "id",
        key: "id",
        title:intl.formatMessage({ id:'modify'}),
        render: (value) => {
          return (
            <div
            className="btn-primary"
            onClick={() => openUserForm(value)}
          >
             <FormattedMessage id={'modify'} />
          </div>
            );
        },
        width: 45,
      },
    
     ];
  return(
    <div>
      <Header />
      <div className="mainContainer profileContainer">
      	<div className="innerContainer">
          <h1 className="profile">{<FormattedMessage id={'adminusers'} />}</h1>
          <div className="profile-wrapper">
            <Button
              type="primary"
              style={{ marginLeft: "5px" }}
              className="btn"
              // onClick={() => setVisible(true)}
              onClick={() => openUserForm({})}
            >
              {/* <FormattedMessage id={'edit'} /> */}
              <FormattedMessage id="AddUser"/>
            </Button>
          <h3>{<FormattedMessage id={'userdetails'} />}</h3>
          <Spin spinning={isLoading}>
            <Table
              className="ant-table-root"
              pagination={{ defaultPageSize:pageSize, showSizeChanger: false, total: totalCount }}
              //  current:currentPage,
              columns={columns}
              //  scroll={{ y: 240 }} 
              onChange={onPaginationChange}
              dataSource={Users}
              bordered
              rowKey='id'
              locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
            />
          </Spin>
      {/* <div className="dataBlock card msgCard">
      
            <ul className="dataTitle list2">
              <li className="w-5"><FormattedMessage id="user" /></li>
              <li><FormattedMessage id="email"/></li>
              <li><FormattedMessage id="contact"/></li>
              <li><FormattedMessage id="company"/></li>
              <li><FormattedMessage id="organisation"/></li>
            </ul>
           
            {
                Users?.map((msg,index) => (
                    <div key={index} className="list-items">
                         <div className="profilePic">
                            <Avatar className="msgAvatar">{msg?.name.substring(0,1)}</Avatar>
                        </div>
                        <ul className={'dataContent list2 bold'}>
                        
                            <li className="d-flex w-3">
                            <span>{msg?.name}</span>
                            </li>
                            <li>
                            <span>{msg?.email}</span>
                            </li>
                            <li>
                            <span>{msg?.contact}</span>
                            </li>
                            <li>
                            <span>{msg?.company}</span>
                            </li>
                             <li>
                            <span>{msg?.organisation}</span>
                            </li>
                        </ul>
                        </div>
                ))
            }
            
            <Pagination
                className="msgsPagination"
                size="small"
                showSizeChanger
                onChange={onPaginationChange}
                defaultCurrent={1}
                total={totalCount}
                showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({ id: 'of' })} ${total} ${intl.formatMessage({ id: 'items' })}  `}
                />
                   
    
        </div> */}
        {visible &&
          <Modal
            footer={null}
            open={visible}
            title={user?.id ? intl.formatMessage({id:"Edit User"}) : intl.formatMessage({id:"AddUser"})}
            // onOk={this.handleOk}
            onCancel={closeUserForm}
            // onCancel={() => {
            //   setUser({});
            //   setVisible(false);
            //   form.resetFields();
            // }}
          >
            <Form
              // form={form}
              initialValues={user}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              onFinish={onAddUser}
            >
              <Form.Item
                label={<FormattedMessage id={"label_name"} />}
                name="name"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id={"error.name"} />,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={<FormattedMessage id={"email"} />}
                name="email"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id={"error.email"} />,
                  },
                  {type: 'email',
                  message: <FormattedMessage id={"error.invalid_email"} />},
                ]}
              >
                <Input disabled={user?.id}/>
              </Form.Item>
              <Form.Item
                label={<FormattedMessage id={"mobile_number"} />}
                name="contact"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id={"error.mobile_number"} />,
                  },
                  {pattern:/\d+/g, message: <FormattedMessage id={"error.invalid_mobile_number"} />},
                ]}
              >
                <Input type= "text" maxLength= "16" minLength="6" />
              </Form.Item>
              {!user?.id &&
                <>
                  <Form.Item
                    label={<FormattedMessage id={"password"} />}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id={"error.password"} />,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    label={<FormattedMessage id={"cnfrmPassword"} />}
                    name="confirm"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id={"error.password"} />,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(intl.formatMessage({ id: "match_pwd" }))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </>
              }
              <div className="formGroup">
                <label className="leftCol"><FormattedMessage id="choose_org"/></label>
                <div className="selectDate">
                  <div className="costumSelect">
                    <select
                      ref={orgRef}
                      name="country"
                      onChange={onFilterChange}
                      id="organization_name"
                      className="inputFiled"
                    >
                      <option value="">{intl.formatMessage({id: 'all'})}</option>
                      {organizations?.map((el, index) => (
                        <option key={`${el}${index}`} value={el}>
                          {el}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="cardBlock">
                <table className="tableGrid fixed_height" width="100%" border="0">
                  <tbody>
                    <tr>
                      <th align="left" valign="top">
                      <FormattedMessage id="choose_company"/>
                      </th>
                      <th align="left" valign="top">
                      <FormattedMessage id="select"/>
                      </th>
                    </tr>
                    {filteredCompanies?.map((el, index) => (
                      <tr key={`${el.created_at}${el.business_id}${index}`}>
                        <td align="left" valign="top">
                          {el.company_name}
                        </td>
                        <td align="left" valign="top">
                          <div className="radioBtn">
                            <input
                              type="checkbox"
                              id={`${el.business_id}${el.company_name}${index}`}
                              value={el.company_id}
                              checked={
                                selectedCompanies?.includes(+el.company_id)
                                  ? "checked"
                                  : null
                              }
                              onChange={onCompanySelect}
                            />
                            <label
                              htmlFor={`${el.business_id}${el.company_name}${index}`}
                            ></label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <div className="btn housingbtn" onClick={onSelectAll}>
                  {!allSelected ? 'Select All' : 'Unselect All'}
                </div> */}
              </div>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit" className="btn">
                  {/* <FormattedMessage id={"change_pwd"} /> */}
                <FormattedMessage id="submit"/>
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        }

         </div>
        </div>
      </div>
    </div>     
  )
}
export default UserDetails