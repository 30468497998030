/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Spin } from 'antd';
import get from 'lodash/get';
import Header from "../../components/Header";
import InspectionReport from '../../components/InspectionReport';
import ConfirmationBox from '../../components/ConfirmationBox';
import MessageToast from "../../components/MessageToast";
import {
	fetchAsset,
  fetchAssetProperties,
} from "../../actions/assets";

import { fetchCompany } from "../../actions/company";
import { createInspectionReports, deleteInspectionReports, fetchInspectionReport } from "../../actions/inspection-report";
import {getUser} from '../../actions/auth';
import { FormattedMessage, useIntl } from 'react-intl';
// import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { fetchDocument, fetchFiles } from '../../actions/documents';
const AddInspectionReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const reportRef = useRef();
  const intl = useIntl();
  const {assetId} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [inspectionReportDetails, setInspectionReportDetails] = useState({});
  const [reportDetails, setReportDetails] = useState({});
  const [inspectionReportId, setInspectionReportId ] = useState();
  // const [assetID, setAssetID ] = useState();
  const [copiedFiles, setCopiedFiles ] = useState([]);
  const [showFeedback, setShowFeedback] = useState({
    open: false,
    success: false,
    error: false,
    info: false,
    message: "",
  });
  // let windows_event;
  // let has_disconnected = false;
  //const assetDetails = useSelector(state => get(state, `entities.assets.${assetId}`, {}));

  const userName = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}`).name || 'Admin');
  
  const fetchReport = async(reportId) => {
      setIsLoading(true);
      const response = await dispatch(fetchInspectionReport(reportId));
      const reportData = response.payload?.entities?.inspections[reportId] || {};
      reportData?.inspection_id && delete reportData?.inspection_id;
      setInspectionReportDetails(reportData);
      await getDocuments(parseInt(localStorage.getItem('copied_report_id')));
      localStorage.removeItem('copied_report_id');
      // setInspectionReportId(reportId);
  }
  const onFileLoad = async (file) => {
    const response = await dispatch(fetchDocument(file?.document_id));
    let link =  response.payload?.entities?.files[file?.document_id]?.signed_link || '';
    let base64 = await fetch(`${link}`)
    .then(response => response.blob())
    .then(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((res) => {
        reader.onloadend = () => {
        res(reader.result);
      }})
    })

    base64 = base64.replace('application/json','image/jpg');
    return base64;
  };
  function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[arr.length - 1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
  }
  
  const getDocuments = async(entity_id) => {
    // let entities = null;
    const documents = await dispatch(fetchFiles({entity_type: "IR", entity_id: entity_id}));
    let uploadedFiles = Object.values(documents.payload?.entities?.files || []);
    if(Object.values(documents.payload?.entities?.files || [])?.length){
      await Promise.all(
        uploadedFiles = Object.values(documents.payload?.entities?.files).map(async(row)=>{
          row.documentId= null;
          row.desc= row.file_description;
          row.contentType= row.file_type;
          let url = await onFileLoad(row)
          // row.file = await fetch(url)
          //             .then(res => res.blob())
          //             .then(blob => {
          //               const file = new File([blob], row?.name,{ type: "image/png" })
          //           })
          row.file = dataURLtoFile(url,row?.file_name)
          return row;
        })
      )
    }
    setCopiedFiles(Object.values(documents.payload?.entities?.files || [])); 
  }
  // useEffect(() => {
  //   let getReportDetailsFunc =async () => {
  //     await getReportDetails();
  //   }

  //   if(inspectionReportDetails){
  //     getReportDetailsFunc();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[inspectionReportDetails]);

  useEffect(() => {
    const getReportDetailsFunc = async () => {
      await getReportDetails();
    };
  
    if (inspectionReportDetails) {
      getReportDetailsFunc();
    }
  }, [inspectionReportDetails]);

  useEffect(() => {
    const createReport = async() => {
      if(!localStorage.getItem('reportId')) {
        const response = await dispatch(createInspectionReports({},assetId));
        let reportId = response?.payload?.result ? response?.payload?.result[0] : '';
        localStorage.setItem('reportId', reportId);
        setInspectionReportId(reportId)
      }
      else {
        await deleteReport();
        const response = await dispatch(createInspectionReports({},assetId));
        let reportId = response?.payload?.result ? response?.payload?.result[0] : '';
        localStorage.setItem('reportId', reportId);
        setInspectionReportId(reportId)
        // setInspectionReportId(parseInt(localStorage.getItem('reportId')));
      }
    }
    createReport();

  }, [assetId]);

  useEffect(() => {
    let localFunc = async () => {
      await deleteReport();
      if(localStorage.getItem('copied_report_id')){
        await fetchReport(parseInt(localStorage.getItem('copied_report_id')));
      }
    }
    localFunc();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteReport = async () => {
    if((inspectionReportId && inspectionReportId!=='') || localStorage.getItem('reportId')){
        // let response = 
          await dispatch(deleteInspectionReports(inspectionReportId || localStorage.getItem('reportId')));
        localStorage.removeItem('reportId');
    }
  }

  const confirmDelete = () => {
    deleteReport();
  }

  const declineSubmit = () => {
    setShowConfirmationBox(false);
  }
  
  const getReportDetails = async() => {
    const asset = await dispatch(fetchAsset(assetId));
    let userId = sessionStorage.getItem('user_id');
    await dispatch(getUser(userId));
    const companyId = asset?.payload?.entities?.assets[assetId]?.company_id;
    const company = await dispatch(fetchCompany(companyId));
    const report  = (Object.assign(asset.payload.entities?.assets[assetId], company.payload.entities?.companies[companyId]) );
    // setIsLoading(false);
    const response = await dispatch(fetchAssetProperties(1));
    const propertyData = Object.values(
      response.payload.entities.assetProperty
    );
    mapPropertyIdNames(propertyData, asset.payload.entities.assets[assetId], report, userName);
  }
  useEffect(() => {
		getReportDetails();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, assetId]);

  const mapPropertyIdNames = (propertyData, asset, report, userName) => {
    let propertiesData = [];
    propertyData.map((prop) => {
      let obj = {};
      obj.property_id = prop.property_id;
      const propSelected = asset.asset_values.find(
        (ele) => ele.property_id === prop.property_id
      );
      if(propSelected ) {
        obj.property_name = prop.property_name;
        obj.property_value = propSelected ? propSelected.property_value : "";
        propertiesData.push(obj);
      }
    });
    const data = {
      ...report,
      // inspection_lines:inspectionReportDetails?.inspection_lines,
      ...inspectionReportDetails,
      asset_properties: propertiesData,
      admin_description:intl.formatMessage({id:"Hissitaito Oy on puolueeton hissikonsultointiin erikoistunut yritys, joka tuottaa asiakkailleen valvontapalveluja ja erilaisia yksilöllisiä palveluja asiakkaiden tarpeiden mukaan. Tämä raportti liitteineen on Hissitaito Oy:n näkemys taloyhtiön hissien kunnosta ja arvio seuraavien vuosien kunnostustarpeista. Raporttia ei saa käyttää tarjouspyyntönä. Raportti on tarkoitettu ohjeelliseksi ja avuksi laadittaessa vuosittaisia kunnossapitobudjetteja. Hissien kuntokartoitus olisi hyvä päivittää n. viiden vuoden välein, jotta se pysyy ajan tasalla."}),
      inspected_by: userName,
    };
    setIsLoading(false);
    setReportDetails(data);
  };

  const finishReportCreation = (isSuccess) => {
    setShowFeedback({
      open: true,
      success: isSuccess,
      error: !isSuccess,
      info: false,
      message: isSuccess ? intl.formatMessage({id:"Inspection Report Saved Successfully"}) : intl.formatMessage({id:"Inspection Report Not Saved "}),
    });
    localStorage.removeItem('reportId');
    setTimeout(() => {
      history.push(`/inspection-report/${inspectionReportId}`)
    },1000);
  }

  const closeFeedback = () => {
    setShowFeedback({
      open: false,
      success: false,
      error: false,
      info: false,
      message: "",
    });
  };

	return (
    <>
    {showFeedback.open && (
        <MessageToast
          open={showFeedback.open}
          success={showFeedback.success}
          error={showFeedback.error}
          message={showFeedback.message}
          onClose={closeFeedback}
        />
      )}
      <Header />
      <div className="mainContainer" style={{padding: '20px 0'}}>
      	<div className="innerContainer">
        <Spin spinning={isLoading}>
          <h2 className="title"><FormattedMessage id="Elevator's inspection report"/></h2>
              {
                Object.values(reportDetails).length > 0 &&
                <>
                  <InspectionReport copiedFiles={copiedFiles} fetchReport={fetchReport}  reportDetails={reportDetails} inspectionReportId={inspectionReportId} finishReportCreation = {finishReportCreation}/>
                </>
              }
           </Spin>   
        </div>
      </div>
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={'Entered data will be lost if you refresh or navigate from this page. Are you sure you want to continue?' } confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />	
   
    </>
  );
};
 export default AddInspectionReport;
