import React, { useState, useEffect, useRef } from "react";
import { message, Spin } from "antd";
import get from "lodash/get";
import { useSelector } from "react-redux";

import "./ChooseHousingCompany.css";
import { useTender } from "../TenderCreationSteps";
import { useDispatch } from "react-redux";
import { Checkbox } from "antd";
import { fetchCompanies, fetchCompanyTypes } from "../../../actions/company";
import { fetchOrganizations } from "../../../actions/organization";
// import { createCompanyPreTender, updatePreTender, deleteCompanyPreTender } from "../../../actions/pre-tender";
import { useParams } from "react-router";
import ToolTip from "../../ToolTip";
import { FormattedMessage, useIntl } from "react-intl";

const ChooseHousingCompany = ({ modifyCompanies, updateTenderData }) => {
  const dispatch = useDispatch();
  const { tenderId } = useParams();
  const {
    tenderDetails,
    onStepChange: move,
    modifyData,
    tenderModifiedDetails,
  } = useTender();
  const intl = useIntl();
  const role_id = useSelector((state) =>
    get(
      state,
      `entities.users.${sessionStorage.getItem("user_id")}.roles.role_id`
    )
  );

  const login_org = useSelector((state) =>
    get(state, `entities.users.${sessionStorage.getItem("user_id")}.entities`)
  );
  const companyTypes = useSelector((state) =>
    get(state, `entities.companyTypes`)
  );
  const companies = useSelector((state) => get(state, `entities.companies`));
  const organizations = useSelector((state) =>
    get(state, `entities.organizations`)
  );
  tenderDetails.selectedCompanies = useSelector((state) =>
    Object.values(get(state, `entities.tenderCompanies`) || []).filter(
      (item) => {
        return item.tender_id === parseInt(tenderId);
      }
    )
  );
  const [filteredCompanies, setFilteredCompanies] = useState(
    Object.values(companies || {})
  );
  const [filteredOrganization, setFilteredOrganization] = useState([]);
  const [filteredOrganization2, setFilteredOrganization2] = useState("");

  const [allSelected, setAllSelected] = useState(false);

  const companyRef = useRef();
  const addressRef = useRef();
  const bidRef = useRef();
  const companyTypeRef = useRef();
  const orgRef = useRef();

  const [selectedCompanies, setSelectedCompanies] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getInfo = async () => {
      if (!companyTypes) await dispatch(fetchCompanyTypes({ limit: 1000 }));

      if (!companies) await dispatch(fetchCompanies({ limit: 1000 }));

      if (!organizations) await dispatch(fetchOrganizations({ limit: 1000 }));
    };

    getInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setFilteredCompanies(Object.values(companies || {}));
    setFilteredOrganization(Object.values(organizations || {}));
    let companyIds;
    if (tenderDetails?.is_published === 0) {
      companyIds = tenderDetails?.selectedCompanies?.map((item) => {
        return item.company_id;
      });

      setAllSelected(
        Object.values(companies || {}).length ===
          tenderDetails?.selectedCompanies?.length
          ? true
          : false
      );
    } else {
      if (!tenderModifiedDetails?.selectedCompanies && tenderDetails)
        tenderModifiedDetails.selectedCompanies = tenderDetails?.selectedCompanies?.map(
          (item) => {
            return item.company_id;
          }
        );
      if (tenderModifiedDetails?.selectedCompanies[0].company_id) {
        companyIds = tenderDetails?.selectedCompanies?.map((item) => {
          return item.company_id;
        });
      } else
        companyIds = tenderModifiedDetails?.selectedCompanies?.map((item) => {
          return item;
        });

      setAllSelected(
        Object.values(companies || {}).length ===
          tenderModifiedDetails?.selectedCompanies?.length
          ? true
          : false
      );
    }
    if (role_id !== 2) onCompanyTypeChange();
    else {
      setFilteredCompanies(
        Object.values(companies || {}).filter((comp) => {
          return comp.organization_id === parseInt(login_org[0].entity_id);
        })
      );
    }
    setSelectedCompanies(companyIds || []);
    if (filteredCompanies.length === companyIds.length) {
      setAllSelected(true);
     }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[companies,tenderDetails]);

  const onCompanyTypeChange = () => {
    const companyType = companyTypeRef.current.value ?? "";
    const filtered = Object.values(organizations || [])?.filter(
      ({ company_type_id }) => {
        const includesCompanyType =
          company_type_id === +companyType || companyType === "0";
        // return true;
        return includesCompanyType;
      }
    );

    setFilteredOrganization(filtered);
    //<!----To Do --->
    setTimeout(() => {
      onFilterChange();
    }, 100);
  };

  const onFilterChange = () => {
    const company = companyRef?.current?.value?.toLowerCase() ?? "";
    const addr = addressRef?.current?.value?.toLowerCase() ?? "";
    const bid = bidRef?.current?.value?.toLowerCase() ?? "";
    const org = orgRef?.current?.value ?? "";
    if (selectedCompanies.length > 0) {
      if (
        selectedCompanies[0]?.organization_id &&
        selectedCompanies[0].organization_id !== +org
      ) {
        setSelectedCompanies([]);
      }
    }
    const filtered = Object.values(companies || {})?.filter(
      ({ company_name, address, business_id, organization_id }) => {
        const includesCompany = company_name.toLowerCase().includes(company);
        const includesAddress = address.toLowerCase().includes(addr);
        const includesBid = business_id.toLowerCase().includes(bid);
        const includesOrg = role_id !== 2 ? organization_id === +org : true;
        // return true;
        return includesCompany && includesAddress && includesBid && includesOrg;
      }
    );

    setFilteredCompanies(filtered);
    setFilteredOrganization2(org);
  };

  const onCompanySelect = ({ target }) => {
    const id = +target.value;
    //setSelectedCompanies(id);
    setSelectedCompanies((c) => {
      const isPresent = c.indexOf(id) > -1;
      if (!isPresent) {
        const newCompanies = selectedCompanies.slice();
        newCompanies.push(id);
        return newCompanies;
      } else if (isPresent) {
        return c.filter((el) => el !== id);
      }
    });
  };

  const onSelectAll = () => {
    const companyIds = !allSelected
      ? filteredCompanies?.map((el) => +el.company_id) ?? []
      : [];
    setSelectedCompanies(companyIds);
    setAllSelected(!allSelected);
  };

  const getNameById = (id) => {
    return Object.values(companies).find((el) => el.company_id === id);
  };

  const onBack = () => move(-1, { selectedCompanies });

  const onNext = async () => {
    const companyNames = [];
    if (!selectedCompanies || selectedCompanies.length === 0) {
      setErrorMessage(
        intl.formatMessage({ id: "Please select atleast one company." })
      );
      return;
    }
    const companies_data = selectedCompanies?.map((el) => {
      companyNames.push(getNameById(el));
      return { company_id: el };
    });

    // console.log(companies_data);

    setLoading(true); // Added to Handle Next Click Disable

    //const companies_data = [{ company_id: selectedCompanies }];

    try {
      if (tenderDetails.is_published !== 1) {
        await modifyCompanies(selectedCompanies);
        let selected_org_id =
          role_id === 2
            ? parseInt(login_org[0].entity_id)
            : Object.values(companies).find(
                (comp) => comp.company_id === companies_data[0].company_id
              ).organization_id;
        const status = await updateTenderData({
          organization_id: selected_org_id,
          company_type: organizations[selected_org_id].company_type_id || 1,
          state: "2",
        });
        if (status) {
          move(1, { selectedCompanies, companyNames });
        } else {
          message.error("Unable to reach server.");
        }
      } else {
        move(
          1,
          {
            selectedCompanies,
            companyNames,
          },
          true
        );
      }
    } catch (e) {
      message.error(e?.message ?? "An error occurred. Please try again.");
    } finally {
      setLoading(false); // Reset loading state after API call completes
    }
  };

  const publishData = () => {
    const companyNames = []
    // eslint-disable-next-line array-callback-return
    selectedCompanies?.map(el => {
      companyNames.push(getNameById(el));
    });
    move(
      0,
      {
        selectedCompanies,
        companyNames,
      },
      true
    );
    modifyData();
  };

  return (
    <>
   {/* { console.log(selectedCompanies)} */}

      <div className="companyWrapper">
        <div className="hedding">
          <h2>
            <FormattedMessage id="choose_company" />{" "}
            <ToolTip text_id="tender.choose_company.info" placement="top" />
          </h2>
        </div>
        <div className="flexContainer">
          <div>
            <div className="formGroup">
              <h3 className="subheading">
                <FormattedMessage id="filters" />
              </h3>
            </div>
            <div className="formGroup">
              <label className="leftCol">
                <FormattedMessage id="company" />
              </label>
              <div className="selectDate">
                <input
                  ref={companyRef}
                  onChange={onFilterChange}
                  type="text"
                  id="company_name"
                  className="formFiled"
                />
                <div className="searchIcon"></div>
              </div>
            </div>
            <div className="formGroup">
              <label className="leftCol">
                <FormattedMessage id="address" />
              </label>
              <div className="selectDate">
                <input
                  ref={addressRef}
                  onChange={onFilterChange}
                  type="text"
                  className="formFiled"
                  id="address"
                />
                <div className="searchIcon"></div>
              </div>
            </div>
            <div className="formGroup">
              <label className="leftCol">
                <FormattedMessage id="business_id" />
              </label>
              <div className="selectDate">
                <input
                  ref={bidRef}
                  onChange={onFilterChange}
                  type="text"
                  id="business_id"
                  className="formFiled"
                />
                <div className="searchIcon"></div>
              </div>
            </div>

            {role_id !== 2 && (
              <>
                <div className="formGroup">
                  <label className="leftCol">
                    <FormattedMessage id="company_type" />
                  </label>
                  <div className="selectDate">
                    <div className="inputText">
                      <div className="costumSelect">
                        <select
                          ref={companyTypeRef}
                          name="country"
                          onChange={onCompanyTypeChange}
                          id="company_type"
                          className="inputFiled"
                        >
                          <option value="0">
                            {intl.formatMessage({ id: "all" })}
                          </option>
                          {Object.values(companyTypes || {})?.map(
                            (el, index) => (
                              <option
                                key={`${el}${index}`}
                                value={el.company_type_id}
                              >
                                {el.company_type_name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="formGroup">
                  <label className="leftCol">
                    <FormattedMessage id="choose_org" />
                  </label>
                  <div className="selectDate">
                    <div className="costumSelect">
                      {/* {console.log(tenderDetails.selectedCompanies)} */}
                      <select
                        ref={orgRef}
                        name="country"
                        onChange={onFilterChange}
                        value={
                          filteredOrganization2 ||
                          (tenderDetails.selectedCompanies &&
                            tenderDetails.selectedCompanies[0]?.organization_id)
                        }
                        id="organization_name"
                        className="inputFiled"
                      >
                        {/* <option value="">All</option> */}
                        {filteredOrganization?.map((el, index) => (
                          <option
                            key={`${el}${index}`}
                            value={el.organization_id}
                          >
                            {el.organization_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            <div className="formGroup">
              <h3 className="subheading">
                <FormattedMessage id="choose_company" />
              </h3>
            </div>
            <div className="cardBlock">
              <table className="tableGrid fixed_height" width="100%" border="0">
                <thead>
                  <tr>
                    <th align="left" valign="top">
                      <FormattedMessage id="choose_company" />
                    </th>
                    <th align="left" valign="top">
                      <FormattedMessage id="select" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCompanies?.map((el, index) => (
                    <tr key={`${el.created_at}${el.business_id}${index}`}>
                      <td align="left" valign="top">
                        {el.company_name}
                      </td>
                      <td align="left" valign="top">
                        <div className="radioBtn">
                          <Checkbox
                            id={`${el.business_id}${el.company_name}${index}`}
                            value={el.company_id}
                            checked={selectedCompanies?.includes(
                              +el.company_id
                            )}
                            onChange={onCompanySelect}
                          ></Checkbox>
                          {/* <input
                            type="checkbox"
                           // name="radio-group"
                            id={`${el.business_id}${el.company_name}${index}`}
                            value={el.company_id}
                            //checked={+el.company_id === selectedCompanies}
                            checked={
                              selectedCompanies?.includes(+el.company_id)
                                ? "checked"
                                : null
                            }
                            onChange={onCompanySelect}
                          />
                          <label
                            htmlFor={`${el.business_id}${el.company_name}${index}`}
                          ></label> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="btn housingbtn" onClick={onSelectAll}>
                {!allSelected ? (
                  <FormattedMessage id="select_all" />
                ) : (
                  <FormattedMessage id="unselect_all" />
                )}
              </div>
            </div>
          </div>
        </div>
        {errorMessage && (
          <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
            {errorMessage}
          </p>
        )}
        <div className="buttonBlock">
          <div onClick={onBack} className="btn cancelBtn">
            <FormattedMessage id="bakc" />
          </div>
          {tenderDetails?.is_published === 1 && (
            <div className="btn" onClick={publishData}>
              <FormattedMessage id="publish" />
            </div>
          )}




          <div onClick={!loading ? onNext : null} className={`btn ${loading ? 'disabled' : ''}`} disabled={loading} >
          {loading ? <Spin style={{ marginRight: '10px' }} /> : <FormattedMessage id="next" />}
          </div>
        </div>
      </div>
    </>
  );
};

// Tender.propTypes = {};

export default ChooseHousingCompany;
