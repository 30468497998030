// import { schema } from "normalizr";
import { CUSTOM_AUTHORIZED_REQUEST } from '../middleware/apiAuthorization';
import {
	handleReducerResponse,
	handleError,
} from "../utils";
import apiUrls  from "../utils/apiUrls";

export const SEND_COMMUNICATION_REQUEST = "SEND_COMMUNICATION_REQUEST";
export const SEND_COMMUNICATION_SUCCESS = "SEND_COMMUNICATION_SUCCESS";
export const SEND_COMMUNICATION_FAILURE = "SEND_COMMUNICATION_FAILURE";

/**
 * Returns a redux api middleware action that makes a request to send emails.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const sendEmailCommunication = (event, data) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.communicationRequest,
			method: "POST",
            body: JSON.stringify({
                event: event,
                payload: data}),
			types: [
				{ type: SEND_COMMUNICATION_REQUEST },
				{
					type: SEND_COMMUNICATION_SUCCESS,
					payload: (action, state, res) =>
						handleReducerResponse(action, state, res),
				},
				{ type: SEND_COMMUNICATION_FAILURE, payload: handleError },
			],
		}
	}
}
