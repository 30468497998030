import "./App.less";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { IntlProvider } from 'react-intl'
import SMSSetting from "./components/SMSSetting/SMSSetting";
import EmailSetting from "./components/EmailSetting/EmailSetting";
import PaymentGateway from "./components/PaymentGateway/PaymentGateway";
import ServiceType from "./components/ServiceType/ServiceType";
import ConsultancyPrices from "./components/ConsultancyPrices/ConsultancyPrices";
import CompanyType from "./components/CompanyType/CompanyType";
import Login from "./components/Login/Login";
import SignUp from "./components/SignUp/SignUp"
import Dashboard from "./pages/dashboard";
import Dash from "./pages/dash";
import ControlPanel from "./pages/controlPanel";
import ControlPanelDetails from "./pages/dashboardDetails";
import LoggedInState from './components/LoggedInState';
import PrivateRoute from "./components/PrivateRoute";
import OrganizationManagement from "./pages/organizationManagement";
import Company from "./components/Company";
import CompanyUserDetails from "../src/pages/companyuserdetails/CompanyUserDetails"
import OrganisationCompany from "./components/organisationcompany/OrganisationCompany";
import Tender from "./pages/tender";
import TenderDetail from "./components/TenderDetail/TenderDetail";
import TenderDetailSupplier from "./components/TenderDetailSupplier/TenderDetail";
import TenderCompletedDetails from "./components/TenderCompletedDetails/TenderDetail";
import BidReceived from "./pages/bidReceived";
import Asset from "./pages/asset";
import AddRating from "./pages/addrating/AddRating"
import SupplierManagement from "./pages/supplierManagement";
import InspectionReport from "./pages/inspectionReport";
import AddInspectionReport from "./pages/addInspectionReport";
import ViewInspectionReport from './pages/viewInspectionReport';
import PreTender from './pages/preTender';
import PreTenderDetail from './pages/preTenderDetail'
import AdminPreTender from './pages/adminPreTender';
import { getUser } from "./actions/auth";
import AllTenders from './pages/allTenders';
import AddDocument from './pages/documentfile/AddDocument'
import Bid from "./pages/bid";
import BidSubmitted from './pages/bidSubmitted';
import axios from "axios";
import { useEffect, useState } from "react";
import SupplierRegistration from './pages/supplierRegistration';
import SupplierRatingList from './pages/supplierRating';
import UserProfile from './pages/userProfile';
import UserDetails from './pages/userdetails/UserDetails'
import PropertyUserDetails from './pages/propertyuserdetails/PropertyUserDetails'
import Reminder from "./components/Login/Reminder";
import TenderMessages from "./pages/allTenders/tenderMessages";
axios.defaults.baseURL = "https://app.tenderin.fi";

function App(props) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  let userId = sessionStorage.getItem('user_id');
  // console.log(userId)

  const getUserData = async() => {
    setIsLoading(true);
    await dispatch(getUser(userId));
    setIsLoading(false);
  }

  useEffect(() => {
    if(userId) {
      setIsLoading(true);
      getUserData();
    }
    else {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userId])

  document.addEventListener("mousemove", () =>{ 
    localStorage.setItem('lastActvity', new Date())
  });
  document.addEventListener("click", () =>{ 
    localStorage.setItem('lastActvity', new Date())
  });
  let timeInterval = setInterval(() => {
    let lastAcivity = localStorage.getItem('lastActvity')
    var diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
    var seconds = Math.floor((diffMs/1000));
    var minute = Math.floor((seconds/60));
    // console.log(seconds +' sec and '+minute+' min since last activity')
    if(minute === 30 && window.location.pathname !== '/login'){
      // console.log('No activity from last 10 minutes... Logging Out')
      //code for logout or anything...
      clearInterval(timeInterval)
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user_id");
      window.location = '/login';
    }
  
  },1000)
  
  return (
    <> {
      !isLoading ?
   
    (<div className="App">
      <Router>
        <Switch>
          <LoggedInState exact path="/login" component={Login} />
          <LoggedInState exact path="/reminder" component={Reminder} />
          <LoggedInState exact path="/signup" component={SignUp} />
          <PrivateRoute exact path="/" role={[1, 2, 3]} component={Dashboard} />
          {/* <PrivateRoute exact path="/dash" role={[1, 2, 3]} component={Dash} />  */}
          <PrivateRoute exact path="/addrating/:tenderId" role={[1,2]} component={AddRating}/>
          {/* //admin */}
          <PrivateRoute exact path="/sms-setting" role={[1]} component={SMSSetting} />
          {/* //admin */}
          <PrivateRoute exact path="/email-setting" role={[1]} component={EmailSetting} />
          {/* //admin */}
          <PrivateRoute
            exact
            path="/bid/received/:tenderId/:name"
            role={[1,2]}
            component={BidReceived}
          />
          {/* //admin */}
          <PrivateRoute
            exact
            path="/payment-gateway"
            role={[1]}
            component={PaymentGateway}
          />
           <PrivateRoute exact path="/adddocument" role={[2, 3]} component={AddDocument} />
          {/* //admin */}
          <PrivateRoute exact path="/service-type" role={[1]} component={ServiceType} />
          {/* //admin */}
          <PrivateRoute
            exact
            path="/consultancy-prices"
            role={[1]}
            component={ConsultancyPrices}
          />
          <PrivateRoute
            exact
            path="/userdetails"
            role={[1,2]}
            component={UserDetails}
          />
          <PrivateRoute
            exact
            path="/company/:id"
            role={[1]}
            component={CompanyUserDetails}
          />
          {/* //admin */}
          <PrivateRoute exact path="/company-type" role={[1]} component={CompanyType} />
          {/* //admin */}
          <PrivateRoute exact path="/organization" role={[1]} component={OrganizationManagement} />
          {/* //admin */}
          <PrivateRoute exact path="/company" role={[1,2]} component={Company} />
          
          <PrivateRoute exact path="/organization/:organisationId" role={[1]} component={OrganisationCompany} />
          {/* //admin */}
          <PrivateRoute exact path="/asset" role={[1,2]} component={Asset} />
          {/* //admin */}
          <PrivateRoute exact path="/supplier" role={[1]} component={SupplierManagement} />
          <PrivateRoute exact path="/ratings" role={[2, 3]} component={SupplierRatingList} />
          {/* //admin */}
          <PrivateRoute exact path="/inspection-report" role={[1,2]} component={InspectionReport} />
          {/* //admin */}
          <PrivateRoute exact path="/inspection-report/add/:assetId" role={[1,2]} component={AddInspectionReport} />
          {/* //admin */}
          <PrivateRoute exact path="/inspection-report/:inspectionId" role={[1,2]} component={ViewInspectionReport} />
          {/* //admin */}
          <PrivateRoute exact path="/tender" role={[1,2]} component={Tender} />
          {/* //admin */}
          <PrivateRoute exact path="/tenders" role={[1,2]} component={AllTenders} />
          {/* //admin */}
          <PrivateRoute exact path="/tender/:tenderId" role={[1,2]} component={Tender} />
          <PrivateRoute exact path="/tender/:tenderId/messages" role={[1,2]} component={TenderMessages} />
          {/* //admin */}
          <PrivateRoute exact path="/pre-tender/:preTenderId/supplier/:supplierId" role={[1,2]} component={AdminPreTender} />
          {/* //supplier */}
          {/*<PrivateRoute
            exact
            path="/SupplierDashboard"
            role={[3]} 
            component={SupplierDashboard}
          />*/}
          {/* //supplier */}
          <PrivateRoute exact path="/pre-tender" role={[3]} component={PreTender} />
          {/* //supplier */}
          <PrivateRoute exact path="/pre-tender/:preTenderId" role={[3]} component={PreTender} />
          {/* //supplier */}
          <PrivateRoute exact path="/pre-tender/detail/:preTenderId" role={[3]} component={PreTenderDetail} />
          {/* //supplier */}
          <PrivateRoute exact path="/bid/:tenderId" role={[3]} component={Bid} />
          {/* //supplier */}
          <PrivateRoute exact path="/tenderBid/:tenderId" role={[3]} component={BidSubmitted} />
          {/* //admin */}
          <PrivateRoute
            exact
            path="/tender/detail/:tenderId"
            role={[1,2]}
            component={TenderDetail}
          />
          <PrivateRoute
            exact
            path="/tender/dashboard/:tenderId"
            role={[1,2]}
            component={Dash}
          />
          <PrivateRoute
            exact
            path="/dashboard"
            role={[1,2,3]}
            component={ControlPanel}
          />
          <PrivateRoute
            exact
            path="/dashboarddetails"
            role={[1,2,3]}
            component={ControlPanelDetails}
          />
          {/* //supplier */}
          <PrivateRoute
            exact
            path="/tender/detail/supplier/:tenderId"
            role={[3]}
            component={TenderDetailSupplier}
          />
          <PrivateRoute
            exact
            path="/tender/awarded/:tenderId"
            role={[1,2]}
            component={TenderCompletedDetails}
          />
          <Route
            exact
            path="/register/:invite_type/:invite_code"
            component={SupplierRegistration}
          />

          <PrivateRoute
              exact
              path="/user-details"
              role={[2]}
              component={PropertyUserDetails}
            />

          <PrivateRoute
              exact
              path="/profile"
              role={[1,2,3]}
              component={UserProfile}
            />
          <Route>
            <p>404 Not Found</p>
          </Route>
        </Switch>
      </Router>
    </div>) : (<></>)
     }
     </>
  );
}

export default App;
