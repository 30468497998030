/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Spin, Form, Input, Button, Checkbox, Select, InputNumber, Modal, message } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from 'react-router';
import { FormattedMessage, useIntl } from "react-intl";

import get from 'lodash/get';

import Header from "../../components/Header";

import { createSuppliersRequest } from '../../actions/suppliers';
import { fetchCities } from '../../actions/list';
import { fetchServiceTypes } from '../../actions/services';

import { segKeyData } from '../../utils';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const { Option } = Select;

const SupplierRegistration = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const intl=useIntl();

   const { invite_type, invite_code } = useParams();

   const [successModal, setSuccessModal] = useState(false);
   const [isSubmitting, setIsSubmitting] = useState(false);

   const cities = useSelector(state => segKeyData('city_name', 'city_id', Object.values(get(state, `entities.cities`) || {})));

   const services = useSelector(state => segKeyData('service_type_name', 'service_type_id', Object.values(get(state, `entities.services`) || {})));

   const getCities = async() => {
       await dispatch(fetchCities(1));
   }

   const getServiceTypes = async() => {
       await dispatch(fetchServiceTypes());
   }

   useEffect(() => {
       getCities();
       getServiceTypes();
   }, [dispatch]);

    const onFinish = async(values) => {
        window.scrollTo(0, 0)
        setIsSubmitting(true);
        values.service_cities = values.service_cities_values.map(item => {
            return {
                'city_id': item
            }
        })

        values.service_types = values.service_types_values.map(item => {
            return {
                'service_type_id': item
            }
        })
        values.pincode = values.pincode.toString();
        values.contact = values.contact.toString();
        values.country_id = 1;
        values.city_id = +values.city_id;
        delete values.service_cities_values;
        delete values.service_types_values;
        const response =  await dispatch(createSuppliersRequest(values, { invite_code, invite_type }));
        setIsSubmitting(false);
        if(response.error) {
            message.error(intl.formatMessage({ id:"invalid_code"}))
        }
        else {
            setSuccessModal(true);
        }
    };

  return (
      <Spin spinning={isSubmitting}>
        <Header /> 
        <div className = "mainContainer" >
            <div className="innerContainer">
                <h2 className="title"><FormattedMessage id={'supplier_registration_form'} /></h2>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ invite_type, invite_code }}
                    onFinish={onFinish}
                    style={{ width: '85%' }}
                >
                    <Form.Item
                    label={<FormattedMessage id={'company_name'} />}
                    name="company_name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                    label={<FormattedMessage id={'business_id'} />}
                    name="business_id"
                    rules={[{ required: true, message: <FormattedMessage id={'error.business_id'} /> }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                    label={<FormattedMessage id={'supplier_name'} />}
                    name="supplier_name"
                    rules={[{ required: true, message: <FormattedMessage id={'error.name'} /> }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                    label={<FormattedMessage id={'email'} />}
                    name="email"
                    rules={[ {
                        type: 'email',
                        message: <FormattedMessage id={'error.invalid_email'} />,
                        },
                        { required: true, 
                        message: <FormattedMessage id={'error.email'} /> 
                        } ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                    label={<FormattedMessage id={'mobile_number'} />}
                    name="contact"
                    rules={[
                            { pattern: new RegExp(/^[0-9]*$/), message: <FormattedMessage id={'error.invalid_mobile_number'} />, },
                            { required: true, message: <FormattedMessage id={'error.mobile_number'} /> },
                            { min: 10, message: <FormattedMessage id={'Mobile Number can\'t be less than 10 digits'} /> },
                            { max: 14, message: <FormattedMessage id={'Mobile Number can\'t be more than 14 digits'} /> },
                        ]}
                    >
                        <Input style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                    label={<FormattedMessage id={'alternate_mobile_number'} />}
                    name="alt_contact"
                    rules={[
                            { pattern: new RegExp(/^[0-9]*$/), message: <FormattedMessage id={'error.invalid_mobile_number'} />, },
                            { min: 10, message: <FormattedMessage id={'Mobile Number can\'t be less than 10 digits'} /> },
                            { max: 14, message: <FormattedMessage id={'Mobile Number can\'t be more than 14 digits'} /> },
                        ]}
                    >
                        <Input style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                    label={<FormattedMessage id={'full_address'} />}
                    name="address"
                    rules={[
                        
                        { required: true, message: <FormattedMessage id={'error.address'} /> }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                    label={<FormattedMessage id={'city'} />}
                    name="city_id"
                    rules={[
                        { required: true, message: <FormattedMessage id={'error.city'} /> }]}
                    >
                        {/* <Select
                            style={{ width: '100%' }}
                            placeholder={<FormattedMessage id={'select_city'} />}
                            notFoundContent={null}
                            optionFilterProp="city_name"
                            options={cities}
                        >
                        </Select> */}
                        <Select
                            // name="city_id"
                            showSearch
                            style={{width: '100%'}}
                            placeholder={<FormattedMessage id={'select_city'} />}
                            optionFilterProp="children"
                            // value={company?.city_id ?? ""}
                            // onChange={handleCityChange}
                            // onSearch={handleCityChange}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            // filterSort={(optionA, optionB) =>
                            //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            // }
                            options={
                                cities.sort((a, b) =>a.label.localeCompare(b.label, 'fi', {
                                    caseFirst: 'upper'
                                }))
                            }
                        />
                    </Form.Item>


                    <Form.Item
                    label={<FormattedMessage id={'pin_code'} />}
                    name="pincode"
                    rules={[
                            { pattern: new RegExp(/^[0-9]*$/), message: <FormattedMessage id={'error.invalid_pin_code'} />, },
                            { required: true, message: <FormattedMessage id={'error.pin_code'} /> },
                            { min: 5,message: <FormattedMessage id={'Postal code can\'t be less than 5 digits'} /> },
                            { max: 6,message: <FormattedMessage id={'Postal code can\'t be more than 6 digits'} /> },
                        ]}
                    >
                        <Input style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                    label={<FormattedMessage id={'cities_operating'} />}
                    name="service_cities_values"
                    rules={[
                        { required: true, message: <FormattedMessage id={'error.city'} /> }]}
                    >
                        <Select
                            showSearch
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder={<FormattedMessage id={'select_operating_city'} />}
                            notFoundContent={null}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            // filterSort={(optionA, optionB) =>
                            //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            // }
                            options={cities.sort((a, b) =>a.label.localeCompare(b.label, 'fi', {
                                caseFirst: 'upper'
                            }))}
                        >
                        </Select>
                    </Form.Item>

                    <Form.Item
                    label={<FormattedMessage id={'choose_industry_type'} />}
                    name="service_types_values"
                    rules={[
                        { required: true, message: <FormattedMessage id={'error.service_type'} /> }]}
                    >
                        <Checkbox.Group
                            options={services}
                            name="service_type_name"
                        />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        {<FormattedMessage id={'submit'} />}
                    </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>

        <Modal
          closable={false}
          title=""
          centered
          visible={successModal}
          footer={null}
        >
          <p><FormattedMessage id={'submit_request'} /></p>
          <p><FormattedMessage id={'response_back'} /></p>
          <div className="d-flex j-center f-row">
              <div className="confirmbtn btn" onClick={() => {setSuccessModal(false); history.push('/')}}>
                <FormattedMessage id={'ok'} />
              </div>
            </div>
          
        </Modal>
    </Spin>
  );
};

export default SupplierRegistration;