import React, { useState } from "react";

import { useDispatch } from "react-redux";

import { Form, Input, Button, Modal, Spin, message } from 'antd';

import { FormattedMessage, useIntl } from "react-intl";


import { resetPassword} from '../../actions/auth';

const ForgotPassword = ({ isOpen, onClose }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const onFinish = async(values) => {
        setIsLoading(true);
        const response = await dispatch(resetPassword(values));
        setIsLoading(false);
        if(response.error) {
            message.error(intl.formatMessage({ id: 'reset_email_failure' }));
        }
        else {
            message.success(intl.formatMessage({ id: 'reset_email' }));
            onClose();

        }
        
      };
    
      const onFinishFailed = (errorInfo) => {
      };

    return (
      <Modal
      open={isOpen}
        onCancel={onClose}
        centered
        maskClosable={true}
        title={<FormattedMessage id={"forgot_pwd"} />}
        footer={null}
      >
        <Spin spinning={isLoading}>
          <Form
            name="forgot_pwd"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label={<FormattedMessage id={"email"} />}
              name="email"
              rules={[
                {
                    type: 'email',
                    message: <FormattedMessage id={'error.invalid_email'} />,
                },
                {
                  required: true,
                  message: <FormattedMessage id={"error.email"} />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit" className="btn">
                <FormattedMessage id={"reset_pwd"} />
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
};

export default ForgotPassword;