/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin , Row, Col, Input, Button} from "antd";
import { useHistory } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { DeleteTwoTone, SearchOutlined } from '@ant-design/icons';
// import moment from 'moment';
import { domainUrl } from "../../helper";

import "./TenderCard.less";
import { genHeaders } from "../Tender/Tender";
import { useDispatch } from "react-redux";
import { fetchPreTenders, deletePreTender } from "../../actions/pre-tender";
// import { getUser } from "../../actions/auth";
import ConfirmationBox from '../ConfirmationBox';
import { changeDateFormat } from '../../utils/common';

const TOGGLE_LIMIT = 4;

const SUBMITTED = "submitted";
const PROGRESSBIDS = "progress";
const NEWTENDER = "new";
const DRAFT = "draft";
const AWARDED = "awarded";

const TenderCard = ({ status }) => {
  const dispatch = useDispatch();
  const intl=useIntl();
  const [tenders, setTenders] = useState();
  const [loading, setLoading] = useState(false);
  const [toggleMore, setToggleMore] = useState(true);
  const [error, setError] = useState();
  const [searchText, setSearchText] = useState('');

  const [title] = useState(() => {
    if (status === "completed_all") return ["completed_tenders", "completed"];
    if (status === "submitted") return ["tenders_submitted_bids", SUBMITTED];
    if (status === "progress") return ["progress_bids", PROGRESSBIDS];
    if (status === "draft") return ["new_tenders", NEWTENDER];
    if (status === "pre") return ["pre_tenders", null];
    if (status === "awarded") return ["awarded_tenders", AWARDED];
  });

   const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [deleteTenderId, setDeleteTender] = useState('');
  const history = useHistory();

  const getTendersInfo = async () => {
    try {
      setLoading(true);
      setError(false);

      const queryParam = status ? `&status=${status}` : "";
      let searchParam = '';
      if(searchText){
        searchParam = `&title=${searchText}`
      }
      let tenderInfo = await axios.get(
        `${domainUrl}/tender/v2/tender/supplier?order_by=updated_at&sort_by=DESC${queryParam}${searchParam}`,
        genHeaders()
      );

      tenderInfo = tenderInfo.data.data.result;

      const creators = await Promise.all(
        tenderInfo?.map((tender) =>
          axios
            .get(`/users/${tender.created_by}`, genHeaders())
            .then((r) => r.data.data)
        )
      );

      tenderInfo = tenderInfo?.map((tender, index) => {
        return {
          name: tender?.title,
          created_by: creators[index]?.name,
          stat: title[1],
          id: tender?.tender_id,
          closing_date: tender?.closing_date,
          completion_date: tender?.completion_date,
          comment: tender?.comment || 'N/A',
          updated_at: tender?.updated_at,
          no_of_suppliers: tender?.no_of_suppliers,
          award_datetime: tender?.award_datetime,
          company_name: tender?.company_name,
          organization_name: tender?.organization_name,
        };
      });

      setTenders(tenderInfo);
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      setTenders(null);
    }
  };
  useEffect(() => {
    if(status !== 'pre')
      getTendersInfo();
    else
      getPreTenderInfo();
  }, []);

  const getPreTenderInfo = async() => {
    setLoading(true);
    setError(false);
    let params = {limit: 1000, order_by: 'updated_at', sort_by: 'DESC', status: 'pending'};
    if(searchText){
      params.title = searchText
    }
    const response = await dispatch(fetchPreTenders(params));
    let preTendersPayload = response?.payload.entities?.preTender;
    // const userResponse = await dispatch(getUser(preTenders[0]?.created_by));
    const userName = ''; //userResponse.payload.entities?.users[preTenders[0].created_by]?.name || '';
    let preTenders = response?.payload?.result?.map( id => {
      return {
        name: preTendersPayload[id]?.title,
        created_by: userName,
        stat: preTendersPayload[id]?.is_submitted === 0 ? 'draft' : 'submitted',
        id: id,
        organization_name: preTendersPayload[id]?.organization_name,
        company_name: preTendersPayload[id]?.company_name,
        submitted_date: preTendersPayload[id]?.submitted_date,
      }
    });
    setTenders(preTenders);
    setLoading(false);
  }

  const declineSubmit = () => {
    setShowConfirmationBox(false);
    setDeleteTender('');
  }

  const confirmDelete = async () => {
    setShowConfirmationBox(false);
    await dispatch(deletePreTender(deleteTenderId));
    getPreTenderInfo();
  };

  const deleteDraftTender = (tender_id) => {
    setDeleteTender(tender_id); 
    setShowConfirmationBox(true);
  }

  const handleSearch = () => {
    if(status !== 'pre')
      getTendersInfo();
    else
      getPreTenderInfo();
  }

  const onTenderClick = (e, tender) => {
     if(e.target.nodeName.toLowerCase() === 'path') {
      e.stopPropagation();
      return;
    }
    if (tender?.id) {
      if (status === "draft" || status === "awarded"  || status === "completed_all" || status === "progress") {
        history.push(`/tender/detail/supplier/${tender.id}`);
      }
      if(status === 'submitted') {
        history.push(`/tenderBid/${tender.id}`)
      }
      if (status === "pre") {
        history.push(tender.stat === 'draft' ? `/pre-tender/${tender.id}`: `/pre-tender/detail/${tender.id}`);
      }
    }
  };

  let content;

  if (loading) {
    content = <Spin style={{ display: "flex", justifyContent: "center" }} />;
  } else if (error || !tenders?.length) {
    // content = (
    // <p style={{ width: "100%", textAlign: "center" }}>
    // Error while loading tenders
    // </p>
    // );
  } else {
    content = (
      <>
        <div className="cardcontainer">
          <div style={{display: 'flex',  width: "100%",alignItems:"center" , justifyContent: "end", marginBottom: "2%" }}>
            <Input
              placeholder={intl.formatMessage({ id:"search_tender_name"})}
              value={searchText}
              onChange={e => setSearchText(e.target.value ? e.target.value : '')}
              onPressEnter={() =>  handleSearch()}
              style={{ marginBottom: 0,display:"block", marginRight: "1%", width: "30%" }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, margin: 0 }}
            >
              <FormattedMessage id="search" />
            </Button>
          </div>
          <ul className="title">
          <Row className="card-row" justify="space-between" align="middle" gutter={[15, 15]}>
            <Col md={6} lg={6} xl={6}><FormattedMessage id={'name'}/></Col>
              <Col  md={8} lg={8} xl={status === 'pre' ? 8 : 9}><FormattedMessage id={'company'} /></Col>
            {
              status !== 'pre' &&
            <Col md={6} lg={5} xl={5}><FormattedMessage id={'created_by'}/></Col>
            }
             {
              (status !== 'pre' &&  status !== AWARDED && status !== 'completed_all') &&
                 <Col md={4} lg={5} xl={4}><FormattedMessage id={'last_updated'}/></Col>
            }
            {
              status === AWARDED && 
                <Col md={4} lg={5} xl={4}><FormattedMessage id={'awarded_date'} /></Col>
            }
            {
              status === 'completed_all' && 
                <Col md={4} lg={5} xl={4} style={{textWrap:'nowrap'}}><FormattedMessage id={'completed_date'} /></Col>
            }
            {
              status === 'pre' &&
              <>
            <Col md={6} lg={5} xl={5}><FormattedMessage id={'status'}/></Col>
            <Col md={4} lg={5} xl={4}><FormattedMessage id={'date_submitted'} /></Col> 
            {
              (status === 'pre') && (
                <Col xs={1}></Col>
              )
            }
            </>
            }
            </Row>
          </ul>
          {tenders
            ?.filter((_, i) => i < (toggleMore ? TOGGLE_LIMIT : tenders.length))
            ?.map((tender, index) => (
              <ul
                key={index}
                className="content"
                style={{ cursor: "pointer" }}
                onClick={(e) => onTenderClick(e, tender)}
              >
                <Row className="card-row" justify="space-between" align="middle" gutter={[15, 15]}>
                <Col md={6} lg={6} xl={6}>
                  <span>{tender?.name}</span>
                </Col>
              <Col md={8} lg={8} xl={status === 'pre' ? 8 : 9}>{tender?.company_name?.split(',').join(', ')}</Col>
                {
              status !== 'pre' &&
                <Col md={6} lg={5} xl={5}>
                  <span>{tender?.created_by}</span>
                </Col>
                }
                {
                  (status !== 'pre' &&  status !== AWARDED && status !== 'completed_all') &&
                    <Col md={4} lg={5} xl={4}>{changeDateFormat(tender?.updated_at,intl?.locale)}</Col>
                }
                {
                  status === AWARDED && 
                    <Col md={4} lg={5} xl={4}>{changeDateFormat(tender?.award_datetime,intl?.locale)}</Col>
                }
                {
                  status === 'completed_all' && 
                    <Col md={4} lg={5} xl={4}>{tender?.completion_date ? changeDateFormat(tender?.completion_date,intl?.locale) : 'N/A'}</Col>
                }
                {
              status === 'pre' &&
              <>
                <Col md={6} lg={5} xl={5}>
                  <span
                    style={{
                      fontWeight: "bold",
                      color:
                        tender?.stat === SUBMITTED || tender?.stat === AWARDED
                          ? "green"
                          : tender?.stat === DRAFT
                          ? "red"
                          : tender?.stat === NEWTENDER
                          ? "#fabf01"
                          : null,
                    }}
                  >
                  <FormattedMessage id={tender?.stat}/>
                    
                  </span>
                </Col>
                <Col md={4} lg={5} xl={4}>{tender?.submitted_date && changeDateFormat(tender?.submitted_date,intl?.locale)}</Col>
                {
                  (status === 'pre') && 
                  <Col xs={1}> {(tender?.stat === DRAFT && <DeleteTwoTone twoToneColor={'red'} onClick={() => deleteDraftTender(tender.id)}/>)}</Col> 
                }
                </>
              }
              </Row>
              </ul>
            ))}
        </div>
        {tenders?.length > TOGGLE_LIMIT && (
          <div className="viewAll" onClick={() => setToggleMore(!toggleMore)}>
            {toggleMore && <FormattedMessage id={'show_more'}/>}
            {!toggleMore && <FormattedMessage id={'show_less'}/>}
          </div>
        )}
      </>
    );
  }

  return (
    <div className="__TenderCard__">
      <div className="card">
        <h2><FormattedMessage id={title[0]}/></h2>
        {content}
      </div>
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={intl.formatMessage({id:'are_you_sure_you_want_to_delete_this_tender'}) } confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />
      {
              title[0] === 'pre_tenders' && (
                <div onClick={() => history.push("/pre-tender")} className="addTenderBtn">
                  <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.701"
              height="29.701"
              viewBox="0 0 29.701 29.701"
            >
              <path
                fill="#fff"
                d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
                transform="translate(-1 -1)"
              />
            </svg>  <span><FormattedMessage id={'add_new_pre_tender'}/></span>
                </div>
              )
            }
    </div>
  );
};

export default TenderCard;
