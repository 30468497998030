/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";
import "../../Tender/AdditionalInfo/AdditionalInfo.css";
import "../../Tender/ChooseTenderType/ChooseTenderType.css";
import { fetchTenderTypes } from '../../../actions/tender';

import { useTender } from "../PreTenderSteps";
import { fetchCompanyTypes } from "../../../actions/company";
import { createPreTender, updatePreTender } from "../../../actions/pre-tender";
import { useHistory } from "react-router";
import ToolTip from "../../ToolTip";
import { FormattedMessage, useIntl } from "react-intl";

const ChooseTenderType = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { preTenderDetails, onStepChange: move, modifyData } = useTender();

  const [tenderTypes, setTenderTypes] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
const intl=useIntl()
  const [selectedTenderType, setSelectedTenderType] = useState(
    preTenderDetails?.tender_type
  );

  const [errorMessage, setErrorMessage] = useState();
  const [otherTender, setOtherTender] = useState(
    preTenderDetails?.tender_type_other
  )
  useEffect(() => {
    const getFieldInfo = async () => {
      const typeResponse = await dispatch(fetchTenderTypes());
      setTenderTypes(Object.values(typeResponse.payload.entities?.tenderTypes || []));

      const companyResponse = await dispatch(fetchCompanyTypes());
      setCompanyTypes(Object.values(companyResponse.payload.entities?.companyTypes || []));
    }
    getFieldInfo();
  }, [dispatch]);

  const onBack = () => {
    move(-1);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
  
    if (!selectedTenderType) {
      return setErrorMessage(<FormattedMessage id="select_tender_type" />);
    } else if (
      selectedTenderType === "o" &&
      (!otherTender || otherTender === "")
    ) {
      setErrorMessage(<FormattedMessage id="Please enter tender type." />);
      return;
    } else {
      setErrorMessage(null);
    }

    if (preTenderDetails.is_submitted !== 1) {
      (async () => {
        if (preTenderDetails?.pretender_id === undefined) {
          let postResponse = await dispatch(
            createPreTender({
              tender_type: selectedTenderType,
              tender_type_other: otherTender || "",
              state: parseInt(preTenderDetails.state) > 1 ? preTenderDetails.state : "1",
            }),
          );
          let createdPreTender = Object.values(postResponse.payload?.entities?.preTender || {},);
          if (createdPreTender[0]?.pretender_id) {
            history.push(`pre-tender/${createdPreTender[0]?.pretender_id}`);
            move(1, {tender_type: selectedTenderType, tender_type_other: otherTender || "", pretender_id: createdPreTender[0].pretender_id,});
          } else {
            message.error("Unable to reach server.");
          }
        } else {
          //update existing tender's company_type & tender_type instead of creating a new one, by using the tender_id;
          let putResponse = await dispatch(updatePreTender({ tender_type: selectedTenderType, tender_type_other: otherTender || "",}, preTenderDetails?.pretender_id,),);
    
          let editedPreTender = putResponse.payload?.entities?.preTender[ preTenderDetails?.pretender_id];
          if (editedPreTender?.pretender_id) {
            move(1, { tender_type: selectedTenderType, tender_type_other: otherTender || "",});
          } else {
            message.error(e?.message ?? "Unable to reach server.");
          }
        }
      })();
    } else {
      move(1,{ tender_type: selectedTenderType, tender_type_other: otherTender || "",}, true,);
    }
  };  

  const publishData = () => {
    move(0, {
      tender_type: selectedTenderType,
      tender_type_other: otherTender || '',
    },true);
    modifyData();
  }


  return (
    <div>
      <div className="parentBlock2 parentBlock3">
        <div className="hedding">
          <h2><FormattedMessage id="preTender_type"/><ToolTip text_id="tender.choose_tender_type.info" placement="top" /></h2>
        </div>

        <form onSubmit={onFormSubmit}>
          <div className="formGroup">
            {tenderTypes?.map((el, index) => { return el.tender_type_value !== 'm' && (
               

                <div key={index} className="radioBtn">
                <input
                  type="radio"
                  checked={el.tender_type_value === selectedTenderType}
                  onChange={() => {setSelectedTenderType(el.tender_type_value); setErrorMessage('')}}
                  id={el.tender_type_label}
                  name="radio-group"
                />
                <label htmlFor={el.tender_type_label}>
                  {el?.tender_type_label == "repair" ? <FormattedMessage id="repair" /> : el?.tender_type_label == "others" ? <FormattedMessage id="others" /> : el?.tender_type_label}
                </label>
              </div>
              
            )})}
          </div>
          {
            selectedTenderType === 'o' && (
              <div className="formGroup">
                <label className="leftCol"></label>
                <input
                  value={otherTender}
                  onChange={({ target }) => {
                    setOtherTender(target.value);
                  }}
                  type="text"
                  className="formFiled"
                />
              </div>
            )
          }
          
          {errorMessage && (
            <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>{errorMessage}</p>
          )}

          <div className="buttonBlock">
            <div className="btn cancelBtn" onClick={onBack}>
              <FormattedMessage id="bakc"/>
            </div>
            {
              preTenderDetails?.is_submitted === 1 && 
              <div className="btn" onClick={publishData}>
                <FormattedMessage id="publish"/>
              </div>
            }
            <input
              type="submit"
              value={intl.formatMessage({id:"next"})}
              className="btn"
              style={{ border: "none" }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

// Tender.propTypes = {};

export default ChooseTenderType;
