import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import {
	handleResponse,
	handlePropertyResponse,
	handleError,
	handleDataSuccessResponse,
	handleQuery,
} from "../utils";
// import { apiUrl, domainUrl } from "../helper";
import { domainUrl } from "../helper";
import { CUSTOM_AUTHORIZED_REQUEST } from '../middleware/apiAuthorization';

export const FETCH_ASSETCATEGORY_REQUEST = "FETCH_ASSETCATEGORY_REQUEST";
export const FETCH_ASSETCATEGORY_SUCCESS = "FETCH_ASSETCATEGORY_SUCCESS";
export const FETCH_ASSETCATEGORY_FAILURE = "FETCH_ASSETCATEGORY_FAILURE";

export const FETCH_ASSET_REQUEST = "FETCH_ASSET_REQUEST";
export const FETCH_ASSET_SUCCESS = "FETCH_ASSET_SUCCESS";
export const FETCH_ASSET_FAILURE = "FETCH_ASSET_FAILURE";

export const FETCH_ASSETS_REQUEST = "FETCH_ASSETS_REQUEST";
export const FETCH_ASSETS_SUCCESS = "FETCH_ASSETS_SUCCESS";
export const FETCH_ASSETS_FAILURE = "FETCH_ASSETS_FAILURE";

export const FETCH_ASSET_PROPERTY_REQUEST = "FETCH_ASSET_PROPERTY_REQUEST";
export const FETCH_ASSET_PROPERTY_SUCCESS = "FETCH_ASSET_PROPERTY_SUCCESS";
export const FETCH_ASSET_PROPERTY_FAILURE = "FETCH_ASSET_PROPERTY_FAILURE";

export const CREATE_ASSET_REQUEST = "CREATE_ASSET_REQUEST";
export const CREATE_ASSET_SUCCESS = "CREATE_ASSET_SUCCESS";
export const CREATE_ASSET_FAILURE = "CREATE_ASSET_FAILURE";

export const UPDATE_ASSET_REQUEST = "UPDATE_ASSET_REQUEST";
export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS";
export const UPDATE_ASSET_FAILURE = "UPDATE_ASSET_FAILURE";

export const DELETE_ASSETS_REQUEST = "DELETE_ASSETS_REQUEST";
export const DELETE_ASSETS_SUCCESS = "DELETE_ASSETS_SUCCESS";
export const DELETE_ASSETS_FAILURE = "DELETE_ASSETS_FAILURE";

export const assetCategorySchema = new schema.Entity("assetCategory", [], {
	idAttribute: "asset_category_id",
});
export const assetSchema = new schema.Entity("assets", [], {
	idAttribute: "asset_id",
});
export const assetPropertySchema = new schema.Entity("assetProperty", [], {
	idAttribute: "property_id",
});

/**
 * Returns a redux api middleware action that makes a request to get Asset Category.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchAssetCategory = (params ={}) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: `${domainUrl}/masterservicenew/master/asset_categories`,
			method: "GET",
			types: [
				{ type: FETCH_ASSETCATEGORY_REQUEST },
				{
					type: FETCH_ASSETCATEGORY_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([assetCategorySchema], action, state, res),
				},
				{ type: FETCH_ASSETCATEGORY_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request for a collection of Assets.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchAssets = (params ={}) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: `${domainUrl}/masterservicenew/master/assets?${handleQuery(params)}`,
			method: "GET",
			types: [
				{ type: FETCH_ASSETS_REQUEST },
				{
					type: FETCH_ASSETS_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([assetSchema], action, state, res),
				},
				{ type: FETCH_ASSETS_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request for a Assets.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchAsset = (assetId) =>
 createAction({
	 endpoint: `${domainUrl}/masterservicenew/master/assets/${assetId}`,
	 method: "GET",
	 headers: {
		 client_code: "ht_front",
		 token: sessionStorage.getItem("token"),
		 "Content-Type": "application/json",
	 },
	 types: [
		 { type: FETCH_ASSET_REQUEST },
		 {
			 type: FETCH_ASSET_SUCCESS,
			 payload: (action, state, res) =>
				 handleResponse(assetSchema, action, state, res),
		 },
		 { type: FETCH_ASSET_FAILURE, payload: handleError },
	 ],
 });

/**
 * Returns a redux api middleware action that makes a request for a collection of Assets Properties.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchAssetProperties = (asset_catgeory) =>
	createAction({
		endpoint: `${domainUrl}/masterservicenew/master/assets/${asset_catgeory}/asset_property`,
		method: "GET",
		headers: {
			client_code: "ht_front",
			token: sessionStorage.getItem("token"),
			"Content-Type": "application/json",
		},
		types: [
			{ type: FETCH_ASSET_PROPERTY_REQUEST },
			{
				type: FETCH_ASSET_PROPERTY_SUCCESS,
				payload: (action, state, res) =>
					handlePropertyResponse(
						[assetPropertySchema],
						action,
						state,
						res
					),
			},
			{ type: FETCH_ASSET_PROPERTY_FAILURE, payload: handleError },
		],
	});

/**
 * Returns a redux api middleware action that creates an Asset.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const createAsset = (data) =>
	createAction({
		endpoint: `${domainUrl}/masterservicenew/master/assets`,
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			client_code: "ht_front",
			token: sessionStorage.getItem("token"),
			"Content-Type": "application/json",
		},
		types: [
			{ type: CREATE_ASSET_REQUEST },
			{
				type: CREATE_ASSET_SUCCESS,
				payload: (action, state, res) =>
					handleDataSuccessResponse(
						[assetPropertySchema],
						action,
						state,
						res
					),
			},
			{ type: CREATE_ASSET_FAILURE, payload: handleError },
		],
	});

/**
 * Returns a redux api middleware action that updates an Asset.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const updateAsset = (asset_id, data) =>
	createAction({
		endpoint: `${domainUrl}/masterservicenew/master/assets/${asset_id}`,
		method: "PUT",
		body: JSON.stringify(data),
		headers: {
			client_code: "ht_front",
			token: sessionStorage.getItem("token"),
			"Content-Type": "application/json",
		},
		types: [
			{ type: UPDATE_ASSET_REQUEST },
			{
				type: UPDATE_ASSET_SUCCESS,
				payload: (action, state, res) =>
					handleDataSuccessResponse(
						[assetPropertySchema],
						action,
						state,
						res
					),
			},
			{ type: UPDATE_ASSET_FAILURE, payload: handleError },
		],
	});

/**
 * Returns a redux api middleware action that deletes an Asset.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const deleteAsset = (asset_id) =>
	createAction({
		endpoint: `${domainUrl}/masterservicenew/master/assets/${asset_id}`,
		method: "DELETE",
		headers: {
			client_code: "ht_front",
			token: sessionStorage.getItem("token"),
			"Content-Type": "application/json",
		},
		types: [
			{ type: DELETE_ASSETS_REQUEST },
			{
				type: DELETE_ASSETS_SUCCESS,
				payload: (action, state, res) =>
					handleDataSuccessResponse(
						[assetPropertySchema],
						action,
						state,
						res
					),
			},
			{ type: DELETE_ASSETS_FAILURE, payload: handleError },
		],
	});