import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from 'lodash/get';
import { useSelector, useDispatch } from "react-redux";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { 
  FormattedMessage, 
  // useIntl 
} from "react-intl";
import { fetchAssetProperties, fetchAssetCategory } from "../../../actions/assets";

import "./AssetDetails.css";
import { Spin } from "antd";
import FileUpload from "../../FileUpload";
import { domainUrl } from "../../../helper";
import FileDisplay from "../../FileDisplay";
const FILE_POST_URL = `${domainUrl}/tender/v1/document`;

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    border: "1px solid #c0c0c0",
  },
  gridItem: {
    "&:nth-child(odd)": {
      borderRight: "1px solid #c0c0c0",
    },
  },
}));

const AssetDetails = ({ readOnly, isOpen, data, onClose, onSubmit }) => {

  const assetCategory = useSelector(state => get(state, `entities.assetCategory`));

  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState({});
  const [properties, setProperties] = useState([]);
  const [assetProperties, setAssetProperties] = useState({});
  const [asset, setAsset] = useState(data);
  const [isUploading, setIsUploading] = useState(false);
  const [fileToUploadList, setFileToUploadList] = useState(asset?.files ?? []);
  const [fileList, setFileList] = useState(asset?.files ?? []);
  const dispatch = useDispatch();
  // const intl = useIntl();
  const getAssetCategories = async () => {
    await dispatch(fetchAssetCategory());
  }

  useEffect(() => {
    async function getAssetProperties() {
      const response = await dispatch(fetchAssetProperties(1));
      const propertyData = Object.values(
        response.payload.entities.assetProperty
      );
      mapPropertyIdNames(propertyData);
    }
    getAssetCategories();
    getAssetProperties();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const mapPropertyIdNames = (propertyData) => {
    let propertiesData = [];
    let propertiesForm = {};
    // eslint-disable-next-line array-callback-return
    propertyData.map((prop) => {
      let obj = {};
      obj.property_id = prop.property_id;
      const propSelected = asset.properties.find(
        (ele) => ele.property_id === prop.property_id
      );
      if ((readOnly && propSelected) || !readOnly) {
        obj.property_name = prop.property_name;
        obj.property_value = propSelected ? propSelected.property_value : "";
        propertiesData.push(obj);
        propertiesForm[obj.property_id] = obj.property_value;
      }
    });

    setProperties(propertiesData);
    setAssetProperties(propertiesForm);
  };

  const handleInputChange = (event) => {
    const data = {
      ...asset,
      [event.target.name]: event.target.value,
    };
    setAsset(data);
    setErrorMessage({});
  };

  const handlePropInputChange = (event) => {
    const data = {
      ...assetProperties,
      [event.target.name]: event.target.value,
    };
    setAssetProperties(data);
    setErrorMessage({});
  };

  function handleSubmit() {
    if (!asset) {
      setErrorMessage({
        asset_name: "Please Enter Asset Name",
      });
    } else if (!asset.asset_name || asset.asset_name === "") {
      setErrorMessage({
        org_id: "Please Enter Asset Name",
      });
    } else if (asset && asset.asset_name !== "") {
      onSubmit(asset, assetProperties);
    } else {
      setErrorMessage({
        message: "Some issue occurred please try again",
      });
    }
  }

  const onFilesStatusChange = (e) => {
    setFileToUploadList(e);
  };

  const addFileList = (files) => {
    setFileList({
      ...fileList,
      ...files
    })
  }

  const onUploadStatusChange = (e) => {
    setIsUploading(e);
  };

  const onFilesUploadStatusChange = (e) => {
    setFileList(e);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="scroll-dialog-title">
      <FormattedMessage id={'selected_housing_company'} />: {asset.company_name}
      </DialogTitle>
      <DialogContent dividers={true} className="assetAddModal">
        <h3>{!readOnly ? (asset?.asset_id ? <FormattedMessage id={'edit'} /> : <FormattedMessage id={'enter'} />) : ''} <FormattedMessage id={'asset_details'} /></h3>

        <div className="inputFieldGroup assetName">
          <label ><FormattedMessage id={'asset_category'} /></label>
          <div className="inputText">
            {readOnly ? (
              <input
                readOnly={readOnly}
                className="inputFiled"
                type="text"
                name="asset_category_name"
                value={assetCategory && assetCategory['1'].asset_category_name}
              />
            ) : (
              <div className="costumSelect">
                <select
                  name="asset_category_id"
                  id="slct"
                  className="inputFiled"
                  value={1}
                  onChange={handleInputChange}
                >
                  {Object.values(assetCategory || {})?.map((el, index) => (
                    <option key={index} value={el.asset_category_id}>
                      {el?.asset_category_name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {/* <div className="errorMessage">{errorMessage.companyType}</div> */}
          </div>
        </div>
        <div className="inputFieldGroup assetName">
          <label><FormattedMessage id={'Asset_Name'} /></label>
          <div className="inputText">
            <input
              readOnly={readOnly}
              className="inputFiled"
              type="text"
              name="asset_name"
              value={asset ? asset.asset_name : ""}
              onChange={handleInputChange}
            />
            <div className="errorMessage">{errorMessage.asset_name}</div>
          </div>
        </div>
        <Grid container spacing={2} className={classes.gridContainer}>
          {properties.map((property, index) => (
            <Grid
              item
              xs={12}
              md={6}
              key={property.property_name}
              className={classes.gridItem}
            >
                <label><FormattedMessage id={property.property_name} /></label>
              <div className="inputFieldGroup">
                <div className="inputText">
                  <input
                    readOnly={readOnly}
                    className="inputFiled"
                    type="text"
                    name={property.property_id}
                    value={
                      assetProperties
                        ? assetProperties[property.property_id]
                        : ""
                    }
                    onChange={handlePropInputChange}
                  />
                  <div className="errorMessage">
                    {errorMessage[property.property_name]}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
          
        </Grid>
        <Spin spinning={isUploading} >
          {asset?.asset_id &&
            <div className="inputFieldGroup mt-2">
              <label><FormattedMessage id="upload_files" />
              </label>
              
                <div className="inputText" style={{width: "100%"}}>
                  {!readOnly &&
                    <FileUpload
                      filePostURL={FILE_POST_URL}
                      entityType={"R"}
                      entityID={asset?.asset_id}
                      onFilesStatusChange={onFilesStatusChange}
                      addFileList={addFileList}
                      onUploadStatusChange={onUploadStatusChange}
                      onFilesUploadStatusChange={onFilesUploadStatusChange}
                    />
                  }
                  <FileDisplay entityType={'R'} fileList={fileList} entityId={asset?.asset_id?.toString()} hideTitle={false} deleteFiles={true}/>
                </div>
            </div>
          }
        </Spin>
      </DialogContent>
      <DialogActions>
        <div className="buttonBlock">
          <div className="btn cancelBtn" onClick={onClose}>
          <FormattedMessage id={'cancel'} />
          </div>
          {!readOnly && (
            <div className="btn" onClick={() => handleSubmit()}>
             <FormattedMessage id={'save'} />
            </div>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

AssetDetails.propTypes = {
  readOnly: PropTypes.bool,
  isOpen: PropTypes.bool,
  data: PropTypes.shape({}),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AssetDetails;